import React, { FC, useEffect, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { ReactComponent as BellSvg } from 'assets/svg/bell.svg';
import { ReactComponent as EnvelopeSvg } from 'assets/svg/envelope.svg';
import { TypographyStyles } from 'constants/shared/typography';
import { UserDropdown } from 'page-components';
import IconButtonWithTooltip from 'shared-components/IconButtonWithTooltip';
import { getCurrentTime } from 'utils';

const ICONS_CLASSNAME = 'cursor-pointer icon-button-with-tooltip-svg-white hover:fill-white p-1';

interface Props {
  handleOpenSignOutModal: VoidFunction;
}

const HeaderPanel: FC<Props> = ({ handleOpenSignOutModal }) => {
  const [currentTime, setCurrentTime] = useState(getCurrentTime());

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(getCurrentTime()), (60 - new Date().getSeconds()) * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className='flex items-center'>
      <div className='hidden md:flex items-center space-x-6 mr-12'>
        <time className={twMerge(TypographyStyles.body_normal, 'text-grey-800 whitespace-nowrap')}>{currentTime}</time>

        <IconButtonWithTooltip
          onClick={() => ''}
          icon={EnvelopeSvg}
          className={ICONS_CLASSNAME}
          tooltipText='Your inbox is currently clear'
          anchorId='inbox'
          disableDefaultIconStyles
        />

        <IconButtonWithTooltip
          onClick={() => ''}
          icon={BellSvg}
          className={ICONS_CLASSNAME}
          tooltipText='You have no notifications'
          anchorId='notifications'
          disableDefaultIconStyles
        />
      </div>
      <UserDropdown handleOpenSignOutModal={handleOpenSignOutModal} />
    </div>
  );
};

export default HeaderPanel;
