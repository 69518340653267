import React, { FC, useEffect, useMemo, useState } from 'react';

import { endOfDay, isBefore } from 'date-fns';
import { SubmitHandler, useFieldArray, useForm, UseFormReset } from 'react-hook-form';

import { START_INVESTMENT_SHARE_EXCHANGE_FORM_FIELDS, StartShareExchangeFieldNames } from 'constants/share-exchange';
import { Option, StartShareExchangesInvestmentItem, StartShareExchangesInvestmentsFormValues } from 'interfaces';
import { selectCompanyDealPredefinedData } from 'modules/deals/selectors';
import { useAppSelector } from 'modules/store';
import { FormContent, SnippetLayout } from 'shared-components';
import { calculateNoOfExchangesShares } from 'utils/investments';

import StartInvestmentShareExchangeAddNewRow from './StartInvestmentShareExchangeAddNewRow';
import StartInvestmentShareExchangeModalButtons from './StartInvestmentShareExchangeModalButtons';
import StartInvestmentShareExchangeModalTableItem from './StartInvestmentShareExchangeModalTableItem';
import StartInvestmentTotalShareExchangeList from './StartInvestmentTotalShareExchangeList';

interface Props {
  onClose: VoidFunction;
  onSuccessStartShareExchange: (
    shareExchanges: StartShareExchangesInvestmentItem[],
    companyId: number,
    loadingCallback: VoidFunction,
    reset: UseFormReset<StartShareExchangesInvestmentsFormValues>,
    investmentIds?: number[],
    draftDealId?: number,
  ) => void;
}

const StartInvestmentShareExchangeModalTable: FC<Props> = ({ onClose, onSuccessStartShareExchange }) => {
  const companyDealPredefinedData = useAppSelector(selectCompanyDealPredefinedData);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
    reset,
  } = useForm<StartShareExchangesInvestmentsFormValues>({
    defaultValues: {
      exchanges: [],
    },
  });

  const {
    fields: dealsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'exchanges',
  });

  const watchExchanges = watch('exchanges');

  const handleSuccessLoadingCallback = () => {
    setIsLoading(false);
  };

  const onConfirmExchanges: SubmitHandler<StartShareExchangesInvestmentsFormValues> = ({ exchanges }) => {
    if (!companyDealPredefinedData?.companyId) return;

    setIsLoading(true);

    onSuccessStartShareExchange(
      exchanges,
      companyDealPredefinedData?.companyId,
      handleSuccessLoadingCallback,
      reset,
      companyDealPredefinedData?.investmentIds,
      companyDealPredefinedData?.draftDealId,
    );
  };

  const classOfSharesOptions: Option[] = useMemo(() => {
    return (
      companyDealPredefinedData?.companySharePrice?.map(({ classOfShares }) => ({
        label: classOfShares,
        value: classOfShares,
      })) || []
    );
  }, [companyDealPredefinedData?.companySharePrice]);

  const closingDatesOptions: Option[] = useMemo(() => {
    return (
      companyDealPredefinedData?.closingDatesDropDown?.map(({ closingDate }) => ({
        label: closingDate,
        value: closingDate,
        isDisabled: isBefore(endOfDay(new Date(closingDate)), endOfDay(new Date())),
      })) || []
    );
  }, [companyDealPredefinedData?.closingDatesDropDown]);

  const appendNewInvestmentsRow = () => {
    const [{ companyPrice }] = companyDealPredefinedData?.companySharePrice || [];

    const { value: classOfShareValue } = classOfSharesOptions[0];
    const { value: closingDateValue } = closingDatesOptions[0];

    append({
      classOfShares: classOfSharesOptions?.length === 1 ? { label: classOfShareValue, value: classOfShareValue } : null,
      companyPrice: companyPrice || '0',
      fundSharePrice: companyDealPredefinedData?.fundSharePrice || '',
      noExchangedShares: '',
      noOfShares: '',
      closingDate: closingDatesOptions?.length === 1 ? { label: closingDateValue, value: closingDateValue } : null,
    });
  };

  useEffect(() => {
    const firstExchangePrefix = `exchanges.0`;

    if (classOfSharesOptions?.length === 1) {
      const { value } = classOfSharesOptions[0];
      const optionToSet: Option = { label: value, value };

      setValue(`${firstExchangePrefix}.${StartShareExchangeFieldNames.CLASS_OF_SHARES}`, optionToSet);
    }

    if (closingDatesOptions?.length === 1) {
      const { value } = closingDatesOptions[0];
      const optionToSet: Option = { label: value, value };

      setValue(`${firstExchangePrefix}.${StartShareExchangeFieldNames.CLOSING_DATE}`, optionToSet);
    }
  }, [classOfSharesOptions, closingDatesOptions, setValue, watchExchanges]);

  useEffect(() => {
    if (!companyDealPredefinedData?.companyName) return;

    const { companySharePrice, ...dealPredefinedData } = companyDealPredefinedData;

    const [{ companyPrice }] = companySharePrice || [];

    reset({
      exchanges: [
        {
          ...dealPredefinedData,
          companyPrice,
        },
      ],
    });
  }, [reset, companyDealPredefinedData]);

  if (!companyDealPredefinedData) return <div className='skeleton-loader h-64 w-full' />;

  return (
    <div className='flex flex-col' id='table-test'>
      <SnippetLayout
        title={companyDealPredefinedData?.companyName}
        description='Please confirm the class of shares and number of shares you’d like to exchange'
        className='mb-10 mt-6 [&>div_p]:pb-2'
        childrenClassName='mt-0'
      >
        <div className='flex flex-col gap-2'>
          {dealsFields?.map((deal, dealIndex) => {
            const dealsPrefix: `exchanges.${number}` = `exchanges.${dealIndex}`;

            const noOfShares = watch(`${dealsPrefix}.${StartShareExchangeFieldNames.NO_OF_SHARES}`);
            const fundSharePrice = watch(`${dealsPrefix}.${StartShareExchangeFieldNames.DAXIA_PRICE}`);

            const watchValues = {
              noOfShares,
              fundSharePrice,
              companyPrice: watch(`${dealsPrefix}.${StartShareExchangeFieldNames.COMPANY_PRICE}`) || '0',
            };

            const watchClassOfShares = watch(`${dealsPrefix}.${StartShareExchangeFieldNames.CLASS_OF_SHARES}`);

            const handleSetCompanyPrice = () => {
              const { companyPrice } =
                companyDealPredefinedData.companySharePrice.find(
                  ({ classOfShares }) => classOfShares === watchClassOfShares?.value,
                ) || {};

              if (companyPrice) {
                setValue(`${dealsPrefix}.${StartShareExchangeFieldNames.COMPANY_PRICE}`, companyPrice);
                setValue(
                  `${dealsPrefix}.${StartShareExchangeFieldNames.NO_OF_EXCHANGED_SHARES}`,
                  calculateNoOfExchangesShares({
                    companyPrice,
                    noOfShares,
                    fundSharePrice,
                  }),
                );
              }
            };

            return (
              <StartInvestmentShareExchangeModalTableItem
                key={deal.id}
                isOnlyOne={dealsFields?.length === 1}
                isLast={dealIndex === dealsFields?.length - 1}
                handleRemoveRow={() => remove(dealIndex)}
                watchClassOfSharesValue={watchClassOfShares?.value}
                handleSetCompanyPrice={handleSetCompanyPrice}
              >
                <FormContent
                  fields={START_INVESTMENT_SHARE_EXCHANGE_FORM_FIELDS(
                    dealIndex,
                    setValue,
                    watchValues,
                    classOfSharesOptions,
                    closingDatesOptions,
                    handleSetCompanyPrice,
                  )}
                  register={register}
                  control={control}
                  errors={errors}
                  anchorPrefix='start-investment-share-exchange-modal'
                />
              </StartInvestmentShareExchangeModalTableItem>
            );
          })}
          {(classOfSharesOptions.length > 1 || closingDatesOptions.length > 1) && (
            <StartInvestmentShareExchangeAddNewRow addNewRow={appendNewInvestmentsRow} />
          )}
        </div>
      </SnippetLayout>

      <StartInvestmentTotalShareExchangeList shareExchanges={watchExchanges} />

      <StartInvestmentShareExchangeModalButtons
        onCancel={onClose}
        onConfirm={handleSubmit(onConfirmExchanges)}
        disabledConfirm={isLoading}
        isLoading={isLoading}
      />
    </div>
  );
};

export default StartInvestmentShareExchangeModalTable;
