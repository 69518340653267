import React, { ReactElement, PropsWithChildren, useEffect, useState } from 'react';

import { Control } from 'react-hook-form';

import { UploadDocumentsFieldNames } from 'constants/documents';
import { selectUserType } from 'modules/current-user/selectors';
import { getCompaniesDocumentsUploadOptions } from 'modules/documents/action';
import { selectCompaniesUploadOptions } from 'modules/documents/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

import SelectCompanySyncInput from './SelectCompanySyncInput';

type Props<T> = {
  control: Control<T>;
};

const COMPANIES_PER_PAGE = 1000;

const UploadDocumentsInputs = <T,>({ control }: PropsWithChildren<Props<T>>): ReactElement => {
  const dispatch = useAppDispatch();
  const options = useAppSelector(selectCompaniesUploadOptions);

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  const userType = useAppSelector(selectUserType);
  const selectProps = {
    className: 'basis-[55%] mb-0',
    name: UploadDocumentsFieldNames.COMPANY,
    control,
  };

  useEffect(() => {
    if (!userType || options?.length) return;

    setIsLoadingOptions(true);

    dispatch(getCompaniesDocumentsUploadOptions({ userType, per_page: COMPANIES_PER_PAGE }))
      .unwrap()
      .finally(() => setIsLoadingOptions(false));
  }, [dispatch, userType]);

  return (
    <div className='flex w-full mt-10'>
      <SelectCompanySyncInput<T>
        label='Company Relation'
        options={options}
        isLoadingOptions={isLoadingOptions}
        {...selectProps}
      />
    </div>
  );
};

export default UploadDocumentsInputs;
