import React, { FC } from 'react';

import cn from 'classnames';

import { GRAPHS_RANGES_DROPDOWN } from 'constants/dashboard';
import { TypographyVariants } from 'constants/shared/typography';
import { GraphItem, SelectOption } from 'interfaces';
import Typography from 'shared-components/Typography';

import AreaChartComponent from '../chart/area-chart/AreaChart';
import TooltipV2 from '../chart/area-chart/TooltipV2';
import MetricsGrowth from '../metrics-growth/MetricsGrowth';
import Select from '../Select';

type Props = {
  onChangeRange: (option: SelectOption | null) => void;
  selectedRange: SelectOption;
  amount: number | null;
  chartData: GraphItem[];
  isLoading: boolean;
  className?: string;
  showTotalArea?: boolean;
  title?: string;
};

const MetricsGraphLayout: FC<Props> = ({
  className,
  selectedRange,
  isLoading,
  chartData,
  onChangeRange,
  amount,
  title,
}) => {
  const validAmount = amount || 0;

  if (isLoading) {
    return (
      <div className={cn('flex w-full h-[220px]', className)}>
        <div className='skeleton-loader w-1/3 mr-4' />

        <div className='skeleton-loader w-2/3' />
      </div>
    );
  }

  return (
    <div className={cn('flex w-full relative h-[220px]', className)}>
      <div className='w-[120px] absolute right-0 -top-[25px]'>
        <Select value={selectedRange} options={GRAPHS_RANGES_DROPDOWN} onChange={onChangeRange} />
      </div>

      <div className='w-1/3 flex flex-col flex justify-between'>
        <div>
          {title && (
            <Typography tag='h3' variant={TypographyVariants.LARGE}>
              {title}
            </Typography>
          )}
          <h3 className='text-4xl font-medium'>{validAmount}</h3>
        </div>

        <MetricsGrowth timePeriod={selectedRange.label} data={chartData} />
      </div>

      <div className={cn('flex items-end w-2/3')}>
        <AreaChartComponent data={chartData} height={190} showXAxis={false} tooltip={TooltipV2} tooltipOffset={-65} />
      </div>
    </div>
  );
};

export default MetricsGraphLayout;
