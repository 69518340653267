import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowLeftSvg } from 'assets/svg/arrow-left.svg';
import { LocalStorageKeys } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { UserTypes } from 'constants/user';
import { userTypes, WHO_ARE_YOU_PAGE_TITLE } from 'constants/who-are-you';
import { AuthLayout, HeaderInfo, WhoAreYouChip } from 'page-components';
import { LinkButton } from 'shared-components';

import { ButtonVariants } from '../constants/shared/button';

const WhoAreYou = () => {
  const navigate = useNavigate();

  const onClickRegister = (selectedUserType: UserTypes) => {
    if (selectedUserType) {
      localStorage.setItem(LocalStorageKeys.USER_TYPE, selectedUserType);
      navigate(ROUTES.signUp);
    }
  };

  return (
    <AuthLayout childrenClassName='max-w-[576px]'>
      <HeaderInfo className='[&_h2]:mb-0' title={WHO_ARE_YOU_PAGE_TITLE} />

      <div className='mb-10'>
        {userTypes.map(({ title, description, userType, icon }, index) => (
          <WhoAreYouChip
            onClick={onClickRegister}
            key={userType}
            className={index === 0 ? 'mb-20' : ''}
            title={title}
            description={description}
            userType={userType}
            icon={icon}
          />
        ))}
      </div>
      <LinkButton to={ROUTES.signIn} variant={ButtonVariants.SECONDARY}>
        <ArrowLeftSvg className='mr-2' /> Back
      </LinkButton>
    </AuthLayout>
  );
};
export default WhoAreYou;
