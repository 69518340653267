import { createSlice } from '@reduxjs/toolkit';

import { META_INITIAL_STATE } from 'constants/global';
import { DEFAULT_METRICS_STATE } from 'constants/shared';
import { InvestorsState } from 'interfaces';

import { INVESTORS_SLICE_NAME } from './action';
import { getInvestorsReducer, getInvestorsMetricsReducer, getInvestorsPieChartMetricsReducer } from './reducers';

export const initialState: InvestorsState = {
  investorsList: {
    list: null,
    isLoading: false,
    ...META_INITIAL_STATE,
  },
  investorsMetrics: {
    ...DEFAULT_METRICS_STATE,
    isLoading: false,
  },
  investorsPieMetrics: {
    isLoading: false,
    chartData: null,
  },
};

const investorsSlice = createSlice({
  name: INVESTORS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getInvestorsReducer(builder);
    getInvestorsMetricsReducer(builder);
    getInvestorsPieChartMetricsReducer(builder);
  },
});

export const investorsReducer = investorsSlice.reducer;
