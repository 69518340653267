import React, { FC, ReactNode } from 'react';

import cn from 'classnames';
import { PlacesType, PositionStrategy, Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { ButtonVariants } from 'constants/shared/button';
import useModal from 'hooks/use-modal/useModal';
import { MissedTooltipPlaces } from 'interfaces';

import Button, { Props as ButtonProps } from './button/Button';

interface Props extends ButtonProps {
  disabledTooltip?: boolean;
  tooltipContent: string | ReactNode;
  anchorId: string;
  wrapperClassName?: string;
  tooltipClassName?: string;
  place?: PlacesType | MissedTooltipPlaces;
  positionStrategy?: PositionStrategy;
  offset?: number;
}

const ButtonWithTooltip: FC<Props> = ({
  wrapperClassName,
  tooltipClassName,
  tooltipContent,
  anchorId,
  disabled,
  place,
  offset,
  variant = ButtonVariants.SECONDARY,
  disabledTooltip,
  positionStrategy,
  ...buttonProps
}) => {
  const { isOpen, onOpen, onClose } = useModal();

  return (
    <>
      <div
        id={anchorId}
        onMouseEnter={!disabledTooltip ? onOpen : () => ''}
        onMouseLeave={onClose}
        className={cn('relative', wrapperClassName)}
      >
        {disabled && <div className='w-full h-full absolute cursor-not-allowed' />}

        <Button disabled={disabled} variant={variant} {...buttonProps}>
          {buttonProps.children}
        </Button>
      </div>

      <Tooltip
        place={place as any}
        anchorId={anchorId}
        isOpen={isOpen}
        offset={offset || -10}
        clickable
        noArrow
        positionStrategy={positionStrategy}
        className={twMerge('text-sm text-grey-500 z-50', tooltipClassName)}
      >
        {tooltipContent}
      </Tooltip>
    </>
  );
};

export default ButtonWithTooltip;
