import React, { FC } from 'react';

import {
  CANCEL_QUALIFICATION_QUESTIONS_TITLE,
  CANCEL_QUALIFICATION_QUESTIONS_DESCRIPTION,
} from 'constants/my-investments';
import { cancelShareExchange } from 'modules/investments/action';
import { selectQualificationQuestions } from 'modules/investments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AlertModal } from 'shared-components';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  onSuccessCancelShareExchangeQuestions: VoidFunction;
  shareExchangeId?: string;
};

const ShareExchangeCancelQualificationQuestionsModal: FC<Props> = ({
  isOpen,
  onClose,
  onSuccessCancelShareExchangeQuestions,
  shareExchangeId,
}) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectQualificationQuestions);

  const onCancelShareExchange = () => {
    if (!shareExchangeId) {
      return;
    }

    dispatch(cancelShareExchange(shareExchangeId))
      .unwrap()
      .then(() => {
        onClose();
        onSuccessCancelShareExchangeQuestions();
      });
  };

  return (
    <AlertModal
      secondModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onCancelShareExchange}
      isLoading={isLoading}
      submitButtonText='Yes'
      backButtonText='No'
      title={CANCEL_QUALIFICATION_QUESTIONS_TITLE}
      description={CANCEL_QUALIFICATION_QUESTIONS_DESCRIPTION}
    />
  );
};

export default ShareExchangeCancelQualificationQuestionsModal;
