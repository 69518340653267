import React, { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { TypographyVariants } from 'constants/shared/typography';

import Typography from './Typography';

interface Props {
  dynamicTitle?: string;
  title: string | ReactNode;
  isLoading?: boolean;
  className?: string;
}

const DynamicPageTitle: FC<Props> = ({ dynamicTitle, title, isLoading, className }) => {
  if (isLoading) return <div className={twMerge('h-10 w-200 xl:w-350 skeleton-loader', className)} />;

  if (dynamicTitle)
    return (
      <Typography className={className} variant={TypographyVariants.MEDIUM}>
        <span className='text-violet-600'>{dynamicTitle} </span>
        {title}
      </Typography>
    );

  return (
    <Typography className={className} variant={TypographyVariants.MEDIUM}>
      {title}
    </Typography>
  );
};

export default DynamicPageTitle;
