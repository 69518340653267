import React, { FC, SyntheticEvent, DragEventHandler } from 'react';

import cn from 'classnames';

import { ReactComponent as UploadIcon } from 'assets/svg/upload.svg';
import Typography from 'shared-components/Typography';

type Props = {
  onClickBrowseFile: VoidFunction;
  onDrop: DragEventHandler;
  allowedFileHelpText: string;
  dragAreaTitle?: string;
  isLoading?: boolean;
};

const DragUploadArea: FC<Props> = ({ onClickBrowseFile, onDrop, allowedFileHelpText, dragAreaTitle, isLoading }) => {
  const allowDrop = (ev: SyntheticEvent) => {
    ev.preventDefault();
  };

  return (
    <div
      onDragOver={allowDrop}
      onDrop={onDrop}
      onClick={onClickBrowseFile}
      className={cn('cursor-pointer', { 'pointer-events-none': isLoading })}
    >
      {dragAreaTitle && <div className='text-grey-200 mb-2'>{dragAreaTitle}</div>}
      <div
        className={cn('w-full h-[230px] rounded-lg bg-dashed flex items-center justify-center rounded-lg flex-col', {
          'cursor-not-allowed bg-grey-500': isLoading,
        })}
      >
        <UploadIcon className='mb-4' />

        <Typography className='text-grey-800'>Drag and Drop here</Typography>
        <Typography className='text-grey-800'>or</Typography>
        <Typography className='text-blue-600 underline'>Browse files</Typography>
      </div>
      <Typography className='text-grey-500 mt-2'>{allowedFileHelpText}</Typography>
    </div>
  );
};

export default DragUploadArea;
