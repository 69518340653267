import React, { PropsWithChildren, ReactElement } from 'react';

import { get } from 'lodash';
import { ArrayPath, Path, Control, useFieldArray, FieldArray, PathValue } from 'react-hook-form';

import { ReactComponent as TrashCanIcon } from 'assets/svg/trash-can.svg';
import { FieldTypes } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import { validateClosingDate } from 'helpers';
import { Button, DatePickerInput } from 'shared-components';
import Typography from 'shared-components/Typography';

type Props<T> = {
  control: Control<T>;
  isEditable?: boolean;
};

const DealParametersClosingDatesFields = <T,>({
  control,
  isEditable = true,
}: PropsWithChildren<Props<T>>): ReactElement => {
  const {
    fields: closingDatesFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'closingDates' as ArrayPath<T>,
  });

  const onClickDeleteClosingDate = (index: number) => {
    remove(index);
  };

  const onClickAddNewClosingDate = () => {
    append({ closingDate: '', isNew: true } as FieldArray<T>);
  };

  const validateClosingDateInput = (closingDateValue: PathValue<T, Path<T>>, isNew: boolean) => {
    return validateClosingDate(closingDateValue as string, !isNew, true);
  };

  return (
    <div>
      <Typography className='bg-grey-100 h-16 text-grey-800 pl-5'>Closing dates</Typography>

      {!!closingDatesFields?.length ? (
        closingDatesFields.map((closingDate, index) => {
          const isNew = get(closingDate, 'isNew');

          const name = `closingDates.${index}.closingDate`;

          return (
            <div key={closingDate.id} className='bg-grey-100 px-5 py-3 flex items-center'>
              <div className='basis-1/2 lg:basis-1/3'>{index + 1}.</div>

              <DatePickerInput
                name={name}
                className='w-[140px] mb-[0px]'
                type={FieldTypes.DATE}
                control={control as Control}
                validation={{
                  validate: (value: PathValue<T, Path<T>>) => validateClosingDateInput(value, isNew),
                }}
                disabled={!isEditable}
              />

              {isEditable && (
                <button type='button' className='basis-1/3 ml-7' onClick={() => onClickDeleteClosingDate(index)}>
                  <TrashCanIcon className='grey-500-svg-path' />
                </button>
              )}
            </div>
          );
        })
      ) : (
        <Typography className='pl-5 mt-4 text-grey-800'>There are no selected closing dates</Typography>
      )}

      {isEditable && (
        <div className='w-full pl-5 flex my-auto'>
          <Button
            className='w-fit mt-4'
            type='button'
            variant={ButtonVariants.SECONDARY}
            onClick={onClickAddNewClosingDate}
          >
            + Add New Date
          </Button>
        </div>
      )}
    </div>
  );
};

export default DealParametersClosingDatesFields;
