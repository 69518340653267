import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { COMPANIES_STATE_DEFAULT_COMPANY_DATA, CURRENT_COMPANY_DEFAULT_STATE } from 'constants/companies';
import {
  CompaniesState,
  CompanyData,
  CompanyResponse,
  CompaniesResponse,
  Option,
  CompaniesMetrics,
  CompanyInvestor,
  DueDiligenceDataWithoutChildren,
  CompanyIac,
} from 'interfaces';

import {
  getCompanyById,
  getCompanyProfileByNumber,
  getMyCompanies,
  getMyCompaniesOptions,
  resetCompanyData,
  getAdminCompanies,
  getAdminCompaniesMetrics,
  updateIsWhitelisted,
  getInvitedCompanyInvestors,
  uploadCompanyInvestors,
  createCompanyInvestorForInvite,
  deleteInvitedCompanyInvestor,
  addCompany,
  getCompanyArchivedDueDiligence,
  getCompanyArchivedIac,
  deleteCompany,
} from './action';

export function getCompanyByIdReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(getCompanyById.pending, (state) => {
    state.currentCompany = { ...CURRENT_COMPANY_DEFAULT_STATE, isLoading: true };
  });

  builder.addCase(getCompanyById.fulfilled, (state, action: PayloadAction<CompanyResponse>) => {
    const { significant_control_persons, officers, ...companyData } = action.payload.data;

    state.currentCompany = {
      isLoading: false,
      data: { ...companyData, extra: action.payload?.extra },
      holders: significant_control_persons,
      officers,
    };
  });

  builder.addCase(getCompanyById.rejected, (state) => {
    state.currentCompany.isLoading = false;
    state.currentCompany.holders = [];
  });
}

export function getMyCompaniesReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(getMyCompanies.pending, (state) => {
    state.companies.isLoading = true;
  });

  builder.addCase(getMyCompanies.fulfilled, (state, action: PayloadAction<CompaniesResponse>) => {
    state.companies = {
      isLoading: false,
      list: action.payload.data,
      totalPages: action.payload.meta.last_page,
      total: action.payload.meta.total,
    };
  });

  builder.addCase(getMyCompanies.rejected, (state) => {
    state.companies.isLoading = false;
  });
}

export function getAdminCompaniesReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(getAdminCompanies.pending, (state) => {
    state.companies.isLoading = true;
    state.companies.list = [];
  });

  builder.addCase(getAdminCompanies.fulfilled, (state, action: PayloadAction<CompaniesResponse>) => {
    state.companies = {
      isLoading: false,
      list: action.payload.data,
      totalPages: action.payload.meta.last_page,
      total: action.payload.meta.total,
    };
  });

  builder.addCase(getAdminCompanies.rejected, (state) => {
    state.companies.isLoading = false;
  });
}

export function getMyCompaniesOptionsReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(getMyCompaniesOptions.fulfilled, (state, action: PayloadAction<Option[]>) => {
    state.myCompaniesOptions = action.payload;
  });
}

export function getCompanyProfileByNumberReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(getCompanyProfileByNumber.fulfilled, (state, action: PayloadAction<CompanyData>) => {
    state.companyData = { data: action.payload, isLoading: false, called: false };
  });
}

export function addCompanyReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(addCompany.pending, (state) => {
    state.companyData.isLoading = true;
    state.companyData.called = false;
  });

  builder.addCase(addCompany.fulfilled, (state) => {
    state.companyData = { data: null, isLoading: false, called: true };
  });

  builder.addCase(addCompany.rejected, (state) => {
    state.companyData.isLoading = false;
  });
}

export function deleteCompanyReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(deleteCompany.pending, (state) => {
    state.companyData.isLoading = true;
  });

  builder.addCase(deleteCompany.fulfilled, (state) => {
    state.companyData = { data: null, isLoading: false, called: !state?.companyData.called };
  });

  builder.addCase(deleteCompany.rejected, (state) => {
    state.companyData.isLoading = false;
  });
}

export function getAdminCompaniesMetricsReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(getAdminCompaniesMetrics.pending, (state) => {
    state.companiesMetrics.isLoading = true;
  });

  builder.addCase(getAdminCompaniesMetrics.fulfilled, (state, action: PayloadAction<CompaniesMetrics>) => {
    state.companiesMetrics = { ...action.payload, isLoading: false };
  });

  builder.addCase(getAdminCompaniesMetrics.rejected, (state) => {
    state.companiesMetrics.isLoading = false;
  });
}

export function resetCompanyDataReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(resetCompanyData, (state) => {
    state.companyData = COMPANIES_STATE_DEFAULT_COMPANY_DATA;
  });
}

export function updateIsWhitelistedReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(updateIsWhitelisted.fulfilled, (state, action: PayloadAction<boolean>) => {
    if (!state.currentCompany.data) {
      return;
    }

    state.currentCompany.data.isWhitelisted = action.payload ? 1 : 0;
  });
}

export function getInvitedCompanyInvestorsReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(getInvitedCompanyInvestors.fulfilled, (state, action: PayloadAction<CompanyInvestor[]>) => {
    state.invitedCompanyInvestors = action.payload;
  });
}

export function uploadCompanyInvestorsReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(uploadCompanyInvestors.fulfilled, (state, action: PayloadAction<CompanyInvestor[]>) => {
    state.invitedCompanyInvestors = [...state.invitedCompanyInvestors, ...action.payload];
  });
}

export function createCompanyInvestorForInviteReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(createCompanyInvestorForInvite.fulfilled, (state, action: PayloadAction<CompanyInvestor>) => {
    state.invitedCompanyInvestors.push(action.payload);
  });
}

export function deleteInvitedCompanyInvestorReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(deleteInvitedCompanyInvestor.fulfilled, (state, action: PayloadAction<number>) => {
    state.invitedCompanyInvestors = state.invitedCompanyInvestors.filter((investor) => investor.id !== action.payload);
  });
}

export function getCompanyArchivedDueDiligenceReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(getCompanyArchivedDueDiligence.pending, (state) => {
    state.archived.archivedDueDiligence = [];
    state.archived.isLoading = true;
  });

  builder.addCase(
    getCompanyArchivedDueDiligence.fulfilled,
    (state, action: PayloadAction<DueDiligenceDataWithoutChildren[]>) => {
      state.archived.archivedDueDiligence = action.payload;
      state.archived.isLoading = false;
    },
  );

  builder.addCase(getCompanyArchivedDueDiligence.rejected, (state) => {
    state.archived.isLoading = true;
  });
}

export function getCompanyArchivedIacReducer(builder: ActionReducerMapBuilder<CompaniesState>) {
  builder.addCase(getCompanyArchivedIac.pending, (state) => {
    state.archived.archivedCompanyIac = [];
    state.archived.isLoading = true;
  });

  builder.addCase(getCompanyArchivedIac.fulfilled, (state, action: PayloadAction<CompanyIac[]>) => {
    state.archived.archivedCompanyIac = action.payload;
    state.archived.isLoading = false;
  });

  builder.addCase(getCompanyArchivedIac.rejected, (state) => {
    state.archived.isLoading = true;
  });
}
