import React from 'react';

import { ReactComponent as ArrowSvg } from 'assets/svg/arrow-right.svg';
import { ROUTES } from 'constants/routes';
import { LinkButton, SnippetLayout } from 'shared-components';
import { navigateByRole } from 'utils';

import AdminFoundersSnippetContent from './AdminFoundersSnippetContent';

const AdminFoundersSnippet = () => {
  return (
    <SnippetLayout
      description={
        <LinkButton to={navigateByRole(true, ROUTES.founders)}>
          View more <ArrowSvg className='ml-2.5' />
        </LinkButton>
      }
      title='Founders'
      className='snippet-title col-span-2 sm:col-span-1 2lg:col-span-2 !2xl:col-span-1'
    >
      <AdminFoundersSnippetContent />
    </SnippetLayout>
  );
};

export default AdminFoundersSnippet;
