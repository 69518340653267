import React, { FocusEventHandler, PropsWithChildren, ReactElement } from 'react';

import cn from 'classnames';
import { debounce } from 'lodash';
import { Control, Controller, Path } from 'react-hook-form';
import { StylesConfig, SelectComponentsConfig, GroupBase } from 'react-select';
import Select from 'react-select/async';

import { REQUIRED_MESSAGE } from 'constants/global';
import { TypographyVariants } from 'constants/shared/typography';
import { Option, WithIsCreated } from 'interfaces';
import { searchCompanies } from 'modules/auth/action';
import { useAppDispatch } from 'modules/store';
import { ErrorMessage, FormLabel } from 'shared-components/index';
import Typography from 'shared-components/Typography';
import { convertObjectToOptions } from 'utils';

interface Props<T> {
  control: Control<T>;
  name: string;
  label: string;
  options?: Option[] | null;
  className?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: (value: WithIsCreated<Option>) => void;
  isReversed?: boolean;
  placeholder?: string;
  disabled?: boolean;
  selectStyles?: StylesConfig;
  description?: string;
  components?: SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>;
  creatable?: boolean;
  isClearable?: boolean;
  disableRequest?: boolean;
}

type OnChangeFunctionType = (value: unknown) => void;

const CompanySelectField = <T,>({
  control,
  className,
  name,
  label,
  onBlur,
  onChange,
  isReversed,
  placeholder,
  disabled,
  selectStyles,
  description,
  components,
  creatable,
  isClearable,
  disableRequest,
}: PropsWithChildren<Props<T>>): ReactElement => {
  const dispatch = useAppDispatch();

  const onSearchCompanies = (searchString: string, callback: CallableFunction) => {
    if (searchString?.length <= 4) return callback([]);

    const formattedSearchString = searchString?.toUpperCase().trim();

    if (disableRequest)
      return callback([{ label: formattedSearchString, value: formattedSearchString, isCreated: true }]);

    dispatch(searchCompanies(searchString))
      .unwrap()
      .then((res) => {
        const isCompanyExist = creatable
          ? Object.entries(res?.items)?.some(([companyNumber, companyName]) =>
              isReversed ? formattedSearchString === companyNumber : formattedSearchString === companyName,
            )
          : false;

        const createdOption =
          !isCompanyExist && creatable
            ? [
                {
                  label: formattedSearchString,
                  value: formattedSearchString,
                  isCreated: true,
                },
              ]
            : [];

        const results =
          res.total_results > 20
            ? [
                {
                  value: '',
                  label: `20 result from ${res.total_results}. Please refine your search query`,
                  isDisabled: true,
                },
                ...createdOption,
                ...convertObjectToOptions(res.items, isReversed),
              ]
            : [...createdOption, ...convertObjectToOptions(res.items, isReversed)];

        callback(results);
      });
    return null;
  };

  const formatOptionsLabel = (props: unknown) => {
    const { label } = props as Option;

    return (
      <div className='overflow-hidden text-ellipsis whitespace-nowrap' title={label}>
        {label}
      </div>
    );
  };

  return (
    <Controller
      name={name as Path<T>}
      control={control}
      rules={{ required: REQUIRED_MESSAGE }}
      render={({ field, fieldState: { error } }) => (
        <div className={className}>
          {label && <FormLabel>{label}</FormLabel>}
          <Select
            {...field}
            onBlur={onBlur}
            isDisabled={disabled}
            noOptionsMessage={() => 'No Companies'}
            className={cn('select-input', { 'select-input-error': error?.message })}
            loadOptions={debounce(onSearchCompanies, 2000)}
            placeholder={placeholder || 'Company Name'}
            styles={selectStyles}
            formatOptionLabel={formatOptionsLabel}
            components={components}
            onChange={(onChange as OnChangeFunctionType) || field.onChange}
            isClearable={isClearable}
          />
          {description && !error && (
            <Typography className='mt-1' variant={TypographyVariants.BODY_SMALL}>
              {description}
            </Typography>
          )}
          <ErrorMessage className='absolute text-xs pl-[5px] pt-[5px] leading-[14px]' error={error?.message} />
        </div>
      )}
    />
  );
};

export default CompanySelectField;
