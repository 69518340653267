import React, { FC, forwardRef, InputHTMLAttributes, SVGProps, useState } from 'react';

import cn from 'classnames';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as Info } from 'assets/svg/info.svg';
import { ReactComponent as SmallInformationIcon } from 'assets/svg/small-info.svg';
import { ReactComponent as VisibilityOff } from 'assets/svg/visibility-off.svg';
import { ReactComponent as Visibility } from 'assets/svg/visibility.svg';
import { TEXT_INPUT_CLASSNAMES } from 'constants/shared';
import { TypographyVariants } from 'constants/shared/typography';
import useModal from 'hooks/use-modal/useModal';
import { FromItemHigligtedColor, SharedHighlighted } from 'interfaces';
import HighlightedMessage from 'shared-components/higlighted-message/HighlightedMessage';
import { FormLabel } from 'shared-components/index';
import Typography from 'shared-components/Typography';

import { FieldTooltipVariants } from '../../constants/shared/input';
import IconWithTooltip from '../IconWithTooltip';
import PrefixSign from '../PrefixSign';

export interface Props extends InputHTMLAttributes<HTMLInputElement>, SharedHighlighted {
  label?: string;
  error?: string;
  warning?: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  shownError?: boolean;
  isAbsoluteError?: boolean;
  tooltipContent?: string;
  tooltipVariant?: FieldTooltipVariants;
  anchorPrefix?: string;
  symbol?: string;
  description?: string;
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      label,
      error,
      warning,
      required,
      icon,
      inputClassName,
      labelClassName,
      isAbsoluteError,
      tooltipContent,
      anchorPrefix,
      onFocus,
      onBlur,
      onChange,
      symbol,
      description,
      highlighted,
      highlightedDescription,
      tooltipVariant = FieldTooltipVariants.WHOLE_INPUT,
      highlightedColor = FromItemHigligtedColor.Blue,
      ...props
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const { isOpen: isTooltipOpen, onOpen: handleOpenTooltip, onClose: handleCloseTooltip } = useModal(false);
    const Icon = icon as FC<SVGProps<SVGSVGElement>>;

    const anchorId = `${anchorPrefix}-${props.name}`;

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) onBlur(event);
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) onFocus(event);
    };

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div
        className={twMerge('mb-6 w-full', className)}
        id={tooltipVariant === FieldTooltipVariants.WHOLE_INPUT ? anchorId : undefined}
        onMouseEnter={handleOpenTooltip}
        onMouseLeave={handleCloseTooltip}
      >
        <div className='flex gap-2'>
          {label && (
            <FormLabel className={labelClassName} htmlFor={props.name}>
              {label}
              {required && '*'}
            </FormLabel>
          )}
          {tooltipVariant === FieldTooltipVariants.WITH_ICON && tooltipContent && (
            <IconWithTooltip
              wrapperClassName='ml-[5px] cursor-pointer'
              tooltipClassName='font-normal'
              tooltipContent={tooltipContent}
              anchorId={anchorId}
              offset={1}
              place='top'
              icon={<SmallInformationIcon />}
            />
          )}
        </div>
        <div>
          <div className='relative'>
            {icon && <Icon className='absolute left-1 top-[calc(50%-6px)] w-3 h-3' />}
            {symbol && <PrefixSign prefixSign={symbol} disabled={props.disabled} />}
            {props.type === 'password' && (
              <button
                type='button'
                className='absolute right-4 top-[calc(50%-12px)] cursor-pointer'
                onClick={togglePasswordVisibility}
              >
                {!showPassword ? <VisibilityOff className='w-6 h-6' /> : <Visibility className='w-6 h-6' />}
              </button>
            )}
            <input
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={onChange}
              {...props}
              type={showPassword ? 'text' : props.type}
              className={cn(
                TEXT_INPUT_CLASSNAMES.default,
                {
                  'pl-4': !!icon,
                  'rounded-l-none': symbol,
                  [TEXT_INPUT_CLASSNAMES.error]: error,
                  [TEXT_INPUT_CLASSNAMES.warning]: warning,
                  [TEXT_INPUT_CLASSNAMES.highlighted[highlightedColor]]: !error && highlighted,
                  [TEXT_INPUT_CLASSNAMES.disabled]: props.disabled,
                },
                inputClassName,
              )}
              ref={ref}
            />
          </div>
          {description && !error && !highlightedDescription && (
            <Typography className='mt-1' variant={TypographyVariants.BODY_SMALL}>
              {description}
            </Typography>
          )}
          {(error || warning) && (
            <div className={cn('flex w-full', { absolute: isAbsoluteError })}>
              <Info
                className={cn('mt-1 w-4 h-4', {
                  '[&>path]:fill-red-700': error,
                  '[&>path]:fill-yellow-700': warning,
                })}
              />
              <div
                className={cn('text-sm pl-1 pt-1.5 leading-14', {
                  'text-red-700': error,
                  'text-yellow-700': warning,
                })}
              >
                {error || warning}
              </div>
            </div>
          )}
          {!error && highlightedDescription && (
            <HighlightedMessage className='input-error-message' color={highlightedColor}>
              {highlightedDescription}
            </HighlightedMessage>
          )}
        </div>
        {tooltipVariant === FieldTooltipVariants.WHOLE_INPUT && tooltipContent && isTooltipOpen && (
          <Tooltip
            anchorId={anchorId}
            positionStrategy='fixed'
            className='text-sm text-grey-500'
            isOpen
            clickable
            noArrow
          >
            {tooltipContent}
          </Tooltip>
        )}
      </div>
    );
  },
);

export default Input;

Input.displayName = 'Input';
