import { UserIntegrationType, UserTypes } from 'constants/user';
import { MetaPage, MetaState, Option, SignUpInput } from 'interfaces';

export interface CreateUserInput extends Omit<SignUpInput, 'website'> {
  companyNumber: Option;
  amountInvested: string;
}

export interface UserData {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  companies: Array<string>;
  created_at: string;
  updated_at: string;
  userType: UserTypes;
  phone?: string;
  website?: string;
  amountInvested: string;
  online: boolean;
  lastActivity?: string | null;
  isRegistrationFinished: boolean;
  invited?: boolean;
}

export type TruncatedUser = Omit<UserData, 'userType' | 'companies'>;

export type UserActionsParamsType = Pick<UserData, 'id' | 'firstName' | 'lastName' | 'isRegistrationFinished'>;

export interface UserIntegrationsData {
  personal: Record<string, boolean>;
  companies: Record<string, boolean>;
}

export interface UserIntegration {
  link: string;
  status: boolean;
  type: UserIntegrationType;
}

export interface UsersState extends MetaState {
  isLoading: boolean;
  isPermissionsLoading: boolean;
  isProfileSettingsActionLoading: boolean;
  isProfileSettingsActionCalled: boolean;
  users: UserData[] | null;
  userData: UserData | null;
  permissions: Permissions;
  enabledPermissions: number[];
}

export type Permissions = Record<string, Permission[]>;

export interface Permission {
  id: number;
  name: string;
  humanName: string;
  moduleName: string;
  created_at: string;
  updated_at: string;
}

export interface PermissionsResponse {
  data: Permissions;
  availablePermissions: Permissions;
}

export interface InviteUserPayload
  extends Omit<CreateUserInput, 'companyNumber' | 'password_confirmation' | 'password'> {
  companyNumber: string;
}

export interface GetUsersPayload {
  data: UserData[];
  meta: MetaPage;
}

export interface GetUserPayload {
  data: UserData;
}

export enum UsersFiltersNameFields {
  REGISTERED = 'registered',
  UNREGISTERED = 'unregistered',
  SELECTED_USER_TYPE = 'selectedUserType',
}

export interface UsersFiltersParams {
  [UsersFiltersNameFields.REGISTERED]: boolean;
  [UsersFiltersNameFields.UNREGISTERED]: boolean;
  [UsersFiltersNameFields.SELECTED_USER_TYPE]: Option[];
}
