import React from 'react';

import { useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { LinkButton } from 'shared-components';
import Typography from 'shared-components/Typography';

interface Props {
  isShown?: boolean;
  additionalQueryParams?: string;
}

const AlreadyRegisteredInfoSection = ({ isShown, additionalQueryParams }: Props) => {
  const { search } = useLocation();

  const navigateRoute = `${ROUTES.signIn}${search}&${additionalQueryParams}`;

  if (!isShown) return null;

  return (
    <div className='col-start-1 col-end-3 row-start-3 row-end-4 flex mb-6'>
      <Typography>You are already registered. Please,</Typography>
      <LinkButton className='ml-1' to={navigateRoute}>
        login
      </LinkButton>
    </div>
  );
};

export default AlreadyRegisteredInfoSection;
