import React, { useMemo } from 'react';

import { DOCUMENTS_PAGE_BASIC_TABS, DOCUMENTS_PAGE_EXTENDED_TABS, MY_DOCUMENTS_PAGE_TITLE } from 'constants/documents';
import { UserTypes } from 'constants/user';
import { selectUserType } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { DashboardLayout } from 'page-components';
import { Tabs, TitleWithDescription } from 'shared-components';

const Documents = () => {
  const userType = useAppSelector(selectUserType);

  const pageTabs = useMemo(() => {
    if (userType === UserTypes.INVESTOR) {
      return DOCUMENTS_PAGE_EXTENDED_TABS;
    }

    return DOCUMENTS_PAGE_BASIC_TABS;
  }, [userType]);

  return (
    <DashboardLayout>
      <div className='min-h-[calc(100vh-124px)] max-w-[1600px] m-4 sm:m-9 p-7 rounded-xl bg-white flex flex-col border border-grey-300 overflow-hidden'>
        <TitleWithDescription className='mb-7.5' title={MY_DOCUMENTS_PAGE_TITLE} />
        <Tabs tabs={pageTabs} />
      </div>
    </DashboardLayout>
  );
};

export default Documents;
