import React, { FC } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { FORGOT_PASSWORD_PAGE_FIELDS } from 'constants/forgot-password';
import { ForgotPasswordInput } from 'interfaces';
import { asyncForgotPassword } from 'modules/auth/action';
import { selectIsActionLoading } from 'modules/auth/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button, FormContent } from 'shared-components';

type Props = {
  setEmail: (email: string) => void;
};

const ForgotPasswordForm: FC<Props> = ({ setEmail }) => {
  const dispatch = useAppDispatch();

  const isActionLoading = useAppSelector(selectIsActionLoading);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ForgotPasswordInput>({ reValidateMode: 'onSubmit' });

  const onSubmit: SubmitHandler<ForgotPasswordInput> = (data) => {
    dispatch(asyncForgotPassword(data))
      .unwrap()
      .then(() => {
        setEmail(data.email);
        reset();
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContent<ForgotPasswordInput> fields={FORGOT_PASSWORD_PAGE_FIELDS} register={register} errors={errors} />
      <Button
        className='mt-8 mb-10 sm:my-0 sm:row-start-2 sm:row-end-3 sm:col-start-3 sm:col-end-4'
        type='submit'
        isLoading={isActionLoading}
      >
        Send Link
      </Button>
    </form>
  );
};

export default ForgotPasswordForm;
