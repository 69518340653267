import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_METRICS_STATE } from 'constants/shared';
import { CommonMetrics, InvestorPieChartMetrics, InvestorsResponse, InvestorsState } from 'interfaces';

import { getInvestors, getInvestorsMetrics, getInvestorsPieChartMetrics } from './action';

export function getInvestorsReducer(builder: ActionReducerMapBuilder<InvestorsState>) {
  builder.addCase(getInvestors.pending, (state) => {
    state.investorsList.isLoading = true;
  });

  builder.addCase(getInvestors.fulfilled, (state, action: PayloadAction<InvestorsResponse>) => {
    state.investorsList.isLoading = false;
    state.investorsList.list = action.payload.data;
    state.investorsList.totalPages = action.payload.meta.last_page;
    state.investorsList.total = action.payload.meta.total;
  });

  builder.addCase(getInvestors.rejected, (state) => {
    state.investorsList.isLoading = false;
  });
}

export function getInvestorsMetricsReducer(builder: ActionReducerMapBuilder<InvestorsState>) {
  builder.addCase(getInvestorsMetrics.pending, (state) => {
    state.investorsMetrics.isLoading = true;
  });

  builder.addCase(getInvestorsMetrics.fulfilled, (state, action: PayloadAction<CommonMetrics>) => {
    state.investorsMetrics = { ...action.payload, isLoading: false };
  });

  builder.addCase(getInvestorsMetrics.rejected, (state) => {
    state.investorsMetrics = { ...DEFAULT_METRICS_STATE, isLoading: false };
  });
}

export function getInvestorsPieChartMetricsReducer(builder: ActionReducerMapBuilder<InvestorsState>) {
  builder.addCase(getInvestorsPieChartMetrics.pending, (state) => {
    state.investorsPieMetrics.isLoading = true;
  });

  builder.addCase(getInvestorsPieChartMetrics.fulfilled, (state, action: PayloadAction<InvestorPieChartMetrics>) => {
    state.investorsPieMetrics = { chartData: { ...action.payload }, isLoading: false };
  });

  builder.addCase(getInvestorsPieChartMetrics.rejected, (state) => {
    state.investorsPieMetrics = { isLoading: false, chartData: null };
  });
}
