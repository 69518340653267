import React from 'react';

import { ReactComponent as WarningIcon } from 'assets/svg/warning-outlined.svg';
import { MY_INVESTMENTS_PAGE_TABS_IDS } from 'constants/investments';
import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { selectCompaniesWithInvestments } from 'modules/investments/selectors';
import { useAppSelector } from 'modules/store';
import { LinkButton } from 'shared-components';
import SnippetNoInfoLayout from 'shared-components/SnippetNoInfoLayout';
import Typography from 'shared-components/Typography';
import { getLinkWithTabId } from 'utils';

const ShareExchangeSnippetNoInfo = () => {
  const companiesWithInvestments = useAppSelector(selectCompaniesWithInvestments);

  return (
    <SnippetNoInfoLayout icon={<WarningIcon />}>
      <Typography className='flex text-grey-800'>
        {companiesWithInvestments?.length > 0 ? (
          <>
            Complete next steps within
            <LinkButton
              className='text-inherit ml-1'
              variant={ButtonVariants.UNDERLINED_LINK}
              to={getLinkWithTabId(ROUTES.myInvestments, MY_INVESTMENTS_PAGE_TABS_IDS.INVESTMENTS)}
            >
              My Investments
            </LinkButton>
          </>
        ) : (
          <>
            <LinkButton className='text-inherit mr-1' variant={ButtonVariants.UNDERLINED_LINK} to={ROUTES.investments}>
              Add Investments
            </LinkButton>
            to start an Exchange
          </>
        )}
      </Typography>
    </SnippetNoInfoLayout>
  );
};

export default ShareExchangeSnippetNoInfo;
