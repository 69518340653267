import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as FailedIcon } from 'assets/svg/cancel-circle.svg';
import { DOCUMENTS_PAGE_TABS_IDS } from 'constants/documents';
import { PAY_BILL_MODAL_TITLE, payBillModalCommonStyles } from 'constants/my-bills';
import { ROUTES } from 'constants/routes';
import { TypographyVariants } from 'constants/shared/typography';
import { ModalWindow, Button } from 'shared-components';
import Typography from 'shared-components/Typography';

import PayBillModalsHeader from './PayBillModalsHeader';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
};

const PayBillUnSuccessModal: FC<Props> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const navigateToBills = () => {
    onClose();
    navigate({ pathname: ROUTES.DOCUMENTS, search: `?tab=${DOCUMENTS_PAGE_TABS_IDS.INVOICES}` });
  };

  return (
    <ModalWindow
      {...payBillModalCommonStyles}
      contentClassName='h-full flex flex-col'
      isOpen={isOpen}
      onClose={onClose}
    >
      <PayBillModalsHeader title={PAY_BILL_MODAL_TITLE} />

      <div className='flex flex-col items-center mt-12'>
        <FailedIcon className='w-12 h-[48px]' />

        <Typography className='my-6 max-w-2xl text-center' tag='h3' variant={TypographyVariants.SEMI_LARGE}>
          Payment un-successful! Please review your payment details and try again
        </Typography>

        <Button onClick={navigateToBills} className='mt-10'>
          Return To Bills
        </Button>
      </div>
    </ModalWindow>
  );
};

export default PayBillUnSuccessModal;
