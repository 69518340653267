import React, { useEffect } from 'react';

import { DASHBOARD_DEALS_SNIPPET_STAGES_LABELS, DASHBOARD_PIE_CHART_COLORS } from 'constants/dashboard';
import useGraphRanges from 'hooks/use-grpah-ranges/useGraphRanges';
import { getDealsStaticMetrics, getDealsFinishedMetrics, getDealsStartedMetrics } from 'modules/deals/action';
import {
  selectIsLoadingMetrics,
  selectDealsTotalInStagesMetrics,
  selectDealsTotalFinishedMetrics,
  selectDealsTotalStartedMetrics,
} from 'modules/deals/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { MetricsGraphLayout } from 'shared-components';
import { PieChart, PieChartActiveShapeV2 } from 'shared-components/chart';
import { convertObjectToAreaData, convertObjectToChartData, getSumOfObjectValues } from 'utils';

const AdminDealsSnippetContent = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoadingMetrics);
  const totalInStages = useAppSelector(selectDealsTotalInStagesMetrics);
  const totalStarted = useAppSelector(selectDealsTotalStartedMetrics);
  const totalFinished = useAppSelector(selectDealsTotalFinishedMetrics);

  const { selectedRange: selectedFinishedRange, onChangeRange: onChangeFinishedRange } = useGraphRanges();
  const { selectedRange: selectedStartedRange, onChangeRange: onChangeStartedRange } = useGraphRanges();

  useEffect(() => {
    dispatch(getDealsStaticMetrics(DASHBOARD_DEALS_SNIPPET_STAGES_LABELS));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDealsStartedMetrics(selectedStartedRange.value));
  }, [dispatch, selectedStartedRange.value]);

  useEffect(() => {
    dispatch(getDealsFinishedMetrics(selectedFinishedRange.value));
  }, [dispatch, selectedFinishedRange.value]);

  return (
    <>
      <MetricsGraphLayout
        title='Started'
        showTotalArea={false}
        selectedRange={selectedStartedRange}
        amount={totalStarted.total}
        isLoading={totalStarted.isLoading}
        chartData={convertObjectToAreaData(totalStarted.totalStarted)}
        onChangeRange={onChangeStartedRange}
      />

      <MetricsGraphLayout
        title='Completed'
        showTotalArea={false}
        className='px-2'
        selectedRange={selectedFinishedRange}
        amount={totalFinished.total}
        isLoading={totalFinished.isLoading}
        chartData={convertObjectToAreaData(totalFinished.totalFinished)}
        onChangeRange={onChangeFinishedRange}
      />

      <PieChart
        showTooltip
        className='justify-self-center row-start-2 col-span-2 1xl:col-start-3 1xl:row-start-1'
        paddingAngle={0}
        isLoading={isLoading}
        colors={DASHBOARD_PIE_CHART_COLORS}
        data={convertObjectToChartData({ ...totalInStages })}
        activeShape={<PieChartActiveShapeV2 title='SE in Stages' customValue={getSumOfObjectValues(totalInStages)} />}
      />
    </>
  );
};

export default AdminDealsSnippetContent;
