import React from 'react';

import cn from 'classnames';

interface Props {
  error?: string;
  disabled?: boolean;
  prefixSign?: string;
}

const PrefixSign = ({ error, disabled, prefixSign = '£' }: Props) => (
  <div
    className={cn(
      'min-w-[46px] text-center flex items-center justify-center rounded border border-grey-300 -mr-1 rounded-l-lg',
      {
        'border border-red-500 border-r-0': !!error,
        'bg-grey-400 border-grey-200': disabled,
        'bg-grey-300': !disabled,
      },
    )}
  >
    {prefixSign}
  </div>
);

export default PrefixSign;
