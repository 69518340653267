import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  adminDealParametersApis,
  adminDealsApi,
  adminDealsMetrics,
  adminFounderApis,
  apis,
  dealsApis,
  founderDealsApis,
  foundersCompaniesApis,
  investorCompaniesApis,
} from 'apis';
import {
  SHARE_EXCHANGE_COMPLETE_MESSAGE,
  SHARE_EXCHANGE_STOP_MESSAGE,
  SHARE_EXCHANGES_COMPLETE_MESSAGE,
  SHARE_EXCHANGES_STOP_MESSAGE,
} from 'constants/share-exchange';
import { showServerError, successNotify } from 'helpers';
import {
  Deal,
  DealPreviewClosureOverviewPayload,
  DealSendClosureOverviewPayload,
  FullDealParameters,
  GetDealsOptionsPayload,
  GetDealsPayload,
  GetListPayload,
  UpdateDealParametersPayload,
  WithIsAdmin,
} from 'interfaces';
import { checkIsInvestor, mapObjectsWithLabels } from 'utils';
import { getDeletedShareExchangesMessage } from 'utils/messages';

import { UserTypes } from '../../constants/user';

export const DEALS_SLICE_NAME = 'deals';

export const getDeals = createAsyncThunk(`${DEALS_SLICE_NAME}/getDeals`, async (data: GetDealsPayload) => {
  const { isAdmin = false, query, ...dealsPayload } = data;
  const adminQuery = query ? query.toLowerCase().replace('in progress', 'inprogress') : undefined;

  const response = await (isAdmin
    ? adminDealsApi.getDeals({ query: adminQuery, ...dealsPayload })
    : dealsApis.getDeals({ query, ...dealsPayload }));

  return response.data;
});

export const getDealDetails = createAsyncThunk(
  `${DEALS_SLICE_NAME}/getDealDetails`,
  async ({ id, isAdmin }: { id: string | number; isAdmin?: boolean }, { dispatch }) => {
    const response = await (isAdmin ? adminDealsApi.getDealDetails(id) : dealsApis.getDealDetails(id));

    dispatch(setDealDetails(response.data.data));

    return response.data.data;
  },
);

export const deleteDeals = createAsyncThunk(
  `${DEALS_SLICE_NAME}/deleteDeals`,
  async ({ dealsIds, isAdmin }: WithIsAdmin<{ dealsIds: number[] }>, { rejectWithValue }) => {
    try {
      await Promise.all(
        dealsIds.map((dealId) => (isAdmin ? adminDealsApi.deleteDeal(dealId) : dealsApis.deleteDeal(dealId))),
      );

      successNotify(getDeletedShareExchangesMessage(dealsIds?.length));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const deleteDeal = createAsyncThunk(
  `${DEALS_SLICE_NAME}/deleteDeal`,
  async ({ id, isAdmin }: WithIsAdmin<{ id: number }>, { rejectWithValue }) => {
    try {
      const response = await (isAdmin ? adminDealsApi.deleteDeal(id) : dealsApis.deleteDeal(id));

      successNotify('The deal was successfully deleted');
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getDealStages = createAsyncThunk(`${DEALS_SLICE_NAME}/getDealStages`, async () => {
  const {
    data: { data },
  } = await apis.getDealStages();

  return Object.keys(data).map((key) => {
    const { stage, doc, label } = data[key];

    return {
      label,
      stage,
      documentKey: doc,
    };
  });
});

export const getDashboardDeals = createAsyncThunk(
  `${DEALS_SLICE_NAME}/getDashboardDeal`,
  async (data: GetDealsPayload & { initialFetch?: boolean }) => {
    const { initialFetch } = data;
    const response = await (checkIsInvestor() ? dealsApis.getDeals(data) : founderDealsApis.getDeals(data));

    return { ...response.data, initialFetch };
  },
);

export const getDealsOptions = createAsyncThunk(
  `${DEALS_SLICE_NAME}/getDealsOptions`,
  async (payload?: GetDealsOptionsPayload) => {
    const { isAdmin, ...payloadData } = payload || {};

    const params = { page: 1, per_page: 10, ...payloadData };

    const {
      data: { data },
    } = await (isAdmin ? adminDealsApi.getDeals(params) : dealsApis.getDeals(params)); // TODO: Update when BE prepare endpoint

    return data.map(({ dealId, companyName, id }) => ({
      label: `${dealId} / ${companyName}`,
      value: id.toString(),
    }));
  },
);

export const getDealsFinishedMetrics = createAsyncThunk(
  `${DEALS_SLICE_NAME}/getAdminFinishedDealsMetrics`,
  async (rangeFilter: string) => {
    const totalFinishedDealsMetrics = await adminDealsMetrics.getDealsTotalFinishedMetrics(rangeFilter);

    return totalFinishedDealsMetrics.data;
  },
);

export const getDealsStartedMetrics = createAsyncThunk(
  `${DEALS_SLICE_NAME}/getAdminStartedDealsMetrics`,
  async (rangeFilter: string) => {
    const totalStartedDealsMetrics = await adminDealsMetrics.getDealsTotalStartedMetrics(rangeFilter);

    return totalStartedDealsMetrics.data;
  },
);

export const getDealsStaticMetrics = createAsyncThunk(
  `${DEALS_SLICE_NAME}/getAdminDealsMetrics`,
  async (stagesLabels: Record<string, string>) => {
    const {
      data: { totals: totalInStages },
    } = await adminDealsMetrics.getDealsTotalInStagesMetrics();

    return {
      totalInStages: mapObjectsWithLabels(totalInStages, stagesLabels),
    };
  },
);

export const getDealsParameters = createAsyncThunk(
  `${DEALS_SLICE_NAME}/getDealsParameters`,
  async (payload: GetListPayload, { rejectWithValue }) => {
    try {
      const response = await adminDealParametersApis.getDealsParameters(payload);

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getDealParametersById = createAsyncThunk(
  `${DEALS_SLICE_NAME}/getDealParametersById`,
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const {
        data: { closingDatesAvailableForOverview, data },
      } = await adminDealParametersApis.getDealParametersById(id);

      dispatch(setDealParameters({ ...data, closingDatesAvailableForOverview }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const updateDealParametersById = createAsyncThunk(
  `${DEALS_SLICE_NAME}/updateDealParametersById`,
  async (payload: UpdateDealParametersPayload, { rejectWithValue }) => {
    try {
      const response = await adminDealParametersApis.updateDealParametersById(payload);

      successNotify('Deal Parameters were successfully updated');

      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const finishDealById = createAsyncThunk(
  `${DEALS_SLICE_NAME}/finishDealById`,
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminDealParametersApis.finishDealParametersById(id);

      dispatch(setDealParameters(response.data.data));
      successNotify('Deal was successfully finished');

      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const reactivateDealById = createAsyncThunk(
  `${DEALS_SLICE_NAME}/reactivateDealById`,
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminDealParametersApis.reactivateDealParametersById(id);

      dispatch(setDealParameters(response.data.data));
      successNotify('Deal was successfully reactivated');

      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const stopDealById = createAsyncThunk(
  `${DEALS_SLICE_NAME}/stopDealById`,
  async ({ id, isAdmin, stopReason }: { id: string; isAdmin?: boolean; stopReason?: string }, { rejectWithValue }) => {
    try {
      const response = await (isAdmin
        ? adminDealsApi.stopDealById({ id, stopReason })
        : dealsApis.stopDealById({ id, stopReason }));
      successNotify('The deal was successfully stopped');

      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const completeDealsParametersById = createAsyncThunk(
  `${DEALS_SLICE_NAME}/completeDealsParametersById`,
  async (dealIds: number[], { rejectWithValue }) => {
    try {
      await Promise.all(dealIds.map((dealId) => adminDealsApi.completeDealById(dealId)));

      successNotify(dealIds?.length > 1 ? SHARE_EXCHANGES_COMPLETE_MESSAGE : SHARE_EXCHANGE_COMPLETE_MESSAGE);

      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const stopDealsParametersById = createAsyncThunk(
  `${DEALS_SLICE_NAME}/stopDealsById`,
  async ({ dealIds, reason }: { dealIds: number[]; reason: string }, { rejectWithValue }) => {
    try {
      const response = await Promise.all(
        dealIds.map((dealId) => adminDealsApi.stopDealById({ id: dealId, stopReason: reason })),
      );

      successNotify(dealIds?.length > 1 ? SHARE_EXCHANGES_STOP_MESSAGE : SHARE_EXCHANGE_STOP_MESSAGE);

      return response.reduce((acc, { data }) => ({ ...acc, [data?.data?.id]: data?.data?.status }), {});
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getCompanyDealPredefinedData = createAsyncThunk(
  `${DEALS_SLICE_NAME}/getCompanyDealPredefinedData`,
  async (
    { companyId, investmentIds, draftDealId }: { companyId: number; investmentIds?: number[]; draftDealId?: number },
    { rejectWithValue },
  ) => {
    try {
      const response = await investorCompaniesApis.getCompanyDealsPredefinedData(companyId);
      return { ...response.data.data, companyId, investmentIds, draftDealId };
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const sendEmailNotificationsByDealId = createAsyncThunk(
  `${DEALS_SLICE_NAME}/sendEmailNotificationsByDealId`,
  async (dealIds: number[], { rejectWithValue }) => {
    try {
      await Promise.all(dealIds.map((dealId) => adminDealsApi.sendEmailNotificationByDealId(dealId)));

      successNotify('The emails has been sent');

      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const previewDealClosureOverview = createAsyncThunk(
  `${DEALS_SLICE_NAME}/previewDealClosureOverview`,
  async ({ id, selectedClosingDates }: DealPreviewClosureOverviewPayload, { rejectWithValue }) => {
    try {
      const response = await adminDealParametersApis.previewDealClosureOverview({ id, selectedClosingDates });
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const sendDealClosureOverview = createAsyncThunk(
  `${DEALS_SLICE_NAME}/sendDealClosureOverview`,
  async (payload: DealSendClosureOverviewPayload, { rejectWithValue }) => {
    try {
      await adminDealParametersApis.sendDealClosureOverview(payload);

      successNotify('The emails has been sent');

      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getDealsProgressList = createAsyncThunk(
  `${DEALS_SLICE_NAME}/getDealsProgressList`,
  async ({ founderId, userType }: { founderId?: string; userType: UserTypes }, { rejectWithValue }) => {
    try {
      const response = await (userType === UserTypes.FOUNDER
        ? foundersCompaniesApis.getDealsProgressList()
        : adminFounderApis.getFounderDealsProgress(founderId));

      return response.data.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getDealDetailsByPlainlyId = createAsyncThunk(
  `${DEALS_SLICE_NAME}/getDealDetailsByPlainlyId`,
  async (plainlyId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await dealsApis.getDealIdByPlainlyId(plainlyId);

      dispatch(setDealDetails({ ...response.data.data }));

      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getLastDraftDealPredefinedData = createAsyncThunk(
  `${DEALS_SLICE_NAME}/getLastDraftDealPredefinedData`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await dealsApis.getLastDealPredefinedData();
      return { ...response.data.data };
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const restartDeal = createAsyncThunk(
  `${DEALS_SLICE_NAME}/restartDeal`,
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const response = await adminDealsApi.restartDeal(id);

      successNotify('The deal was successfully restarted');
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const setDealParameters = createAction<FullDealParameters>('SET_DEAL_PARAMETERS');

export const setDealDetails = createAction<Deal>('SET_DEAL_DETAILS');
