import React from 'react';

import { MY_BILLS_PAGE_TITLE, MY_BILLS_PAGE_DESCRIPTION } from 'constants/my-bills';
import { TitleWithDescription } from 'shared-components';

import BillsContainer from './my-bills/bills/BillsContainer';

const MyBillsTab = () => (
  <div>
    <TitleWithDescription
      className='mb-6'
      descriptionClassName='pt-2 pb-0'
      title={MY_BILLS_PAGE_TITLE}
      description={MY_BILLS_PAGE_DESCRIPTION}
    />
    <BillsContainer />
  </div>
);

export default MyBillsTab;
