import React, { FC, ReactNode } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { TableRowVariants, TableRowVariantsStyles } from 'constants/shared';

type Props = {
  children: ReactNode;
  className?: string;
  variant?: TableRowVariants;
  disabled?: boolean;
};

const TableRow: FC<Props> = ({ children, className, variant = TableRowVariants.MAIN, disabled }) => {
  return (
    <tr
      className={twMerge(
        cn(TableRowVariantsStyles[variant], className, {
          'pointer-events-none opacity-40': disabled,
        }),
      )}
    >
      {children}
    </tr>
  );
};

export default TableRow;
