import React, { FC, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { InvitingFundFieldNames } from 'constants/company-iac';
import { REQUIRED_MESSAGE } from 'constants/global';
import { ButtonVariants } from 'constants/shared/button';
import { UseModalReturnValues } from 'hooks/use-modal/useModal';
import { NumberOrNull, UserData } from 'interfaces';
import { companyIacInviteExistingFmUsers, getFmUsers, getFundManagers } from 'modules/company-iac/action';
import { useAppDispatch } from 'modules/store';
import { Button, ModalWindow, AsyncSelectField } from 'shared-components';
import Typography from 'shared-components/Typography';
import { getInvitingModalTitle } from 'utils';

import InvitingFundModalAddForm from './InvitingFundModalAddForm';
import InvitingFundModalFmUsersItem from './InvitingFundModalFmUsersItem';
import InvitingFundModalFmUsersSkeleton from './InvitingFundModalFmUsersSkeleton';

const FM_USERS_INITIAL_STATE = {
  fmManagerId: null,
  list: [],
  isLoading: false,
};

interface Props extends Omit<UseModalReturnValues, 'onOpen'> {
  companyIacId?: number;
  companyId?: string;
}

const InvitingFundModal: FC<Props> = ({ isOpen, onClose, companyIacId, companyId }) => {
  const dispatch = useAppDispatch();

  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [isActionLoading, setIsActionLoading] = useState(false);

  const [{ fmManagerId, list: fmUsersList, isLoading }, setFmUsers] = useState<{
    fmManagerId: NumberOrNull;
    list: UserData[];
    isLoading: boolean;
  }>(FM_USERS_INITIAL_STATE);

  const { control, handleSubmit, watch, reset } = useForm();

  const fundManagerId = watch(InvitingFundFieldNames.FM_MANAGER)?.value;

  const onSubmit = () => {
    if (!companyIacId) return;

    setIsActionLoading(true);

    const filteredFmUsers = fmUsersList.filter((fmUser) => selectedUsers.includes(fmUser.id));
    dispatch(companyIacInviteExistingFmUsers({ fmUsers: filteredFmUsers, id: companyIacId, fundManagerId, companyId }))
      .unwrap()
      .then(() => {
        setFmUsers(FM_USERS_INITIAL_STATE);
        setSelectedUsers([]);
        reset({ [InvitingFundFieldNames.FM_MANAGER]: '' });
        onClose();
      })
      .finally(() => {
        setIsActionLoading(false);
      });
  };

  const handleCloseAddForm = () => setIsAddFormOpen(false);

  const handleOpenAddForm = () => setIsAddFormOpen(true);

  const handleSetSelectedUsers = (id: number) => {
    setSelectedUsers((users) => {
      return users.includes(id) ? users.filter((userId) => userId !== id) : [...users, id];
    });
  };

  const addNewFmUser = (user: UserData) =>
    setFmUsers((prev) => ({
      ...prev,
      list: [...prev.list, { ...user, type: user.userType }],
    }));

  useEffect(() => {
    if (fundManagerId && fundManagerId !== fmManagerId) {
      setFmUsers((prev) => ({ ...prev, isLoading: true }));
      dispatch(getFmUsers({ id: fundManagerId, filter: `filter[iac]=${companyIacId}` }))
        .unwrap()
        .then(({ data: fmUsers }) => {
          setFmUsers({
            fmManagerId: fundManagerId,
            list: fmUsers,
            isLoading: false,
          });
        });
    }
  }, [companyIacId, dispatch, fmManagerId, fundManagerId]);

  return (
    <ModalWindow
      title={getInvitingModalTitle(isAddFormOpen)}
      className='lg:w-8/12 xl:w-6/12'
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='mt-9'>
        {isAddFormOpen ? (
          <InvitingFundModalAddForm handleCloseAddForm={handleCloseAddForm} handleAddNewUser={addNewFmUser} />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <AsyncSelectField
              control={control}
              name={InvitingFundFieldNames.FM_MANAGER}
              label='Fund Manager'
              placeholder='Select Fund Manager'
              fetchOptions={getFundManagers}
              noOptionsMessage='No Fund Managers found'
              validation={{ required: REQUIRED_MESSAGE }}
              defaultOptions
            />
            {fundManagerId ? (
              <div className='mt-5'>
                <Typography className='mb-1'>FM Users</Typography>
                <div>
                  {!isLoading && fmUsersList?.length ? (
                    fmUsersList.map((user) => (
                      <InvitingFundModalFmUsersItem
                        handleSetSelectedUsers={handleSetSelectedUsers}
                        checked={selectedUsers.includes(user.id)}
                        className='last:border-b'
                        key={user.id}
                        {...user}
                      />
                    ))
                  ) : isLoading ? (
                    <InvitingFundModalFmUsersSkeleton />
                  ) : null}
                </div>
                <Button onClick={handleOpenAddForm} className='mt-2.5' type='button' variant={ButtonVariants.LINK}>
                  Not in list
                </Button>
              </div>
            ) : null}
            <Button className='mt-10' disabled={!selectedUsers?.length} isLoading={isActionLoading}>
              Invite Users
            </Button>
          </form>
        )}
      </div>
    </ModalWindow>
  );
};

export default InvitingFundModal;
