import React from 'react';

import { Link } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import Typography from 'shared-components/Typography';

const AlreadyExistEmailToast = () => {
  return (
    <Typography className='text-grey-800'>
      Looks like you already have an account click here to
      <Link to={ROUTES.signIn}>log in</Link>
    </Typography>
  );
};

export default AlreadyExistEmailToast;
