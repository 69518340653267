import React, { FC } from 'react';

import cn from 'classnames';
import { format } from 'date-fns';

import { ReactComponent as CheckMarkSvg } from 'assets/svg/check-mark-v3.svg';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { dealStatusesLabel } from 'constants/deals';
import { ButtonVariants } from 'constants/shared/button';
import { CHECKMARK_PATH_STYLES } from 'constants/styles';
import { Deal } from 'interfaces';
import { Checkbox, ColoredLabel, LinkButton } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import { getDealDetailsLink, separateAndCapitalizeString } from 'utils';
import { checkIsDealDeleted } from 'utils/deals';

type Props = {
  deal: Deal;
  handleSelectDeal: (deal: Deal) => void;
  selected: boolean;
};

const DealsTableRow: FC<Props> = ({ deal, handleSelectDeal, selected }) => {
  const isDealDeleted = checkIsDealDeleted(deal.status);

  const onSelectDeal = () => {
    if (isDealDeleted) return;

    handleSelectDeal(deal);
  };

  return (
    <TableRow>
      <TableData>
        {!isDealDeleted && (
          <LinkButton className='w-40' variant={ButtonVariants.SECONDARY} to={getDealDetailsLink(deal.id, true)}>
            View
          </LinkButton>
        )}
      </TableData>
      <TableData className='w-10'>
        <Checkbox onChange={onSelectDeal} checked={selected} disabled={isDealDeleted} />
      </TableData>
      <TableData>{deal.companyName}</TableData>
      <TableData>{deal.investor}</TableData>
      <TableData>{deal.phone}</TableData>
      <TableData>{deal?.investorEmail}</TableData>
      <TableData>
        {
          <CheckMarkSvg
            className={cn('grey-300-svg-path', {
              [CHECKMARK_PATH_STYLES]: deal?.paid,
            })}
          />
        }
      </TableData>
      <TableData>{separateAndCapitalizeString(deal.stage)}</TableData>
      <TableData>
        <ColoredLabel {...dealStatusesLabel[deal.status]} />
      </TableData>
      <TableData>{deal.closingDate ? format(new Date(deal.closingDate), DAY_FORMAT_WITH_SLASHES) : ''}</TableData>
    </TableRow>
  );
};

export default DealsTableRow;
