import React from 'react';

import { useNavigate } from 'react-router-dom';

import { MY_INVESTMENTS_SNIPPET_TITLE } from 'constants/dashboard';
import { MY_INVESTMENTS_OVERVIEW_TABLE_COLUMNS, MY_INVESTMENTS_PAGE_TABS_IDS } from 'constants/investments';
import { ConstraintVariants } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import { TypographyVariants } from 'constants/shared/typography';
import useInvestments from 'hooks/useInvestments';
import { MyInvestmentsTable } from 'page-components';
import InvestmentsLayout from 'page-components/investments/InvestmentsLayout';
import { Button, Constraint } from 'shared-components';
import Typography from 'shared-components/Typography';
import { getFormattedAllInvestmentsIncludedDaxia } from 'utils/investments';

const MyInvestmentsOverviewContainer = () => {
  const { investments, isLoading, updateSorting } = useInvestments({});
  const navigate = useNavigate();

  const formattedInvestments = getFormattedAllInvestmentsIncludedDaxia(investments);

  return (
    <Constraint variant={ConstraintVariants.FULL_ROUNDED} className='min-h-min'>
      <InvestmentsLayout
        customHeader={
          <Typography tag='h4' variant={TypographyVariants.MEDIUM}>
            {MY_INVESTMENTS_SNIPPET_TITLE}
          </Typography>
        }
        className='items-start mb-6 flex-col xs:flex-row gap-4 xs:gap-0'
        childrenClassName='justify-end'
      >
        <MyInvestmentsTable
          investments={formattedInvestments}
          updateSorting={updateSorting}
          isLoading={isLoading}
          limit={4}
          columns={MY_INVESTMENTS_OVERVIEW_TABLE_COLUMNS}
          isAvailableAdditionalActions
        />
      </InvestmentsLayout>

      <div className='flex justify-center'>
        <Button
          className='mt-6'
          variant={ButtonVariants.SECONDARY}
          onClick={() => navigate({ search: `?tab=${MY_INVESTMENTS_PAGE_TABS_IDS.INVESTMENTS}` })}
        >
          View all investments
        </Button>
      </div>
    </Constraint>
  );
};

export default MyInvestmentsOverviewContainer;
