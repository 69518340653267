import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const selectState = (x: RootState) => x.investorsReducer;

export const selectInvestorsData = createSelector(selectState, ({ investorsList }) => investorsList);

export const selectInvestorsMetrics = createSelector(selectState, ({ investorsMetrics }) => investorsMetrics);

export const selectInvestorsPieChartMetrics = createSelector(
  selectState,
  ({ investorsPieMetrics }) => investorsPieMetrics,
);
