import React, { FC } from 'react';

import { CANCEL_SHARE_EXCHANGE_MODAL_TITLE, CANCEL_SHARE_EXCHANGE_MODAL_DESCRIPTION } from 'constants/share-exchange';
import { AlertModal } from 'shared-components';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: VoidFunction;
  onCancelShareExchange: VoidFunction;
};

const ShareExchangeCancelQualificationQuestionsModal: FC<Props> = ({
  isOpen,
  onClose,
  isLoading,
  onCancelShareExchange,
}) => {
  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onCancelShareExchange}
      isLoading={isLoading}
      submitButtonText='Yes'
      backButtonText='No'
      title={CANCEL_SHARE_EXCHANGE_MODAL_TITLE}
      description={CANCEL_SHARE_EXCHANGE_MODAL_DESCRIPTION}
    />
  );
};

export default ShareExchangeCancelQualificationQuestionsModal;
