import React from 'react';

import { ReactComponent as ArrowSvg } from 'assets/svg/arrow-right.svg';
import { ROUTES } from 'constants/routes';
import { SnippetLayout, LinkButton } from 'shared-components';
import { navigateByRole } from 'utils';

import AdminCompaniesSnippetContent from './AdminCompaniesSnippetContent';

const AdminCompaniesSnippet = () => {
  return (
    <SnippetLayout
      title='Companies'
      description={
        <LinkButton to={navigateByRole(true, ROUTES.companies)}>
          View more <ArrowSvg className='ml-2.5' />
        </LinkButton>
      }
      className='snippet-title col-span-2 sm:col-span-1 !2xl:order-last'
      childrenClassName='mt-10 flex items-center justify-between lg:-ml-4 2lg:-ml-0 2lg:px-4'
    >
      <AdminCompaniesSnippetContent />
    </SnippetLayout>
  );
};

export default AdminCompaniesSnippet;
