import React, { FC } from 'react';

import cn from 'classnames';

import {
  BUSINESS_REVIEW_FORM_FIELDS,
  BUSINESS_REVIEW_RADIO_BUTTONS_WITH_NOTES_FIELDS,
  InvestmentProposalBlockNames,
} from 'constants/company-iac';
import { UseCompanyIacOverviewReturnValues } from 'hooks/useCompanyIacBusinessReview';
import { FormContent } from 'shared-components';

import CompanyIacAddCustomFields from '../company-iac-add-custom-fields/CompanyIacAddCustomFields';
import CompanyIacCustomFieldsForm from '../company-iac-custom-fields-form/CompanyIacCustomFieldsForm';
import CompanyIacBlock from '../CompanyIacBlock';
import CompanyIacRadioInputWithNotes from '../CompanyIacRadioInputWithNotes';

type Props = Omit<UseCompanyIacOverviewReturnValues, 'trigger' | 'reset'> & {
  hideAdminActions: boolean;
};

const CompanyIacBusinessReview: FC<Props> = ({
  isLoading,
  isEditing,
  control,
  register,
  errors,
  watch,
  unregister,
  setValue,
  isHideActionButtons,
  handleSaveEditionCustomField,
  handleChangeNotesTextFields,
  handleSaveRadioInputValue,
  handleSaveBusinessReviewFields,
  customFieldsData,
  hideAdminActions,
}) => {
  const { registerCustomFields, onDeleteCustomField, deletedFields } = customFieldsData;

  return (
    <CompanyIacBlock title='Business Review' isHideActionButtons={isHideActionButtons} isLoading={isLoading}>
      <form>
        <FormContent
          isDisabled={isEditing}
          fields={BUSINESS_REVIEW_FORM_FIELDS}
          register={register}
          errors={errors}
          control={control}
          className={cn('mb-5', { 'disabled-light-grey': isEditing })}
          onBlur={handleSaveBusinessReviewFields}
        />

        {BUSINESS_REVIEW_RADIO_BUTTONS_WITH_NOTES_FIELDS.map((field, index) => (
          <CompanyIacRadioInputWithNotes
            setValue={setValue}
            unregister={unregister}
            key={index}
            isDisabled={isEditing}
            register={register}
            errors={errors}
            watch={watch}
            radioInputProps={field.radioInput}
            textAreaProps={field.textAreaInput}
            onChangeRadioInput={handleSaveRadioInputValue}
            onChangeNotes={handleChangeNotesTextFields}
          />
        ))}
      </form>

      <CompanyIacCustomFieldsForm
        errors={customFieldsData.customFieldsErrors}
        disabled={isEditing}
        deletedFields={deletedFields}
        onDeleteCustomField={onDeleteCustomField}
        register={registerCustomFields}
        blockName={InvestmentProposalBlockNames.BUSINESS_REVIEW}
        onBlur={handleSaveEditionCustomField}
      />

      <CompanyIacAddCustomFields
        blockName={InvestmentProposalBlockNames.BUSINESS_REVIEW}
        disabled={isEditing}
        hideAddNewFieldButton={hideAdminActions}
      />
    </CompanyIacBlock>
  );
};

export default CompanyIacBusinessReview;
