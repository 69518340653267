import React, { ReactNode, useEffect } from 'react';

import qs from 'qs';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { LocalStorageKeys } from 'constants/global';
import {
  AUTH_ROUTES,
  COMMON_AUTH_ROUTES,
  FM_USER_ROUTES,
  FOUNDER_ROUTES,
  GLOBAL_ROUTES,
  INVESTOR_ROUTES,
  MAIN_ADMIN_ROUTES,
  MAIN_ROUTES,
  ROUTES,
  SIGN_UP_FLOW_LIST,
} from 'constants/routes';
import { SIGN_UP_FLOWS } from 'constants/sign-up';
import { InviteUserType, UserTypes } from 'constants/user';
import useScrollToTop from 'hooks/useScrollToTop';
import { Route as RouteType } from 'interfaces';
import { getUser, logoutUser } from 'modules/current-user/action';
import { selectIsAuth } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { FullScreenLoader, ProtectedRoute } from 'shared-components';
import { getCompanyProfileLink, getDueDiligenceLink, navigateByRole } from 'utils';

import { selectInvitedUserSignUp } from '../modules/auth/selectors';

const renderRoutes = (routes: RouteType[], baseUrl?: string): ReactNode[] => {
  return routes.map(({ path, element }) => {
    const Component = element;
    return <Route key={path} path={(baseUrl || '') + path} element={<Component />} />;
  });
};

const PagesWrapper = () => {
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isAuth = useAppSelector(selectIsAuth);
  const { inviteType, companyId } = useAppSelector(selectInvitedUserSignUp) || {};

  const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;
  const isAllowAdminPages = userType === UserTypes.ADMIN || userType === UserTypes.FM_USER;

  useScrollToTop();

  useEffect(() => {
    const { flow, company_id: companyId } = qs.parse(search?.replace('?', ''));

    if (pathname?.includes(ROUTES.signUp) && companyId && flow === SIGN_UP_FLOWS.CREATE_SHARE_EXCHANGE && isAuth) {
      dispatch(logoutUser())
        .unwrap()
        .then(() => {
          navigate(`${ROUTES.signUp}${search}`);
        });
      return;
    }
  }, [dispatch, isAuth, navigate, pathname, search]);

  useEffect(() => {
    const isFirstSignIn = localStorage.getItem(LocalStorageKeys.IS_FIRST_SIGN_IN);
    if (isFirstSignIn && companyId && userType === UserTypes.FOUNDER) {
      if (inviteType === InviteUserType.DUE_DILIGENCE) {
        navigate(getDueDiligenceLink(companyId, false));
      } else {
        navigate(getCompanyProfileLink(companyId, false));
      }
      localStorage.removeItem(LocalStorageKeys.IS_FIRST_SIGN_IN);
    }
  }, [companyId, navigate, userType]);

  useEffect(() => {
    if (SIGN_UP_FLOW_LIST.includes(pathname) && !localStorage.getItem(LocalStorageKeys.AUTH_ACCESS_TOKEN)) {
      navigate(ROUTES.signIn);
    }
  }, [navigate, pathname]);

  useEffect(() => {
    if (isAuth === null) dispatch(getUser());
  }, [dispatch, isAuth]);

  if (isAuth === null) return <FullScreenLoader withoutSidebar hideOverlay />;

  return (
    <>
      <Routes>
        <Route
          element={
            <ProtectedRoute
              isAuth={isAuth && userType !== null && !isAllowAdminPages}
              to={navigateByRole(isAllowAdminPages, ROUTES.signIn)}
            />
          }
        >
          {renderRoutes(MAIN_ROUTES)}
          {renderRoutes(
            userType === UserTypes.FOUNDER
              ? FOUNDER_ROUTES
              : !isAuth
              ? [...FOUNDER_ROUTES, ...INVESTOR_ROUTES]
              : INVESTOR_ROUTES,
          )}
        </Route>

        <Route element={<ProtectedRoute isAuth={!isAuth} to={ROUTES.dashboard} />}>
          {renderRoutes(AUTH_ROUTES(userType))}
        </Route>
        <Route
          element={
            <ProtectedRoute
              isAuth={isAuth && isAllowAdminPages}
              to={navigateByRole(isAllowAdminPages || userType === null, ROUTES.signIn)}
            />
          }
        >
          {renderRoutes(userType === UserTypes.FM_USER ? FM_USER_ROUTES : MAIN_ADMIN_ROUTES, ROUTES.admin)}
        </Route>
        <Route element={<ProtectedRoute isAuth={!isAuth} to={`${ROUTES.admin}${ROUTES.dashboard}`} />}>
          {renderRoutes(COMMON_AUTH_ROUTES, ROUTES.admin)}
        </Route>
        {renderRoutes(GLOBAL_ROUTES)}
      </Routes>
    </>
  );
};

export default PagesWrapper;
