import React from 'react';

import { SHARE_EXCHANGE_STEPS } from 'constants/share-exchange-process';
import { ConstraintVariants } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import { TypographyVariants } from 'constants/shared/typography';
import useModal from 'hooks/use-modal/useModal';
import { DashboardLayout } from 'page-components';
import FAQModal from 'page-components/dashboard/FAQModal';
import { Button, Constraint, SnippetLayout, TitleWithDescription } from 'shared-components';
import Typography from 'shared-components/Typography';
import VerticalStepper from 'shared-components/vertical-stepper/VerticalStepper';

const ShareExchangeProcess = () => {
  const { onOpen: handleOpenFAQModal, ...faqModalProps } = useModal();

  return (
    <DashboardLayout>
      <FAQModal {...faqModalProps} />
      <Constraint variant={ConstraintVariants.FULL}>
        <TitleWithDescription title='Share Exchange Process' />
        <SnippetLayout className='mt-10'>
          <Typography tag='h5' variant={TypographyVariants.MEDIUM}>
            PlanD’s share exchange process happens online and consists of the following steps that can be done in as
            little as 24 hours*:
          </Typography>
          <div className='bg-violet-100 p-6 mt-6 rounded-lg'>
            <VerticalStepper steps={SHARE_EXCHANGE_STEPS} />
          </div>

          <Typography className='text-grey-800 my-6'>
            After this, your deal will be closed and you will receive the{' '}
            <b className='font-bold'>Investment Confirmation Letter</b> formalising the deal once the closing date has
            passed.
          </Typography>
          <Typography variant={TypographyVariants.BODY_SMALL}>
            *conditioned on the company&#39;s due diligence having been cleared before
          </Typography>
        </SnippetLayout>
        <SnippetLayout
          title='Explainer Video'
          description='We’ve created a quick and easy video for you to watch that will give you a general understanding on planD and the the platform'
          className='mt-10'
          childrenClassName='mt-0'
        >
          <Button variant={ButtonVariants.SECONDARY} onClick={handleOpenFAQModal}>
            View video
          </Button>
        </SnippetLayout>
      </Constraint>
    </DashboardLayout>
  );
};

export default ShareExchangeProcess;
