import React, { FC } from 'react';

import { ReactComponent as ArrowRightSvg } from 'assets/svg/arrow-right.svg';
import { ButtonVariants } from 'constants/shared/button';
import { Button, LinkButton } from 'shared-components';

interface Props {
  linkPath: string;
  onSubmit?: VoidFunction;
  isLoading?: boolean;
}

const SkipButtonsGroup: FC<Props> = ({ linkPath, onSubmit, isLoading }) => {
  return (
    <div className='mt-10 flex flex-col items-center'>
      {onSubmit ? (
        <Button isLoading={isLoading} type='button' onClick={onSubmit}>
          Next <ArrowRightSvg className='ml-2' />
        </Button>
      ) : (
        <LinkButton className='w-full' variant={ButtonVariants.PRIMARY} to={linkPath}>
          Next <ArrowRightSvg className='ml-2' />
        </LinkButton>
      )}
    </div>
  );
};

export default SkipButtonsGroup;
