import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { investorsApi, permissionsApi, profileApi, usersApi, usersFinishRegistrationApi } from 'apis';
import { filterObjectWithFalsyString, showServerError, successNotify } from 'helpers';
import { CreateUserInput, MetaPayload, SettingsSecurityInput } from 'interfaces';

export const USERS_SLICE_NAME = 'users';

export const getUsers = createAsyncThunk(`${USERS_SLICE_NAME}/getUsers`, async (data: MetaPayload) => {
  const response = await usersApi.getUsers(data);

  return response.data;
});

export const getUser = createAsyncThunk(`${USERS_SLICE_NAME}/getUser`, async (data: string) => {
  const response = await usersApi.getUser(data);

  return response.data;
});

export const deleteUser = createAsyncThunk(`${USERS_SLICE_NAME}/deleteUser`, async (data: string) => {
  await usersApi.deleteUser(data);
});

export const getUserPermissions = createAsyncThunk(`${USERS_SLICE_NAME}/getUserPermissions`, async (data: number) => {
  const response = await permissionsApi.getUserPermissions(data);

  return response.data;
});

export const inviteUser = createAsyncThunk(
  `${USERS_SLICE_NAME}/inviteUser`,
  async (data: CreateUserInput, { rejectWithValue }) => {
    try {
      const { companyNumber, ...rest } = data;

      const response = await investorsApi.inviteUser({ companyNumber: companyNumber.value, ...rest });
      successNotify('The invitation has been sent');
      return response.data?.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const updateUserPermissions = createAsyncThunk(
  `${USERS_SLICE_NAME}/updateUserPermissions`,
  async ({ userId, permissions }: { userId: number; permissions: Record<string, string> }) => {
    const response = await permissionsApi.updateUserPermissions(
      userId,
      Object.keys(filterObjectWithFalsyString(permissions)),
    );

    successNotify('Permissions was successfully updated');
    return response.data.data;
  },
);

export const updateUserPassword = createAsyncThunk(
  `${USERS_SLICE_NAME}/updateUserPassword`,
  async (data: SettingsSecurityInput, { rejectWithValue }) => {
    try {
      const response = await profileApi.updateUserPassword(data);

      successNotify('Profile password was successfully updated');

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const sendFinishRegistrationInvite = createAsyncThunk(
  `${USERS_SLICE_NAME}/sendFinishRegistrationInvite`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await usersFinishRegistrationApi.sendFinishRegistrationInvite(id);
      successNotify('The invitation email has been sent');

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const resetUserData = createAction('RESET_USER_DATA');
