import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { auditLogsReducer } from './audit-logs/slice';
import { authReducer } from './auth/slice';
import { billsReducer } from './bills/slice';
import { companiesIacReducer } from './companies-iac/slice';
import { companiesReducer } from './companies/slice';
import { companyIacReducer } from './company-iac/slice';
import { currentUserReducer } from './current-user/slice';
import { dealsReducer } from './deals/slice';
import { documentsReducer } from './documents/slice';
import { dueDiligenceReducer } from './due-diligence/slice';
import { feesReducer } from './fees/slice';
import { foundersReducer } from './founders/slice';
import { fundsReducer } from './funds/slice';
import { investmentsReducer } from './investments/slice';
import { investorsReducer } from './investors/slice';
import { shareExchangeReducer } from './share-exchange/slice';
import { usersReducer } from './users/slice';

const rootReducer = combineReducers({
  auditLogs: auditLogsReducer,
  authReducer,
  usersReducer,
  investmentsReducer,
  documentsReducer,
  dealsReducer,
  companiesReducer,
  currentUserReducer,
  billsReducer,
  foundersReducer,
  investorsReducer,
  shareExchangeReducer,
  dueDiligenceReducer,
  companyIacReducer,
  feesReducer,
  fundsReducer,
  companiesIacReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
