import React, { FC, ReactNode, useState } from 'react';

import { SIGN_OUT_MODAL_TITLE } from 'constants/dashboard';
import { ButtonVariants } from 'constants/shared/button';
import useModal from 'hooks/use-modal/useModal';
import { logoutUser } from 'modules/current-user/action';
import { selectIsActionLoading } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Header, HeaderPanel, Sidebar } from 'page-components';
import { Button, FullScreenLoader, ModalWindow, Overlay } from 'shared-components';

interface Props {
  children: ReactNode;
}

const DashboardLayout: FC<Props> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const dispatch = useAppDispatch();
  const isActionLoading = useAppSelector(selectIsActionLoading);

  const { onOpen, ...modalState } = useModal();

  const onLogout = () => dispatch(logoutUser());

  const handleOpenSidebar = () => setIsSidebarOpen((prev) => !prev);

  return (
    <main className='flex'>
      <ModalWindow
        title={SIGN_OUT_MODAL_TITLE}
        description='You will have to sign in with your credentials again next time.'
        className='min-w-[70%] 2xl:min-w-[55%]'
        {...modalState}
      >
        <div className='flex [&_button]:w-fit space-x-6 mt-4'>
          <Button variant={ButtonVariants.PRIMARY} disabled={isActionLoading} onClick={modalState.onClose}>
            Cancel
          </Button>
          <Button variant={ButtonVariants.SECONDARY} isLoading={isActionLoading} onClick={onLogout}>
            Yes, Sign Out
          </Button>
        </div>
      </ModalWindow>
      <Sidebar isOpen={isSidebarOpen} handleOpenSignOutModal={onOpen} />
      <div className='w-full min-h-screen bg-grey-100 h-full lg:pl-64'>
        <Header handleOpenSidebar={handleOpenSidebar}>
          <HeaderPanel handleOpenSignOutModal={onOpen} />
        </Header>
        {children}
      </div>
      <Overlay onClick={handleOpenSidebar} isShown={isSidebarOpen} />
      {!modalState.isOpen && isActionLoading && <FullScreenLoader />}
    </main>
  );
};

export default DashboardLayout;
