import React, { FC, useState, useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import {
  COMPANY_IAC_STOP_MODAL_TITLE,
  COMPANY_IAC_STOP_MODAL_CORE_FIELDS,
  COMPANY_IAC_STOP_MODAL_OPTIONAL_FIELDS,
  CompanyIacStopReasons,
} from 'constants/company-iac';
import { ButtonVariants } from 'constants/shared/button';
import { COMPANY_IAC_STATUSES, CompanyIacStopFieldNames, CompanyIacStopFormState } from 'interfaces';
import { stopCompanyIac } from 'modules/company-iac/action';
import { selectCompanyIacId, selectCompanyIacStatus } from 'modules/company-iac/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AlertModal, FormContent } from 'shared-components';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
};

const CompanyIacStopModal: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleOptionalInputs, setIsVisibleOptionalInputs] = useState(false);

  const companyIacId = useAppSelector(selectCompanyIacId);
  const companyIacStatus = useAppSelector(selectCompanyIacStatus);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    reset,
  } = useForm<CompanyIacStopFormState>();

  const watchReasonSelect = watch(CompanyIacStopFieldNames.REASON_SELECT);

  const onCloseForm = () => {
    reset({ [CompanyIacStopFieldNames.REASON_SELECT]: null });
    setIsVisibleOptionalInputs(false);
    onClose();
  };

  const onSubmit: SubmitHandler<CompanyIacStopFormState> = (data) => {
    if (!data.reason || companyIacStatus !== COMPANY_IAC_STATUSES.DECLINED_BY_FMUSERS) {
      return;
    }

    const reason = data.reason.value === CompanyIacStopReasons.OTHER ? data.reasonText : data.reason.label;

    setIsLoading(true);

    dispatch(stopCompanyIac({ reason, id: companyIacId }))
      .unwrap()
      .then(onCloseForm)
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (watchReasonSelect && watchReasonSelect.value === CompanyIacStopReasons.OTHER) {
      setIsVisibleOptionalInputs(true);
      return;
    }

    if (isVisibleOptionalInputs) {
      setIsVisibleOptionalInputs(false);
      return;
    }
  }, [watchReasonSelect, isVisibleOptionalInputs]);

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onCloseForm}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      title={COMPANY_IAC_STOP_MODAL_TITLE}
      submitButtonText='Confirm'
      backButtonText='Cancel'
      modalClassName='lg:w-8/12 xl:w-6/12'
      submitButtonVariant={ButtonVariants.PRIMARY}
      backButtonVariant={ButtonVariants.SECONDARY}
      wrapperModalClassName='lg:py-0 lg:px-0'
      contentClassName='lg:px-28 lg:py-12'
    >
      <form className='w-full mt-8'>
        <FormContent
          fields={COMPANY_IAC_STOP_MODAL_CORE_FIELDS}
          register={register}
          errors={errors}
          control={control}
        />

        {isVisibleOptionalInputs && (
          <FormContent fields={COMPANY_IAC_STOP_MODAL_OPTIONAL_FIELDS} register={register} errors={errors} />
        )}
      </form>
    </AlertModal>
  );
};

export default CompanyIacStopModal;
