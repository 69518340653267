import React from 'react';

import { ReactComponent as SpinLoaderSvg } from 'assets/svg/spin-loader.svg';

const SpinLoader = () => {
  return (
    <div className='h-screen w-screen flex items-center justify-center'>
      <SpinLoaderSvg className='animated-spin w-20 h-20' />
    </div>
  );
};

export default SpinLoader;
