import { createSlice } from '@reduxjs/toolkit';

import { CurrentUserState } from 'interfaces';

import { CURRENT_USER_SLICE_NAME } from './action';
import {
  deletePendingInviteReducer,
  getMyInvitesReducer,
  getUserInfoByHashReducer,
  getUserReducer,
  inviteUserReducer,
  logoutUserReducer,
  setCurrentUserReducer,
  setCurrentUserStateReducer,
  updateCurrentUserReducer,
  updateLinkedInIntegrationReducer,
} from './reducers';

export const initialState: CurrentUserState = {
  isAuth: null,
  data: null,
  isActionLoading: false,
  myInvites: [],
  isLoading: false,
};

const currentUserSlice = createSlice({
  name: CURRENT_USER_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    setCurrentUserReducer(builder);
    getUserReducer(builder);
    logoutUserReducer(builder);
    getUserInfoByHashReducer(builder);
    updateLinkedInIntegrationReducer(builder);
    updateCurrentUserReducer(builder);
    setCurrentUserStateReducer(builder);
    inviteUserReducer(builder);
    getMyInvitesReducer(builder);
    deletePendingInviteReducer(builder);
  },
});

export const currentUserReducer = currentUserSlice.reducer;
