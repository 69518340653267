import { FormItem } from 'interfaces';
import { Input } from 'shared-components';

import { EMAIL_PATTERN } from './global';
import { FieldTypes } from './shared';

export const FORGOT_PASSWORD_PAGE_TITLE = 'Reset your password';

export const FORGOT_PASSWORD_PAGE_ADDITIONAL_TEXT =
  "Enter your email and we'll send you a link to reset your password.";

export enum ForgotPasswordFieldNames {
  EMAIL = 'email',
}

export const FORGOT_PASSWORD_PAGE_FIELDS: FormItem[] = [
  {
    name: ForgotPasswordFieldNames.EMAIL,
    label: 'Email Address',
    placeholder: 'johndoe@mycompany.com',
    type: FieldTypes.TEXT,
    component: Input,
    validation: {
      required: 'Required information missing please input Email address to continue',
      pattern: { message: 'Invalid email address', value: EMAIL_PATTERN },
    },
  },
];
