import React, { FC, useState } from 'react';

import cn from 'classnames';
import { useForm } from 'react-hook-form';

import { ReactComponent as CheckMark } from 'assets/svg/check-mark.svg';
import { ReactComponent as SpinLoader } from 'assets/svg/spin-loader.svg';
import { ReactComponent as TrashCan } from 'assets/svg/trash-can.svg';
import { CUSTOM_FIELDS_FORM_FIELDS, InvestmentProposalBlockNames } from 'constants/company-iac';
import useModal from 'hooks/use-modal/useModal';
import { CompanyIacCustomFieldInput } from 'interfaces';
import { createCustomField } from 'modules/company-iac/action';
import { useAppDispatch } from 'modules/store';
import { FormContent } from 'shared-components';

import DeleteIacFieldModal from '../company-iac-delete-field/DeleteIacFieldModal';

type Props = {
  index: number;
  onDeleteField: (index: number) => void;
  blockName: InvestmentProposalBlockNames;
  disabled?: boolean;
};

const CompanyIacAddCustomFieldsListItem: FC<Props> = ({ index, onDeleteField, blockName, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { onOpen, ...modalState } = useModal();

  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CompanyIacCustomFieldInput>();

  const isDisabled = disabled || isLoading;

  const handeDeleteField = () => {
    onDeleteField(index);
  };

  const deleteField = () => {
    onOpen();
  };

  const onSubmit = (data: CompanyIacCustomFieldInput) => {
    setIsLoading(true);

    dispatch(createCustomField({ ...data, blockName }))
      .unwrap()
      .then(() => {
        onDeleteField(index);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <li>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col sm:flex-row items-end gap-6 px-5 py-3 bg-grey-100'
      >
        <FormContent
          anchorPrefix='custom-fields'
          fields={CUSTOM_FIELDS_FORM_FIELDS}
          register={register}
          errors={errors}
          isDisabled={isDisabled}
        />

        <div className='mt-7 flex items-start self-center'>
          {isLoading ? (
            <SpinLoader className='stroke-grey-300 w-6 h-6 mx-auto' />
          ) : (
            <>
              <button disabled={isDisabled} className='mr-5' type='submit'>
                <CheckMark className={cn('fill-grey-100 w-6 h-6', { 'cursor-not-allowed': disabled })} />
              </button>
              <button
                disabled={isDisabled}
                className={cn('mt-0.5', { 'cursor-not-allowed': disabled })}
                type='button'
                onClick={() => deleteField()}
              >
                <TrashCan className='grey-500-svg-path' />
              </button>
            </>
          )}
        </div>
      </form>
      <DeleteIacFieldModal onSubmit={handeDeleteField} {...modalState} />
    </li>
  );
};

export default CompanyIacAddCustomFieldsListItem;
