import React, { FC } from 'react';

import { GraphItem } from 'interfaces';

export interface Props {
  data: GraphItem[];
  timePeriod: string;
}

const MetricsGrowth: FC<Props> = ({ data, timePeriod }) => {
  const calculateAmountForSelectedPeriod = data.reduce((acc, item) => acc + item.uv, 0);

  return (
    <div className='flex items-center text-sm'>
      <p className={'pl-1 text-lightGreen-700'}>
        + {calculateAmountForSelectedPeriod} last {timePeriod.toLowerCase()}
      </p>
    </div>
  );
};

export default MetricsGrowth;
