import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { InvestmentProposalBlockNames } from 'constants/company-iac';
import {
  CompanyIacCustomField,
  CompanyIacState,
  SetIsCustomFieldsInCreateModePayload,
  CompanyIac,
  CompanyIacDeal,
} from 'interfaces';
import { Fund } from 'interfaces/funds.interfaces';

import {
  createCustomField,
  getFunds,
  removeCompanyIacEditingSections,
  setCompanyIacData,
  setCompanyIacEditingSections,
  setIsCustomFieldsInCreateMode,
  setCompanyIacDeal,
  setCompanyIacHasDealParameters,
  setPartialCompanyIacData,
} from './action';

export function createCustomFieldReducer(builder: ActionReducerMapBuilder<CompanyIacState>) {
  builder.addCase(
    createCustomField.fulfilled,
    (state, action: PayloadAction<CompanyIacCustomField & { blockName: InvestmentProposalBlockNames }>) => {
      const { blockName, ...payload } = action.payload;
      state.customFields[blockName] = [...state.customFields[blockName], { ...payload }];
    },
  );
}

export function setCompanyIacEditingSectionsReducer(builder: ActionReducerMapBuilder<CompanyIacState>) {
  builder.addCase(setCompanyIacEditingSections, (state, action: PayloadAction<InvestmentProposalBlockNames>) => {
    state.editingSections.push(action.payload);
  });
}

export function removeCompanyIacEditingSectionsReducer(builder: ActionReducerMapBuilder<CompanyIacState>) {
  builder.addCase(removeCompanyIacEditingSections, (state, action: PayloadAction<InvestmentProposalBlockNames>) => {
    state.editingSections = state.editingSections.filter((section) => section !== action.payload);
  });
}

export function setIsCustomFieldsInCreateModeReducer(builder: ActionReducerMapBuilder<CompanyIacState>) {
  builder.addCase(
    setIsCustomFieldsInCreateMode,
    (state, action: PayloadAction<SetIsCustomFieldsInCreateModePayload>) => {
      state.customFieldsInCreateMode[action.payload.blockName] = action.payload.isCreateMode;
    },
  );
}

export function setCompanyIacReducer(builder: ActionReducerMapBuilder<CompanyIacState>) {
  builder.addCase(setCompanyIacData, (state, action: PayloadAction<CompanyIac>) => {
    state.companyIac = action.payload;
    state.customFields = {
      ...state.customFields,
      overview: action.payload.overviewCustomInputs || [],
      summary: action.payload.summaryCustomInputs || [],
      businessReview: action.payload.businessReviewCustomInputs || [],
    };
  });
}

export function setPartialIacReducer(builder: ActionReducerMapBuilder<CompanyIacState>) {
  builder.addCase(setPartialCompanyIacData, (state, action: PayloadAction<CompanyIac>) => {
    state.companyIac = { ...state.companyIac, ...action.payload };
    state.customFields = {
      ...state.customFields,
      overview: action.payload.overviewCustomInputs || state.customFields.overview,
      summary: action.payload.summaryCustomInputs || state.customFields.summary,
      businessReview: action.payload.businessReviewCustomInputs || state.customFields.businessReview,
    };
  });
}

export function getFundsReducer(builder: ActionReducerMapBuilder<CompanyIacState>) {
  builder.addCase(getFunds.fulfilled, (state, action: PayloadAction<{ data: Fund[] }>) => {
    state.funds = action.payload.data;
  });
}

export function companyIacSetDealReducer(builder: ActionReducerMapBuilder<CompanyIacState>) {
  builder.addCase(setCompanyIacDeal, (state, action: PayloadAction<CompanyIacDeal>) => {
    state.deal = action.payload;
  });
}

export function companyIacSetCompanyIacHasDealParameters(builder: ActionReducerMapBuilder<CompanyIacState>) {
  builder.addCase(setCompanyIacHasDealParameters, (state, action: PayloadAction<boolean>) => {
    state.companyIac.hasDealParameter = action.payload;
  });
}
