import React, { FC, useMemo } from 'react';

import { Tooltip } from 'react-tooltip';

import { ReactComponent as CheckMarkIcon } from 'assets/svg/check-mark.svg';
import { ReactComponent as EmptyCircleIcon } from 'assets/svg/empty-circle.svg';
import { DEAL_DETAILS_INCORRECT_URL_PATTERN, SHARE_EXCHANGE_STAGES } from 'constants/deals';
import { PLAND_SUPPORT_EMAIL } from 'constants/global';
import { ButtonVariants } from 'constants/shared/button';
import useModal from 'hooks/use-modal/useModal';
import { StepProgressItem } from 'interfaces';
import { LinkButton } from 'shared-components';
import Typography from 'shared-components/Typography';
import { checkIsCompletedStepAndReadyToCompleteStage } from 'utils/deals';

import DealDetailsReadyToCompleteTooltipContent from './DealDetailsReadyToCompleteTooltipContent';

const PLAINLY_LINK_ID = 'plainly-link';

interface Props extends Pick<StepProgressItem, 'text' | 'documentKey'> {
  isCurrentStage: boolean;
  isPassedTask: boolean;
  currentStage: SHARE_EXCHANGE_STAGES;
  stageKey: string;
  stoppedDeal: boolean;
  documentUrl?: string | undefined;
  passedDocumentUrl?: string;
  isPendingCurrentStage?: boolean;
}

const DealsDetailsOutstandingTasksItem: FC<Props> = ({
  stoppedDeal,
  currentStage,
  stageKey,
  text,
  isCurrentStage,
  documentUrl,
  isPassedTask,
  passedDocumentUrl,
  documentKey,
  isPendingCurrentStage,
}) => {
  const { isOpen, onOpen, onClose } = useModal();

  const tooltipAnchorId = `${PLAINLY_LINK_ID}-${documentKey}`;

  const isCompletedStepAndReadyToCompleteStage = checkIsCompletedStepAndReadyToCompleteStage(stageKey, currentStage);

  const isPendingApprovalStage =
    stageKey === SHARE_EXCHANGE_STAGES.APPLICATION_FORM && currentStage === SHARE_EXCHANGE_STAGES.APPROVAL;

  const isIncorrectCurrentLink = !documentUrl || documentUrl?.endsWith(DEAL_DETAILS_INCORRECT_URL_PATTERN);

  const buttonLinkText = useMemo(() => {
    if (isCurrentStage && isCompletedStepAndReadyToCompleteStage) return 'Pending Closure';

    if (isPassedTask) return 'View Document';

    if (isPendingApprovalStage || (isPendingCurrentStage && isCurrentStage)) {
      return 'Pending Approval';
    }

    if (isCurrentStage && stageKey === SHARE_EXCHANGE_STAGES.INVOICE) return 'Pay Now';

    if (isCurrentStage) return 'Start';

    return '';
  }, [
    isCurrentStage,
    isCompletedStepAndReadyToCompleteStage,
    isPassedTask,
    isPendingApprovalStage,
    isPendingCurrentStage,
    stageKey,
  ]);
  // This tooltip is shown only for Introducer Agreement stage and only in case
  // If response from Plainly hasn't been received yet
  // Or when deal has been stopped

  const shownTooltip = useMemo(() => {
    if (
      isIncorrectCurrentLink &&
      isCurrentStage &&
      (currentStage === SHARE_EXCHANGE_STAGES.INTRODUCER_AGREEMENT || isCompletedStepAndReadyToCompleteStage)
    ) {
      return true;
    }

    return stoppedDeal && isCurrentStage;
  }, [isIncorrectCurrentLink, isCurrentStage, currentStage, isCompletedStepAndReadyToCompleteStage, stoppedDeal]);

  const tooltipMessage = useMemo(() => {
    if (stoppedDeal && isCurrentStage) {
      return `Share exchange is stopped. For details - please contact the planD team here ${PLAND_SUPPORT_EMAIL}`;
    }
    if (isIncorrectCurrentLink && isCurrentStage && currentStage === SHARE_EXCHANGE_STAGES.INTRODUCER_AGREEMENT) {
      return 'Connection may take a few minutes. Try again if longer than expected';
    }

    if (isCurrentStage && isCompletedStepAndReadyToCompleteStage) return <DealDetailsReadyToCompleteTooltipContent />;

    return '';
  }, [stoppedDeal, isCurrentStage, isIncorrectCurrentLink, currentStage, isCompletedStepAndReadyToCompleteStage]);

  if (!text) return null;

  return (
    <>
      {shownTooltip && (
        <Tooltip
          anchorId={tooltipAnchorId}
          isOpen={isOpen}
          positionStrategy='fixed'
          className='text-sm text-grey-500 z-10 !mt-0'
          offset={0}
          clickable
          noArrow
        >
          {tooltipMessage}
        </Tooltip>
      )}

      <div className='flex sm:items-center justify-between flex-col sm:flex-row gap-4 sm:gap-0 py-6 relative border-b border-grey-200'>
        <div className='flex items-center'>
          <div>{isPassedTask ? <CheckMarkIcon className='[&_path]:fill-violet-600' /> : <EmptyCircleIcon />}</div>

          <div className='ml-6'>
            <Typography className='font-bold text-grey-800' tag='h5'>
              {text}
            </Typography>
          </div>
        </div>
        {(documentKey && (isCurrentStage || (!isPassedTask && isPendingApprovalStage))) ||
        (isPassedTask && passedDocumentUrl) ? (
          <div id={tooltipAnchorId} onMouseEnter={onOpen} onMouseLeave={onClose}>
            <LinkButton
              className='w-full xs:w-44 xs:ml-2 cursor-pointer'
              disabled={
                (!isCurrentStage && !isPassedTask) ||
                stoppedDeal ||
                isCompletedStepAndReadyToCompleteStage ||
                (isCurrentStage && (isPendingCurrentStage || isIncorrectCurrentLink))
              }
              variant={ButtonVariants.SECONDARY}
              to={isCurrentStage ? documentUrl || '' : passedDocumentUrl || ''}
              target={!isCurrentStage ? '_blank' : undefined}
              rel='noreferrer'
            >
              {buttonLinkText}
            </LinkButton>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DealsDetailsOutstandingTasksItem;
