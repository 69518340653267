import React, { FC } from 'react';

import { PROFILE_TAB_DESCRIPTION, PROFILE_TAB_TITLE } from 'constants/settings';
import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components';

interface Props {
  onCancel: VoidFunction;
}

const ProfileSettingsHeader: FC<Props> = ({ onCancel }) => (
  <div className='flex justify-between flex-col md:flex-row gap-4 md:gap-0'>
    <div>
      <h3 className='font-bold text-xl'>{PROFILE_TAB_TITLE}</h3>
      <p className='text-grey-500'>{PROFILE_TAB_DESCRIPTION}</p>
    </div>
    <div className='flex items-center'>
      <Button className='w-full md:w-150 mr-4' variant={ButtonVariants.SECONDARY} type='button' onClick={onCancel}>
        Cancel
      </Button>
      <Button type='submit' className='w-full md:w-150' variant={ButtonVariants.PRIMARY}>
        Save
      </Button>
    </div>
  </div>
);

export default ProfileSettingsHeader;
