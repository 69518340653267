import React, { FC, useCallback, useMemo, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { ReactComponent as ArrowDropDownIcon } from 'assets/svg/arrow-drop-down.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/svg/check-mark.svg';
import { ReactComponent as TrashCanIcon } from 'assets/svg/trash-can.svg';
import {
  COMPANY_INVESTORS_ADD_FORM_FIELDS,
  COMPANY_INVESTORS_DOWNLOAD_TEMPLATE_PATH,
  COMPANY_INVESTORS_TABLE_COLUMNS,
} from 'constants/company-investors';
import useModal from 'hooks/use-modal/useModal';
import {
  AddNewCompanyInvestorFormValues,
  ButtonAction,
  CompanyInvestor,
  InvitePreviewModalParams,
  NumberOrNull,
  ValidatedCompanyInvestor,
} from 'interfaces';
import {
  createCompanyInvestorForInvite,
  deleteInvitedCompanyInvestor,
  uploadCompanyInvestors,
  validateCompanyInvestorsForInvite,
} from 'modules/companies/action';
import { useAppDispatch } from 'modules/store';
import { AlertModal, ButtonsActions, Checkbox, FormContent } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';
import { getCompanyInvestorsInviteShareExchangeModalDescription, getLinkWithOriginPrefix } from 'utils';

import { CheckboxVariants } from '../../constants/shared';
import CompanyInvestorsFooter from './CompanyInvestorsFooter';
import CompanyInvestorsTableItem from './CompanyInvestorsTableItem';
import NoCompanyInvestorsAdded from './NoCompanyInvestorsAdded';
import UploadCompanyInvestorsModal from './upload-company-investors-modal/UploadCompanyInvestorsModal';

export const ADD_NEW_COMPANY_INVESTOR_INITIAL_VALUES = {
  fullName: '',
  email: '',
};

interface Props {
  companyId?: string;
  companyName?: string;
  investors: CompanyInvestor[];
  isLoading?: boolean;
  handleSetIsLoading: (value: boolean) => void;
  handleOpenInvitePreviewModal: ({
    companyId,
    selectedInvestors,
    handleResetSelectedInvestors,
  }: InvitePreviewModalParams) => void;
}

const CompanyInvestorsContent: FC<Props> = ({
  investors = [],
  isLoading = false,
  companyId,
  companyName,
  handleSetIsLoading,
  handleOpenInvitePreviewModal,
}) => {
  const dispatch = useAppDispatch();

  const [selectedInvestors, setSelectedInvestors] = useState<CompanyInvestor[]>([]);

  const [isFormLoading, setIsFormLoading] = useState(false);
  const [disabledInvestorId, setDisabledInvestorId] = useState<NumberOrNull>(null);

  const [investorsWithShareExchanges, setInvestorsWithShareExchanges] = useState<
    (CompanyInvestor & ValidatedCompanyInvestor)[]
  >([]);

  const { onOpen: handleOpenSendInvitesModal, ...sendInvitesModalProps } = useModal();

  const { onOpen: handleOpenUploadInvestorsModal, ...uploadInvestorsModalProps } = useModal();

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm<AddNewCompanyInvestorFormValues>();

  const handleCreateInvestor: SubmitHandler<AddNewCompanyInvestorFormValues> = (values) => {
    if (!companyId) return;

    setIsFormLoading(true);

    dispatch(createCompanyInvestorForInvite({ id: companyId, investor: values }))
      .unwrap()
      .finally(() => {
        setIsFormLoading(false);
        reset(ADD_NEW_COMPANY_INVESTOR_INITIAL_VALUES);
      });
  };

  const handleAllSelectedInvestors = useCallback(() => {
    if (!investors?.length || !setSelectedInvestors) return;

    const newSelectedInvestors = selectedInvestors?.length < investors?.length ? investors : [];

    setSelectedInvestors(newSelectedInvestors);
  }, [investors, selectedInvestors?.length]);

  const handleSelectInvestor = useCallback((currentInvestor: CompanyInvestor) => {
    setSelectedInvestors((investors) => {
      const isInvestorChecked = investors?.some((investor) => investor.id === currentInvestor.id);

      return isInvestorChecked
        ? investors.filter((user) => user.id !== currentInvestor.id)
        : [...investors, currentInvestor];
    });
  }, []);

  const handleDeleteInvestor = (investorId: number) => {
    if (!companyId) return;

    setDisabledInvestorId(investorId);
    dispatch(deleteInvitedCompanyInvestor({ id: companyId, investorId }))
      .unwrap()
      .finally(() => {
        setDisabledInvestorId(null);
        setSelectedInvestors((selectedInvestors) => selectedInvestors.filter((investor) => investor.id !== investorId));
      });
  };

  const handleResetSelectedInvestors = () => setSelectedInvestors([]);

  const handleValidateInvestors = () => {
    if (!companyId || !companyName || !selectedInvestors?.length) return;
    handleSetIsLoading(true);

    dispatch(validateCompanyInvestorsForInvite({ id: companyId, companyName, investors: selectedInvestors }))
      .unwrap()
      .then(({ investors, isNoLiveDealParameterExist }) => {
        if (investors?.length) {
          setInvestorsWithShareExchanges(investors);
          handleOpenSendInvitesModal();
          return;
        }

        if (!investors?.length && !isNoLiveDealParameterExist) {
          handleOpenInvitePreviewModal({ companyId, selectedInvestors, handleResetSelectedInvestors });
        }
      })
      .finally(() => handleSetIsLoading(false));
  };

  const handleContinueInvestorsInvite = () => {
    if (!companyId) return;

    sendInvitesModalProps.onClose();
    handleOpenInvitePreviewModal({ companyId, selectedInvestors, handleResetSelectedInvestors });
  };

  const onUploadCompanyInvestors = async ([investorsFile]: File[]) => {
    handleSetIsLoading(true);

    if (!companyId) return;

    dispatch(uploadCompanyInvestors({ id: companyId, file: investorsFile }))
      .unwrap()
      .finally(() => handleSetIsLoading(false));
  };

  const handleDeleteAddInvestorForm = () => {
    const isFieldsEmpty = Object.values(getValues()).every((value) => !value);

    if (!isFieldsEmpty) {
      reset(ADD_NEW_COMPANY_INVESTOR_INITIAL_VALUES);
      return;
    }
  };

  const checkboxTableColumn = useMemo(
    () => ({
      id: 'checkbox',
      title: (
        <div className='flex items-center'>
          <Checkbox
            variant={CheckboxVariants.ALL}
            onChange={handleAllSelectedInvestors}
            checked={Boolean(selectedInvestors?.length && selectedInvestors?.length === investors?.length)}
          />
          <ArrowDropDownIcon className='ml-2 [&_path]:fill-blue-600' />
        </div>
      ),
      className: 'w-32 pl-5',
    }),
    [handleAllSelectedInvestors, investors?.length, selectedInvestors?.length],
  );

  const tableColumns = useMemo(() => [checkboxTableColumn, ...COMPANY_INVESTORS_TABLE_COLUMNS], [checkboxTableColumn]);

  const addInvestorFormActions: ButtonAction[] = [
    {
      id: 'save',
      icon: CheckMarkIcon,
      action: handleSubmit(handleCreateInvestor),
    },
    { id: 'delete', icon: TrashCanIcon, action: handleDeleteAddInvestorForm },
  ];
  return (
    <>
      <AlertModal
        {...sendInvitesModalProps}
        onSubmit={handleContinueInvestorsInvite}
        isLoading={isLoading}
        submitButtonText='Continue'
        title={getCompanyInvestorsInviteShareExchangeModalDescription({
          companyName,
          investors: investorsWithShareExchanges,
        })}
        description='Do you want to continue?'
        modalClassName='lg:w-8/12 xl:w-6/12'
        descriptionClassName='mt-6'
      />
      <UploadCompanyInvestorsModal
        onSubmitUploadInvestors={onUploadCompanyInvestors}
        investorsTemplateDownloadPath={getLinkWithOriginPrefix(COMPANY_INVESTORS_DOWNLOAD_TEMPLATE_PATH)}
        {...uploadInvestorsModalProps}
      />
      <div>
        <div className='flex flex-col xs:flex-row gap-4 mb-4 xs:mb-0'>
          <FormContent
            fields={COMPANY_INVESTORS_ADD_FORM_FIELDS}
            register={register}
            control={control}
            errors={errors}
            isDisabled={isFormLoading}
          />
          <ButtonsActions
            className='self-end xs:self-start'
            anchorPrefix='add-new-investor'
            actions={addInvestorFormActions}
            disabled={isFormLoading}
          />
        </div>

        <Table columns={tableColumns} className='[&_th]:first:pl-1'>
          {investors?.length && !isLoading ? (
            investors.map((investor) => (
              <CompanyInvestorsTableItem
                key={investor.id}
                handleSelectInvestor={handleSelectInvestor}
                handleDeleteInvestor={handleDeleteInvestor}
                checked={selectedInvestors.some((selectedInvestor) => investor.id === selectedInvestor.id)}
                disabled={investor.id === disabledInvestorId}
                {...investor}
              />
            ))
          ) : isLoading ? (
            <TableSkeleton columnsNumber={6} rowsNumber={3} />
          ) : (
            <NoCompanyInvestorsAdded />
          )}
        </Table>
      </div>
      <CompanyInvestorsFooter
        handleConfirmButton={handleValidateInvestors}
        handleClickBatchUpload={handleOpenUploadInvestorsModal}
        selectedInvestorsLength={selectedInvestors?.length}
        isLoading={isLoading}
      />
    </>
  );
};

export default CompanyInvestorsContent;
