import React, { FC } from 'react';

import { ReactComponent as SpinLoaderSvg } from 'assets/svg/spin-loader.svg';
import { PAY_BILL_MODAL_TITLE, payBillModalCommonStyles } from 'constants/my-bills';
import { TypographyVariants } from 'constants/shared/typography';
import { ModalWindow } from 'shared-components';
import Typography from 'shared-components/Typography';

import PayBillModalsHeader from './PayBillModalsHeader';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
};

const PayBillProcessingModal: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <ModalWindow {...payBillModalCommonStyles} isOpen={isOpen} onClose={onClose}>
      <PayBillModalsHeader title={PAY_BILL_MODAL_TITLE} />

      <div className='flex flex-col items-center mt-12'>
        <SpinLoaderSvg className='animated-spin w-12 h-[48px] [&_.secondary-circle-line]:stroke-lightGreen-700 [&_.primary-circle-line]:fill-lightGreen-400' />

        <Typography className='my-6 max-w-[630px] text-center' tag='h3' variant={TypographyVariants.SEMI_LARGE}>
          You will be redirected to Xero to complete your payment securely.
        </Typography>
        <Typography className='text-grey-500 mb-10'>
          You will be redirected to Xero to complete your payment securely.
        </Typography>
      </div>
    </ModalWindow>
  );
};

export default PayBillProcessingModal;
