import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  adminFundsApis,
  adminIacApis,
  companiesIacApis,
  currentUserApi,
  fmUserIacApis,
  adminCompaniesApis,
} from 'apis';
import { InvestmentProposalBlockNames } from 'constants/company-iac';
import { showServerError, successNotify, toCamelCase } from 'helpers';
import {
  CompanyIacCustomFieldInput,
  SetIsCustomFieldsInCreateModePayload,
  UpdateCompanyIacPayload,
  CreateCompanyIacPayload,
  Option,
  CompanyIacInvitePayload,
  CompanyIac,
  CompanyIacDeclinePayload,
  CompanyIacStopPayload,
  CompanyIacDeal,
  CreateDealParametersPayload,
  GetCompanyIacPayload,
} from 'interfaces';
import { GetFmUsersPayload } from 'interfaces/funds.interfaces';
import { getCompanyIacRequestByConditions } from 'utils/companies-iac';

export const COMPANY_IAC_SLICE_NAME = 'company-iac';

export const getCompanyIac = createAsyncThunk(
  `${COMPANY_IAC_SLICE_NAME}/getCompanyIacOverview`,
  async ({ companyId, iacId, isAdmin }: GetCompanyIacPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await getCompanyIacRequestByConditions(companyId, iacId, isAdmin);

      dispatch(setCompanyIacData(response.data.data));
      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const updateCompanyIac = createAsyncThunk(
  `${COMPANY_IAC_SLICE_NAME}/updateCompanyIac`,
  async (payload: UpdateCompanyIacPayload, { dispatch, rejectWithValue }) => {
    try {
      const { shouldUpdateStore, ...restPayload } = payload;
      const response = await companiesIacApis.updateCompanyIac(restPayload);

      // TODO: Delete this parameter for overview when autosave will be implemented
      // We dont update store in case of autosaves, but we need to update it for Overview section
      if (shouldUpdateStore) {
        dispatch(setPartialCompanyIacData(response.data.data));
      }

      successNotify('Company IAC was successfully updated', {
        toastId: 'iac-field-updated',
      });
      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const createCustomField = createAsyncThunk(
  `${COMPANY_IAC_SLICE_NAME}/createCustomField`,
  async ({ blockName, ...payload }: CompanyIacCustomFieldInput, { rejectWithValue }) => {
    try {
      const name = toCamelCase(payload.label || '');

      return { ...payload, blockName, id: new Date().getTime(), name };
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getFundManagers = createAsyncThunk(
  `${COMPANY_IAC_SLICE_NAME}/getFundManagers`,
  async (_, { rejectWithValue }) => {
    try {
      const { data: fundManagers } = await adminFundsApis.getFundManagers();
      const fundManagersOptions = fundManagers?.data.map(
        (fundManager) => ({ label: fundManager.name, value: fundManager.id.toString() } as Option),
      );

      return { options: fundManagersOptions, data: fundManagers.data };
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getFmUsers = createAsyncThunk(
  `${COMPANY_IAC_SLICE_NAME}/getFmUsers`,
  async (payload: GetFmUsersPayload, { rejectWithValue }) => {
    try {
      const response = await adminFundsApis.getFundManagerUsersForInvite(payload);

      return response.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const companyIacInviteExistingFmUsers = createAsyncThunk(
  `${COMPANY_IAC_SLICE_NAME}/companyIacInviteExistingFmUsers`,
  async ({ id, fmUsers, fundManagerId, companyId }: CompanyIacInvitePayload, { rejectWithValue, dispatch }) => {
    try {
      await Promise.all(
        fmUsers.map((fmUser) =>
          fmUser?.isRegistrationFinished
            ? adminFundsApis.companyIacInviteExistingFmUser({ id, fmUserId: fmUser.id })
            : currentUserApi.inviteUser({
                ...fmUser,
                fundManagerId: Number(fundManagerId),
                companyId: Number(companyId),
                inviteType: 'investment_proposal',
              }),
        ),
      );

      const updatedIacResponse = await adminIacApis.setFundManager({ id, fundManagerId });

      dispatch(setCompanyIacData(updatedIacResponse.data.data));

      successNotify('Users were successfully invited');

      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getFunds = createAsyncThunk(`${COMPANY_IAC_SLICE_NAME}/getFunds`, async (_, { rejectWithValue }) => {
  try {
    const { data: funds } = await adminFundsApis.getFunds({ page: 1, per_page: 100 });

    const fundsOptions = funds.data.map((fund) => ({ label: fund.name, value: fund.id.toString() } as Option));

    return { options: fundsOptions, data: funds.data };
  } catch (err) {
    showServerError(err);
    return rejectWithValue(err);
  }
});

export const createCompanyIac = createAsyncThunk(
  `${COMPANY_IAC_SLICE_NAME}/createCompanyIac`,
  async (payload: CreateCompanyIacPayload, { rejectWithValue }) => {
    try {
      await companiesIacApis.createCompanyIac(payload);

      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const declineCompanyIac = createAsyncThunk(
  `${COMPANY_IAC_SLICE_NAME}/declineCompanyIac`,
  async (payload: CompanyIacDeclinePayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await fmUserIacApis.declineCompanyIac(payload);

      dispatch(setCompanyIacData(response.data.data));
      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const confirmCompanyIac = createAsyncThunk(
  `${COMPANY_IAC_SLICE_NAME}/confirmCompanyIac`,
  async (
    {
      iacId,
      isAdmin,
    }: {
      iacId: number;
      isAdmin: boolean;
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await (isAdmin ? adminIacApis.confirmCompanyIac(iacId) : fmUserIacApis.confirmCompanyIac(iacId));

      dispatch(setCompanyIacData(response.data.data));
      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const stopCompanyIac = createAsyncThunk(
  `${COMPANY_IAC_SLICE_NAME}/stopCompanyIac`,
  async (payload: CompanyIacStopPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminIacApis.stopCompanyIac(payload);

      dispatch(setCompanyIacData(response.data.data));
      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getCompanyIacDeal = createAsyncThunk(
  `${COMPANY_IAC_SLICE_NAME}/getCompanyIacDeal`,
  async (companyId: string, { rejectWithValue, dispatch }) => {
    dispatch(setCompanyIacDeal({} as CompanyIacDeal));

    try {
      const response = await adminCompaniesApis.getDealParameters(companyId);

      dispatch(setCompanyIacDeal(response.data.data));

      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const createCompanyIacDealParameters = createAsyncThunk(
  `${COMPANY_IAC_SLICE_NAME}/createCompanyIacDealParameters`,
  async (payload: CreateDealParametersPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await adminCompaniesApis.createDealParameters(payload);

      dispatch(setCompanyIacDeal(response.data.data));

      dispatch(setCompanyIacHasDealParameters(true));

      successNotify('Deal parameters were successfully created');

      return null;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const setCompanyIacEditingSections = createAction<InvestmentProposalBlockNames>(
  'SET_EDITING_COMPANY_IAC_SECTION',
);

export const removeCompanyIacEditingSections = createAction<InvestmentProposalBlockNames>(
  'REMOVE_EDITING_COMPANY_IAC_SECTION',
);

export const setIsCustomFieldsInCreateMode = createAction<SetIsCustomFieldsInCreateModePayload>(
  'SET_IS_CUSTOM_FIELDS_IN_CREATE_MODE',
);

export const setCompanyIacHasDealParameters = createAction<boolean>('SET_COMPANY_IAC_HAS_DEAL_PARAMETERS');

export const setCompanyIacDeal = createAction<CompanyIacDeal>('SET_COMPANY_IAC_DEAL');

export const setCompanyIacData = createAction<CompanyIac>('SET_COMPANY_IAC_DATA');

export const setPartialCompanyIacData = createAction<CompanyIac>('SET_PARTIAL_COMPANY_IAC_DATA');
