import React from 'react';

import { SubmitHandler } from 'react-hook-form';

import { COMPANY_CONFIRM_PAGE_DESCRIPTION, COMPANY_CONFIRM_PAGE_TITLE } from 'constants/company-confirm';
import { ConstraintVariants } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import { CompanyDetailsInput } from 'interfaces';
import { addCompany } from 'modules/auth/action';
import { selectIsActionLoading } from 'modules/auth/selectors';
import { resetCompanyData } from 'modules/companies/action';
import { selectCompanyData } from 'modules/companies/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AuthHeader, AuthLayout, HeaderInfo } from 'page-components';
import { CompanyDataForm, Constraint } from 'shared-components';
import Typography from 'shared-components/Typography';

const CompanyConfirm = () => {
  const dispatch = useAppDispatch();

  const companyData = useAppSelector(selectCompanyData);
  const isActionLoading = useAppSelector(selectIsActionLoading);

  const handleResetCompanyData = () => dispatch(resetCompanyData());

  const onSubmit: SubmitHandler<CompanyDetailsInput> = (data) => {
    dispatch(addCompany({ data })).unwrap().finally(handleResetCompanyData);
  };

  return (
    <AuthLayout customHeader={<AuthHeader hideButtons />}>
      <HeaderInfo className='[&_h2]:pt-0' title={COMPANY_CONFIRM_PAGE_TITLE}>
        <Typography>{COMPANY_CONFIRM_PAGE_DESCRIPTION}</Typography>
      </HeaderInfo>
      <CompanyDataForm
        buttonsClassName='flex flex-col [&_button]:w-full [&_button]:max-w-full [&_button]:ml-0 xs:mt-10 space-y-6'
        companyData={companyData}
        onSubmit={onSubmit}
        isActionLoading={isActionLoading}
        handleResetCompanyData={handleResetCompanyData}
        skipButtonVariant={ButtonVariants.LINK}
        isResetAfterSubmit
      />
    </AuthLayout>
  );
};

export default CompanyConfirm;
