import add from 'date-fns/add';

import { LocalStorageKeys } from 'constants/global';
export const setUserAccess = (access_token: string) => {
  localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, access_token);
  localStorage.setItem(LocalStorageKeys.ACCESS_EXPIRED_TIME, add(new Date(), { days: 7 }).toString());

  localStorage.removeItem(LocalStorageKeys.AUTH_ACCESS_TOKEN);
};

export const resetUserStorage = () => {
  localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
  localStorage.removeItem(LocalStorageKeys.ACCESS_EXPIRED_TIME);
  localStorage.removeItem(LocalStorageKeys.USER_TYPE);
  localStorage.removeItem(LocalStorageKeys.HIDE_INVESTOR_WELCOME_MODAL);
};

export const getAccessToken = () => localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
