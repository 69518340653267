import React, { useCallback, useEffect, useState } from 'react';

import { AUDIT_LOG_TABLE_COLUMNS } from 'constants/audit-log';
import useFilterParameters from 'hooks/use-filter-parameters/useFilterParameters';
import useModal from 'hooks/use-modal/useModal';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { getAdminActivityLogs } from 'modules/audit-logs/action';
import { selectAuditLogs } from 'modules/audit-logs/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Pagination, SearchForm } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import AuditLogsModal from '../audit-logs-modal/AuditLogsModal';
import AuditLogsTableItem from './AuditLogsTableItem';
import NoAuditLogsAdded from './NoAuditLogsAdded';

const AUDIT_LOG_PER_PAGE = 8;

const AuditLogsTable = () => {
  const dispatch = useAppDispatch();

  const { data: auditLogs, isLoading, totalPages, total } = useAppSelector(selectAuditLogs);

  const { page, query, setPage, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();
  const { filter } = useFilterParameters();

  const { onOpen, ...modalState } = useModal();

  const [currentAuditLogsId, setCurrentAuditLogsId] = useState<number | null>(null);

  const handleOpenAuditInfoModal = (id: number) => {
    setCurrentAuditLogsId(id);
    onOpen();
  };

  const loadAuditLogs = useCallback(() => {
    dispatch(
      getAdminActivityLogs({
        page,
        per_page: AUDIT_LOG_PER_PAGE,
        query,
        order: sorting.order,
        sort: sorting.column,
        filter,
      }),
    );
  }, [dispatch, filter, page, query, sorting.column, sorting.order]);

  useEffect(() => {
    loadAuditLogs();
  }, [loadAuditLogs]);

  return (
    <div className='flex flex-col'>
      <SearchForm
        className='w-7/12 mb-12 items-center'
        placeholder='Search by Entity Id, Entity'
        handleFilter={handleFilter}
      />

      <Table onUpdateSorting={updateSorting} columns={AUDIT_LOG_TABLE_COLUMNS}>
        {!isLoading && auditLogs?.length ? (
          auditLogs.map((auditLog) => (
            <AuditLogsTableItem auditLog={auditLog} handleOpenModal={handleOpenAuditInfoModal} key={auditLog.id} />
          ))
        ) : isLoading ? (
          <TableSkeleton rowsNumber={3} columnsNumber={AUDIT_LOG_TABLE_COLUMNS.length} />
        ) : (
          <NoAuditLogsAdded />
        )}
      </Table>

      <Pagination loading={isLoading} currentPage={page} totalPages={totalPages} total={total} updatePage={setPage} />
      <AuditLogsModal {...modalState} id={currentAuditLogsId} />
    </div>
  );
};

export default AuditLogsTable;
