import React, { FC, useMemo } from 'react';

import { SHARE_EXCHANGE_STAGES_LABELS } from 'constants/deals';
import { ButtonVariants } from 'constants/shared/button';
import { UseModalReturnValues } from 'hooks/use-modal/useModal';
import { Button, ModalWindow } from 'shared-components';
import Typography from 'shared-components/Typography';

interface Props extends Omit<UseModalReturnValues, 'onOpen' | 'modalProps'> {
  currentDocument?: string;
  isQueryDocument?: string;
}

const PlainlyDealDetailsModal: FC<Props> = ({ currentDocument, isOpen, onClose, isQueryDocument }) => {
  const modalDescription = useMemo(() => {
    if (currentDocument === SHARE_EXCHANGE_STAGES_LABELS.INTRODUCER_AGREEMENT && !isQueryDocument) {
      return (
        <Typography>
          Please wait while we process your data and generate the first document you should sign to start your share
          exchange. This can take up to 60 seconds.
        </Typography>
      );
    }
    switch (currentDocument) {
      case SHARE_EXCHANGE_STAGES_LABELS.APPROPRIATENESS_KYC:
        return (
          <Typography>
            Thank you for completing the Investor Appropriateness Questionnaire.
            <br />
            Per FCA regulation, there is now a 24hr cooling off period, during which time you can leave the platform.
            You will be notified via email with the relevant next steps, once the 24hrs has elapsed.
          </Typography>
        );
      case SHARE_EXCHANGE_STAGES_LABELS.APPLICATION_FORM:
        return (
          <Typography>
            Thank you for signing the document {currentDocument}!<br />
            Your document is currently under review. We will send you an email notification when the next steps are
            available.
          </Typography>
        );
      case SHARE_EXCHANGE_STAGES_LABELS.STOCK_TRANSFER_FORM:
        return (
          <Typography>
            Thank you for signing the stock transfer form.
            <br />
            All of your documents are now complete, and there is nothing further for you to action. Once the deal has
            closed, you will receive your investment confirmation letter which summarises your Daxia shareholding.
          </Typography>
        );

      default:
        return (
          <Typography>
            Please wait whilst we process your documents.
            <br />
            Please note this could take up to 60 seconds - please do not refresh or leave the page
          </Typography>
        );
    }
  }, [currentDocument]);

  return (
    <ModalWindow
      className='lg:w-6/12 xl:w-5/12'
      wrapperClassName='lg:!px-8 xl:px-28'
      onClose={onClose}
      isOpen={isOpen}
      preventOutsideClick
    >
      <div className='text-grey-500'>{modalDescription}</div>
      <Button className='mt-10' variant={ButtonVariants.SECONDARY} onClick={onClose}>
        Next
      </Button>
    </ModalWindow>
  );
};

export default PlainlyDealDetailsModal;
