import React from 'react';

import { VerticalStepperItem as VerticalStepperItemType } from 'interfaces';

import VerticalStepperItem from './VerticalStepperItem';

interface Props {
  steps: VerticalStepperItemType[];
}

const VerticalStepper = ({ steps }: Props) => {
  if (!steps?.length) return null;

  return (
    <ol>
      {steps?.map((step) => (
        <VerticalStepperItem key={step.step} {...step} />
      ))}
    </ol>
  );
};

export default VerticalStepper;
