import React, { FC } from 'react';

import cn from 'classnames';

import { ReactComponent as ConfirmSvg } from 'assets/svg/check-mark.svg';
import { ReactComponent as CloseSvg } from 'assets/svg/close.svg';
import { FUNDS_ITEM_FORM_FIELDS } from 'constants/funds';
import useFundForm from 'hooks/useFundForm';
import { ButtonAction } from 'interfaces';
import { Fund, FundFormInput } from 'interfaces/funds.interfaces';
import { editFundById } from 'modules/funds/action';
import { useAppDispatch } from 'modules/store';
import { ButtonsActions, FormContent, ListItemWrapper } from 'shared-components';

interface Props {
  actions: ButtonAction[];
  isEdit?: boolean;
  handleCloseEditMode: VoidFunction;
  fund?: Fund;
}

const FundsListItem: FC<Props> = ({ isEdit, actions, handleCloseEditMode, fund }) => {
  const dispatch = useAppDispatch();

  const {
    reset,
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useFundForm({ fund });

  const onCloseEdit = () => {
    handleCloseEditMode();
    reset();
  };

  const handleEditFund = ({ typesOfShares, ...values }: FundFormInput) => {
    dispatch(editFundById({ ...values, typesOfShares: [typesOfShares?.value] } as Fund))
      .unwrap()
      .finally(handleCloseEditMode);
  };

  const editActions: ButtonAction[] = [
    { id: 'confirm-edit-fund', icon: ConfirmSvg, action: handleSubmit(handleEditFund) },
    { id: 'reset-fund', icon: CloseSvg, action: onCloseEdit },
  ];

  return (
    <ListItemWrapper className='mb-6 last:mb-0'>
      <form onSubmit={handleSubmit(handleEditFund)} className='flex flex-col md:flex-row w-full'>
        <div className='w-full grid grid-cols-2 md:grid-cols-[1fr_1fr_1fr_1fr_1fr] items-end gap-4'>
          <FormContent
            register={register}
            control={control}
            errors={errors}
            fields={FUNDS_ITEM_FORM_FIELDS}
            isDisabled={!isEdit}
            className={cn({ 'disabled-grey-select': !isEdit })}
          />
        </div>
        <ButtonsActions
          className='self-end md:self-center'
          anchorPrefix='fund'
          actions={isEdit ? editActions : actions}
        />
      </form>
    </ListItemWrapper>
  );
};

export default FundsListItem;
