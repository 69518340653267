import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as DocumentSvg } from 'assets/svg/document.svg';
import { TypographyVariants } from 'constants/shared/typography';

import Typography from './Typography';

interface Props {
  className?: string;
}

const MetricCard: FC<Props> = ({ className }) => {
  return (
    <div
      className={twMerge(
        'flex items-center rounded-10 border border-grey-100 min-w-[250px] h-[72px] px-3 py-2.5 ml-5',
        className,
      )}
    >
      <div className='relative min-h-[46px] min-w-[46px] rounded-full overflow-hidden bg-red-500/10'>
        <DocumentSvg className='red-500-svg-path absolute left-1/2 top-1/2 -translate-y-2/4 -translate-x-2/4 z-70 ' />
      </div>
      <div className='flex justify-between items-center w-full w-[80%]'>
        <div className='flex flex-col justify-between mx-1 overflow-hidden'>
          <Typography tag='h5' className='text-ellipsis whitespace-nowrap' variant={TypographyVariants.BODY_SMALL}>
            To Be Decided
          </Typography>
          <Typography className='mt-1' variant={TypographyVariants.LARGE}>
            12312
          </Typography>
        </div>
        <Link to='/' className='text-red-500'>
          View
        </Link>
      </div>
    </div>
  );
};

export default MetricCard;
