import React, { FC } from 'react';

import { InvitedUser } from 'interfaces';
import { CheckmarkWithTitle } from 'shared-components';

interface Props {
  invitationList: InvitedUser[];
}

const InvitedList: FC<Props> = ({ invitationList }) => {
  if (!invitationList?.length) return null;

  return (
    <div className='my-10 space-y-6'>
      {invitationList.map(({ email }) => (
        <CheckmarkWithTitle key={email} title={email} className='fill-green-600' />
      ))}
    </div>
  );
};

export default InvitedList;
