import React, { FC, useState } from 'react';

import { FUNDS_ITEM_FORM_FIELDS } from 'constants/funds';
import useModal from 'hooks/use-modal/useModal';
import { UsePaginationWithSearchReturnValues } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useFundForm from 'hooks/useFundForm';
import { Fund, FundFormInput } from 'interfaces/funds.interfaces';
import { createFund } from 'modules/funds/action';
import { useAppDispatch } from 'modules/store';
import { Button, ButtonsControl, FormContent, ModalWindow } from 'shared-components';

type Props = Partial<UsePaginationWithSearchReturnValues> & {
  loadFunds: VoidFunction;
};

const CreateFundModal: FC<Props> = ({ loadFunds, setPage, page }) => {
  const dispatch = useAppDispatch();

  const [isActionLoading, setIsActionLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useModal();

  const {
    reset,
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useFundForm({});

  const handleCloseModal = () => {
    reset();
    onClose();
  };

  const handleCreateFund = ({ typesOfShares, ...values }: FundFormInput) => {
    setIsActionLoading(true);
    dispatch(createFund({ ...values, typesOfShares: [typesOfShares?.value] } as Fund))
      .unwrap()
      .then(() => {
        if (page === 1) {
          loadFunds();
        }

        setPage?.(1);
        handleCloseModal();
      })
      .finally(() => setIsActionLoading(false));
  };

  return (
    <div>
      <ModalWindow
        title='Create Fund'
        className='min-w-[60%] xl:min-w-[52%]'
        wrapperClassName='pt-10'
        contentClassName='overflow-x-hidden'
        headerClassName='modal-big-title'
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <form className='mt-10' onSubmit={handleSubmit(handleCreateFund)}>
          <FormContent register={register} control={control} errors={errors} fields={FUNDS_ITEM_FORM_FIELDS} />
        </form>
        <ButtonsControl
          submitButtonText='Create'
          backButtonText='Cancel'
          isLoading={isActionLoading}
          onSubmit={handleSubmit(handleCreateFund)}
          onBack={handleCloseModal}
        />
      </ModalWindow>

      <Button onClick={onOpen}>Create Fund</Button>
    </div>
  );
};

export default CreateFundModal;
