import React from 'react';

const ShareExchangeModalTooltipMessage = () => {
  return (
    <div>
      <p>1. Please choose which class(es) of shares you would like to exchange</p>
      <p>2. Please input the number of shares you would like to exchange</p>
      <p>3. Please select from the available dates when you would like your share exchange to close.</p>
    </div>
  );
};

export default ShareExchangeModalTooltipMessage;
