import cn from 'classnames';

import { FormItem } from 'interfaces';
import { Input } from 'shared-components';

import { EMAIL_PATTERN, PASSWORD_PATTERN } from './global';
import { FieldTypes } from './shared';

export enum PROFILE_FORM_TITLES {
  personalDetails = 'Personal Details',
  emailAddress = 'Email Address',
  companyInformation = 'Company Information',
  contactDetails = 'Contact Details',
}

export enum ProfileFieldNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phone',
}

export enum SettingsSecurityFieldNames {
  CURRENT_PASSWORD = 'currentPassword',
  NEW_PASSWORD = 'password',
  CONFIRM_PASSWORD = 'password_confirmation',
}

export const SETTINGS_PAGE_TITLE = 'Settings';

export const PROFILE_TAB_TITLE = 'Profile';

export const PROFILE_TAB_DESCRIPTION = 'Manage your profile';

export const SETTINGS_SECURITY_TITLE = 'Account Security';

export const SETTINGS_SECURITY_DESCRIPTION = 'Manage your account password';

export const SETTINGS_SECURITY_DEVICES_TITLE = "Where you've logged in";

export const SETTINGS_SECURITY_DEVICES_DESCRIPTION = 'Manage your devices and see whos logged in';

export const SETTINGS_INTEGRATIONS_TITLE = 'Integrations';

export const SETTINGS_INTEGRATIONS_DESCRIPTION = 'See your connected accounts & integrations with pland';

export const SETTINGS_INTEGRATIONS_MODAL_TITLE = 'Connect to LinkedIn';

export const SETTINGS_INTEGRATIONS_MODAL_DESCRIPTION =
  'Info about where they can find this link if they dont know where it is?';

export const SETTINGS_ACCOUNT_SETTINGS_TITLE = 'Account Settings';

export const SETTINGS_ACCOUNT_SETTINGS_DESCRIPTION = 'Manage your account password and other security preferences';

export const SETTINGS_RIGHT_TO_FORGET_TITLE = 'Right to forget';

export const SETTINGS_RIGHT_TO_FORGET_DESCRIPTION = 'Delete viewing of your existing share-exchanges info';

export const SETTINGS_RIGHT_TO_FORGET_MODAL_TITLE =
  'Doing this will delete viewing your share-exchanges data from planD';

export const SETTINGS_RIGHT_TO_FORGET_SUCCESS_MESSAGE = 'Your share exchange information was successfully deleted.';

export const getSettingsSecurityFields = (currentPassword: string, isEditMode: boolean): FormItem[] => [
  {
    name: SettingsSecurityFieldNames.CURRENT_PASSWORD,
    label: 'Current Password',
    placeholder: 'Current Password',
    type: FieldTypes.PASSWORD,
    component: Input,
    validation: {
      required: 'Required information missing please input Current Password to continue',
      pattern: {
        message: 'Incorrect Password',
        value: PASSWORD_PATTERN,
      },
    },
    className: cn(
      'hidden items-start sm:items-center flex-col sm:flex-row border-t border-t-grey-200 mt-6 pt-6 one-row-input w-80-relative one-row-label',
      {
        flex: isEditMode,
      },
    ),
  },
  {
    name: SettingsSecurityFieldNames.NEW_PASSWORD,
    label: 'New Password',
    placeholder: 'New Password',
    type: FieldTypes.PASSWORD,
    component: Input,
    validation: {
      required: 'Required information missing please input New Password to continue',
      pattern: {
        message: 'Password criteria not met',
        value: PASSWORD_PATTERN,
      },
    },
    className: cn(
      'hidden items-start sm:items-center flex-col sm:flex-row border-t border-t-grey-200 pt-6 one-row-input w-80-relative one-row-label',
      {
        flex: isEditMode,
      },
    ),
  },
  {
    name: SettingsSecurityFieldNames.CONFIRM_PASSWORD,
    label: 'Confirm Password',
    placeholder: 'Confirm Password',
    type: FieldTypes.PASSWORD,
    component: Input,
    validation: {
      required: 'Required information missing please input Confirm Password to continue',
      validate: (confirmPassword: string) => currentPassword === confirmPassword || 'Passwords must match',
    },
    className: cn(
      'hidden items-start sm:items-center flex-col sm:flex-row order-1 one-row-input w-80-relative one-row-label',
      {
        flex: isEditMode,
      },
    ),
  },
];

export const PERSONAL_DETAILS_FIELDS = [
  {
    name: ProfileFieldNames.FIRST_NAME,
    label: 'First Name',
    placeholder: 'First Name',
    type: FieldTypes.TEXT,
    component: Input,
    validation: {
      required: 'Required information missing please input First Name to continue',
    },
    className: 'col-span-2 sm:col-span-1 mb-0',
  },
  {
    name: ProfileFieldNames.LAST_NAME,
    label: 'Last Name',
    placeholder: 'Last Name',
    type: FieldTypes.TEXT,
    component: Input,
    validation: {
      required: 'Required information missing please input Last Name to continue',
    },
    className: 'col-span-2 sm:col-span-1 mb-0',
  },
];

export const EMAIL_FIELDS = [
  {
    name: ProfileFieldNames.EMAIL,
    label: 'Email Address',
    placeholder: 'Email Address',
    type: FieldTypes.TEXT,
    component: Input,
    validation: {
      required: 'Required information missing please input Email address to continue',
      pattern: { message: 'Invalid email address', value: EMAIL_PATTERN },
    },
    className: 'col-span-1 mb-0',
  },
];

export const CONTACT_DETAILS_FIELDS = [
  {
    name: ProfileFieldNames.PHONE_NUMBER,
    label: 'Phone Number',
    placeholder: 'Phone Number',
    type: FieldTypes.TEXT,
    component: Input,
    className: 'col-span-full xs:col-span-1 mb-0',
  },
];
