import React from 'react';

import { DEALS_PAGE_TITLE, DEALS_TABS_IDS } from 'constants/deals';
import useInitialTab from 'hooks/use-initial-tab/useInitialTab';
import { DashboardLayout } from 'page-components';
import DealsTableTabContainer from 'page-components/deals/deals-table/DealsTableTabContainer';
import { TitleWithDescription, Tabs } from 'shared-components';

export const DEALS_PAGE_TABS = [
  { id: DEALS_TABS_IDS.SHARE_EXCHANGES, name: 'Share Exchanges', component: DealsTableTabContainer },
];

const Deals = () => {
  const initialTabId = useInitialTab();

  return (
    <DashboardLayout>
      <div className='contentful-dashboard-page'>
        <TitleWithDescription className='mb-6' title={DEALS_PAGE_TITLE} />

        <Tabs tabs={DEALS_PAGE_TABS} initialTabId={initialTabId} />
      </div>
    </DashboardLayout>
  );
};

export default Deals;
