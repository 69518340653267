import { TableColumns } from '../interfaces';

export const INVESTORS_PAGE_TITLE = 'Investors';

export const INVESTMENTS_PAGE_PLACEHOLDER = 'Search by First Name, Last Name, Email Address or Phone';

export const INVESTORS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'firstName', title: 'First Name', sortable: true },
  { id: 'lastName', title: 'Last Name', sortable: true },
  { id: 'email', title: 'Email address', sortable: true },
  { id: 'phone', title: 'Phone number', sortable: true },
  { id: 'companies', title: 'Companies' },
  { id: 'deals', title: 'Deals' },
];

export const ADMIN_DASHBOARD_INVESTORS_PIE_CHART_METRICS_LABELS = {
  totalRegistered: 'Registered Users',
  totalPending: 'Pending Investors',
  totalCompleted: 'Investors',
};
