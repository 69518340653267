import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';

import { MY_INVITES_SNIPPET_DESCRIPTION, MY_INVITES_SNIPPET_TITLE } from 'constants/dashboard';
import useModal from 'hooks/use-modal/useModal';
import { DeletePendingInviteUser, DeletePendingInviteUserFunctionType } from 'interfaces';
import { deletePendingInviteUser, getMyInvites } from 'modules/current-user/action';
import { selectIsActionLoading, selectIsLoading, selectMyInvites } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AlertModal, SnippetLayout } from 'shared-components';
import { checkIsFounder } from 'utils';

import MyInvites from './MyInvites';
import MyInvitesModalForm from './MyInvitesModalForm';

const MyInvitesSnippet = () => {
  const [deleteInviteUser, setDeleteInviteUser] = useState<DeletePendingInviteUser | null>(null);

  const dispatch = useAppDispatch();
  const myInvites = useAppSelector(selectMyInvites);
  const isActionLoading = useAppSelector(selectIsActionLoading);
  const isLoading = useAppSelector(selectIsLoading);

  const isFounder = useMemo(() => checkIsFounder(), []);

  const { onOpen, ...deletePendingInvitesModalProps } = useModal();

  const handleOpenDeletePendingInvitesModal: DeletePendingInviteUserFunctionType = (user) => {
    setDeleteInviteUser(user);
    onOpen();
  };

  const handleDeletePendingInvite = () => {
    if (!deleteInviteUser) return;
    dispatch(deletePendingInviteUser(deleteInviteUser?.id))
      .unwrap()
      .finally(() => {
        deletePendingInvitesModalProps.onClose();
        setDeleteInviteUser(null);
      });
  };

  useEffect(() => {
    dispatch(getMyInvites());
  }, [dispatch]);

  return (
    <>
      <AlertModal
        {...deletePendingInvitesModalProps}
        onSubmit={handleDeletePendingInvite}
        title='Please confirm removal'
        description={`- ${deleteInviteUser?.email}`}
        descriptionClassName='mt-6'
        isLoading={isActionLoading}
      />
      <SnippetLayout
        title={MY_INVITES_SNIPPET_TITLE}
        description={MY_INVITES_SNIPPET_DESCRIPTION}
        className={cn({ 'col-span-full': isFounder })}
        childrenClassName={'!mt-6'}
      >
        <MyInvites
          listClassName='max-h-44 mb-6'
          invites={myInvites}
          isLoading={isLoading || isActionLoading}
          handleDeleteInvite={handleOpenDeletePendingInvitesModal}
        />
        <MyInvitesModalForm loading={isActionLoading} />
      </SnippetLayout>
    </>
  );
};

export default MyInvitesSnippet;
