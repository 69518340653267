import React from 'react';

import { ReactComponent as ArrowSvg } from 'assets/svg/arrow-right.svg';
import { ROUTES } from 'constants/routes';
import { LinkButton, SnippetLayout } from 'shared-components';
import { navigateByRole } from 'utils';

import AdminInvestorsSnippetContent from './AdminInvestorsSnippetContent';

const AdminInvestorsSnippet = () => {
  return (
    <SnippetLayout
      description={
        <LinkButton to={navigateByRole(true, ROUTES.investors)}>
          View more <ArrowSvg className='ml-2.5' />
        </LinkButton>
      }
      title='Investors'
      className='snippet-title col-span-2 sm:col-span-1 2lg:col-span-2 !2xl:col-span-1'
      childrenClassName='mt-10 flex justify-around lg:-ml-4 2lg:-ml-0 2lg:px-4'
    >
      <AdminInvestorsSnippetContent />
    </SnippetLayout>
  );
};

export default AdminInvestorsSnippet;
