import React from 'react';

import { ReactComponent as ArrowSvg } from 'assets/svg/arrow-right.svg';
import { ROUTES } from 'constants/routes';
import { LinkButton, SnippetLayout } from 'shared-components';
import { navigateByRole } from 'utils';

import AdminDealsSnippetContent from './AdminDealsSnippetContent';

const AdminDealsSnippet = () => {
  return (
    <SnippetLayout
      description={
        <LinkButton to={navigateByRole(true, ROUTES.deals)}>
          View more <ArrowSvg className='ml-2.5' />
        </LinkButton>
      }
      title='Share Exchanges'
      className='snippet-title col-span-full'
      childrenClassName='grid grid-cols-2 gap-y-12 gap-x-4'
    >
      <AdminDealsSnippetContent />
    </SnippetLayout>
  );
};

export default AdminDealsSnippet;
