import React from 'react';

import { UserTypes } from 'constants/user';
import useModal from 'hooks/use-modal/useModal';
import { AlertModal, Button } from 'shared-components';

interface Props {
  onResubmit: (isReSubmit?: boolean) => void;
  userType: UserTypes | undefined;
  lastUpdated: string | null;
  isLocked?: boolean;
}

const ReSubmitButtonWithModal = ({ onResubmit, userType, isLocked }: Props) => {
  const { onOpen, ...reSubmitModalProps } = useModal();

  const handleSubmit = () => {
    onResubmit(true);
    reSubmitModalProps.onClose();
  };

  if (userType !== UserTypes.ADMIN) return null;

  return (
    <div className='flex-1'>
      <AlertModal
        onSubmit={handleSubmit}
        title='Are you sure?'
        submitButtonText='Yes'
        backButtonText='No'
        descriptionClassName='mt-7'
        isLoading={false}
        {...reSubmitModalProps}
      />
      <Button className='w-full md:w-[150px] md:ml-4' disabled={isLocked} onClick={!isLocked ? onOpen : undefined}>
        Re-submit
      </Button>
    </div>
  );
};

export default ReSubmitButtonWithModal;
