import React from 'react';

import { TypographyVariants } from 'constants/shared/typography';
import { VerticalStepperItem as VerticalStepperItemType } from 'interfaces';

import Typography from '../Typography';

const VerticalStepperItem = ({ step, content }: VerticalStepperItemType) => {
  return (
    <li className='relative flex pb-4 after:content-[""] after:absolute after:left-[22px] after:top-14 after:bottom-2 after:w-0.5 after:bg-violet-500 min-h-[85px] last:after:hidden'>
      <div className='flex'>
        <div className='py-2 px-4 rounded-full flex justify-center items-center bg-violet-500 max-h-12 max-w-12'>
          <Typography variant={TypographyVariants.MEDIUM} className='text-white z-10'>
            {step}
          </Typography>
        </div>
        <Typography className='ml-6 mt-3 text-grey-500'>{content}</Typography>
      </div>
    </li>
  );
};

export default VerticalStepperItem;
