import { createAsyncThunk } from '@reduxjs/toolkit';

import { adminInvestorsApi, adminInvestorsMetrics } from 'apis';
import { showServerError } from 'helpers';
import { GetListPayload } from 'interfaces';

export const INVESTORS_SLICE_NAME = 'investors';

export const getInvestors = createAsyncThunk(`${INVESTORS_SLICE_NAME}/getInvestors`, async (data: GetListPayload) => {
  const response = await adminInvestorsApi.getInvestors(data);
  return response.data;
});

export const getInvestorsMetrics = createAsyncThunk(
  `${INVESTORS_SLICE_NAME}/getAdminInvestorsMetrics`,
  async (rangeFilter: string) => {
    const response = await adminInvestorsMetrics.getMetrics(rangeFilter);
    return response.data;
  },
);

export const getInvestorsPieChartMetrics = createAsyncThunk(
  `${INVESTORS_SLICE_NAME}/getAdminInvestorsPieChart`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminInvestorsMetrics.getPieChart();

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);
