import React, { forwardRef, FunctionComponent, InputHTMLAttributes, ReactElement } from 'react';

import cn from 'classnames';
import { debounce } from 'lodash';
import { twMerge } from 'tailwind-merge';

import { CheckboxVariants, CheckboxVariantsStyles } from 'constants/shared';

import FormLabel from '../form-label/FormLabel';
export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: FunctionComponent<any> | string;
  className?: string;
  inputClassName?: string;
  error?: string | boolean;
  variant?: CheckboxVariants;
  debounceTime?: number;
}

export const INPUT_DEFAULT_STYLES = `peer overflow-hidden relative left-0 h-6 w-6 shrink-0
 appearance-none rounded border-2 outline-none after:absolute after:left-0 after:top-0 after:h-full after:w-full
 after:bg-none after:bg-center after:bg-no-repeat after:content-[''] checkbox-check-mark hover:outline-2 hover:outline-blue-200 transition-[outline] disabled:outline-0 disabled:after:bg-transparent checked:disabled:after:bg-grey-300`;

const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    { label, className, inputClassName, error, variant = CheckboxVariants.MAIN, onChange, debounceTime, ...props },
    ref,
  ) => {
    const renderedLabel = (): ReactElement | string => {
      if (!label) {
        return '';
      }

      if (typeof label === 'function') {
        const Component = label;
        return <Component htmlFor={props.name} />;
      }

      return label;
    };

    return (
      <div className={className}>
        <div className={cn('flex w-full gap-2.5 cursor-pointer')}>
          <input
            id={props.name}
            type='checkbox'
            ref={ref}
            {...props}
            onChange={debounceTime ? debounce(onChange as never, debounceTime) : onChange}
            className={twMerge(
              INPUT_DEFAULT_STYLES,
              CheckboxVariantsStyles[variant],
              error ? 'border-red-500' : 'border-blue-500',
              props.disabled ? 'cursor-not-allowed border-grey-300 checked:border-grey-300' : 'cursor-pointer',
              inputClassName,
            )}
          />
          {renderedLabel() && (
            <FormLabel
              htmlFor={props.name}
              disabled={props.disabled}
              className='inline-block w-full cursor-pointer mb-0'
            >
              {renderedLabel()}
            </FormLabel>
          )}
        </div>
      </div>
    );
  },
);

export default Checkbox;

Checkbox.displayName = 'Checkbox';
