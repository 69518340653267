import { ChangeEvent } from 'react';

import { addDays, differenceInDays, format } from 'date-fns';
import { UseFormSetValue } from 'react-hook-form';
import { CSSObjectWithLabel } from 'react-select';

import { validateClosingDate } from 'helpers';
import {
  COMPANY_IAC_STATUSES,
  CompanyIacDeclineFieldNames,
  CompanyIacOverviewFormState,
  CompanyIacRadioInputSetField,
  CompanyIacStopFieldNames,
  CompanyIacTextAreaNotesField,
  CompanyIacTableFieldsLabel,
  FormItem,
  Option,
  CompanyIacStartDealFieldNames,
} from 'interfaces';
import { BusinessReviewRadioConflictsOfInterestsLegend } from 'page-components/company-iac';
import { Checkbox, Input, SelectFormInput, TextArea, InputWithPrefixSign, DatePickerInput } from 'shared-components';
import NumberInput from 'shared-components/NumberInput';
import { getClosestWorkOrCurrentDate } from 'utils';

import { DAY_FORMAT_WITH_DASH } from './dateFormats';
import { COMMERCIAL_PARAMETERS_INPUT_CLASSNAME } from './deal-parameters';
import { COMPANY_APPROVAL_FIELD_OPTIONS } from './due-diligence';
import {
  EMAIL_PATTERN,
  ENTER_NUMBER_MESSAGE,
  ENTER_DECIMAL_NUMBER_MESSAGE,
  INTEGER_PATTERN,
  POSITIVE_NUMBER_FLOAT_PATTERN,
  REQUIRED_MESSAGE,
  DECIMAL_NUMBER_PATTERN,
  NUMBERS_PATTERN,
  ENTER_INTEGER_NUMBER_MESSAGE,
} from './global';
import { ColoredLabelVariants, FieldTypes } from './shared';

const TEXT_AREA_MAX_LENGTH_ERROR = {
  value: 1500,
  message: 'The input length should be less than 1500 characters.',
};

export const COMPANY_IAC_PAGE_SUBTITLE = 'IAC Investment Proposal';

export const COMPANY_IAC_SUBMIT_TITLE = 'Confirm & Review';

export const COMPANY_IAC_SEND_TO_REVIEW_TITLE = 'Send to Review';

export const COMPANY_IAC_RESEND_TO_REVIEW_TITLE = 'Re-send to Review';

export const COMPANY_IAC_START_DEAL_TITLE = 'Start Deal';

export const COMPANY_IAC_TAKE_ACTION_TITLE = 'Take Action';

export const FUND_MANAGER_COMPANY_IAC_SUBMIT_TITLE = 'Confirm IAC Investment Proposal';

export const COMPANY_IAC_SUBMIT_DESCRIPTION =
  'To my knowledge the answers provided for this investment proposal are complete, true, fair and not misleading';

export const COMPANY_IAC_SUBMIT_TEXT = 'Confirm Form';

export const COMPANY_IAC_START_DEAL_TEXT = 'Start Deal';

export const FUND_MANAGER_COMPANY_IAC_SUBMIT_TEXT = 'Confirm IAC Investment Proposal';

export const FUND_MANAGER_COMPANY_IAC_DECLINE_TEXT = 'Decline/Request Additional Information';

export const COMPANY_IAC_SHARES_FIELDS_LABEL = 'Fully Diluted Share Capital*';

export const COMPANY_IAC_PURCHASE_COMPLETION_LABEL = 'Number, class and % of shares to purchase at completion*';

export const COMPANY_IAC_FILL_CUSTOM_FIELDS_MESSAGE = 'Please save or complete the form for custom field';

export const COMPANY_IAC_REQUIRED_CLOSING_DATES_MESSAGE = 'Closing dates is required';

export const COMPANY_IAC_SEND_REQUEST_FOR_REVIEW_TEXT = 'Send request for review to Fund Manager';

export const COMPANY_IAC_SEND_ONE_MORE_REQUEST_FOR_REVIEW_TEXT = 'Send one more request to review to Fund Manager';

export const COMPANY_IAC_RESEND_REQUEST_FOR_REVIEW_TEXT = 'Re-send request for review to Fund Manager';

const COMPANY_IAC_PURCHASE_AT_FIELDS_SHARED_CLASSNAME =
  'mb-0 relative [&_label]:absolute [&_label]:-top-8 [&_label]:left-0';

export const COMPANY_IAC_BORDERED_WRAPPER_CLASSNAME =
  'p-6 rounded-2xl bg-white flex flex-col border border-grey-200 overflow-hidden';

export enum InvestmentProposalOverviewFieldNames {
  COMPANY_NAME = 'companyName',
  INCORPORATION_DATE = 'incorporationDate',
  WEBSITE = 'website',
  COMPANY_NUMBER = 'companyNumber',
  SECTOR = 'businessSector',
  CLASS_OF_SHARES = 'classOfShares',
  NO_OF_SHARES_SHARE_CAPITAL = 'noOfSharesShareCapital',
  SHARES_CHECKED = 'checked',
  COMPANY_APPROVAL_REQUIRED_FOR_SECONDARY_SALE = 'approvalForSecondarySale',
  PERCENT = 'percent',
  NO_OF_SHARES_PURCHASE_AT_COMPLETION = 'noOfSharesPurchaseAtCompletion',
  SHARE_CLASS = 'shareClass',
  PROPOSED_PURCHASE_PRICE = 'proposedPrice',
  NUMBER_OF_SHAREHOLDERS = 'numberOfShareholders',
  OTHER_NOTABLE_DETAILS = 'notes',
  COMPANY_STAGE = 'stageOfDevelopment',
  DENOMINATION = 'denomination',
  SHARE_PRICE = 'sharePrice',
  CLOSING_DATE = 'closingDate',
  CLOSING_DATE_DURATION = 'duration',
}

export enum InvestmentProposalSummaryFieldNames {
  COMPANY_OVERVIEW = 'companyOverview',
  BACKGROUND_INVESTMENT_PROPOSAL = 'backgroundInvestmentProposal',
  FINANCIAL_SHARE_CAPITAL = 'finansialShareCapital',
  VALUATION_METRICS = 'valuationMetrics',
}

export enum InvestmentProposalBlockNames {
  OVERVIEW = 'overview',
  SUMMARY = 'summary',
  BUSINESS_REVIEW = 'businessReview',
}

export enum CustomFieldsFieldNames {
  LABEL = 'label',
  VALUE = 'value',
}

export enum InvitingFundFieldNames {
  FM_MANAGER = 'fmManager',
}

export enum InvitingFundAddNewFieldNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  ADD_ANOTHER = 'addAnother',
}

export const INVESTMENT_PROPOSAL_OVERVIEW_FORM_FIELDS: FormItem[] = [
  {
    name: InvestmentProposalOverviewFieldNames.COMPANY_NAME,
    label: 'Company name',
    placeholder: 'Company name',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: { required: REQUIRED_MESSAGE },
  },
  {
    name: InvestmentProposalOverviewFieldNames.WEBSITE,
    label: 'Website',
    placeholder: 'Website',
    type: FieldTypes.TEXT,
    component: Input,
  },
];

export const INVESTMENT_PROPOSAL_OVERVIEW_PROPOSED_PURCHASE_PRICE_FIELD: FormItem = {
  name: InvestmentProposalOverviewFieldNames.PROPOSED_PURCHASE_PRICE,
  label: 'Daxia Price per Share',
  placeholder: 'Daxia Price per Share',
  type: FieldTypes.TEXT,
  component: Input,
  required: true,
  validation: {
    required: REQUIRED_MESSAGE,
    pattern: {
      value: DECIMAL_NUMBER_PATTERN,
      message: ENTER_DECIMAL_NUMBER_MESSAGE,
    },
  },
};

export const INVESTMENT_PROPOSAL_OVERVIEW_OTHER_NOTABLE_FIELD: FormItem = {
  name: InvestmentProposalOverviewFieldNames.OTHER_NOTABLE_DETAILS,
  label: 'Other Notable Details',
  placeholder: 'Other Notable Details',
  type: FieldTypes.TEXT,
  component: TextArea,
};

export const COMPANY_IAC_SHARES_FIELDS_COMMON_CLASSNAMES = {
  CLASS_OF_SHARES: 'w-[220px] mb-0',
  NO_OF_SHARES: 'w-[120px] mx-4 mb-0',
};

export const COMPANY_IAC_SHARES_FIELDS_LABELS: CompanyIacTableFieldsLabel[] = [
  { className: COMPANY_IAC_SHARES_FIELDS_COMMON_CLASSNAMES.CLASS_OF_SHARES, label: 'Class of Shares' },
  { className: COMPANY_IAC_SHARES_FIELDS_COMMON_CLASSNAMES.NO_OF_SHARES, label: 'No. of Shares' },
  { className: 'min-w-[165px]', label: 'Target' },
];

export const COMPANY_IAC_SHARES_FIELDS = (
  index: number,
  setValue: UseFormSetValue<CompanyIacOverviewFormState>,
  checkDisabledFields?: (index: number, fieldName: string) => boolean,
): FormItem[] => [
  {
    name: `fullyDilutedShareCapital.${index}.${InvestmentProposalOverviewFieldNames.CLASS_OF_SHARES}`,
    placeholder: 'Class of Shares',
    type: FieldTypes.TEXT,
    component: Input,
    className: COMPANY_IAC_SHARES_FIELDS_COMMON_CLASSNAMES.CLASS_OF_SHARES,
    disabled: checkDisabledFields && checkDisabledFields(index, InvestmentProposalOverviewFieldNames.CLASS_OF_SHARES),
    validation: { required: REQUIRED_MESSAGE },
  },
  {
    name: `fullyDilutedShareCapital.${index}.${InvestmentProposalOverviewFieldNames.NO_OF_SHARES_SHARE_CAPITAL}`,
    placeholder: 'No. of Shares',
    type: FieldTypes.NUMBER,
    component: NumberInput,
    className: COMPANY_IAC_SHARES_FIELDS_COMMON_CLASSNAMES.NO_OF_SHARES,
    validation: { required: REQUIRED_MESSAGE },
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(INTEGER_PATTERN, '');
      setValue(
        `fullyDilutedShareCapital.${index}.${InvestmentProposalOverviewFieldNames.NO_OF_SHARES_SHARE_CAPITAL}`,
        value,
      );
    },
  },
  {
    name: `fullyDilutedShareCapital.${index}.${InvestmentProposalOverviewFieldNames.SHARES_CHECKED}`,
    type: FieldTypes.CHECKBOX,
    component: Checkbox,
    className: 'flex items-center [&_input]:top-0',
  },
];

export const COMPANY_IAC_PURCHASE_AT_COMPLETION = (
  setValue: UseFormSetValue<CompanyIacOverviewFormState>,
  totalShares: number,
): FormItem[] => [
  {
    name: `sharesToPurchase.${InvestmentProposalOverviewFieldNames.PERCENT}`,
    label: '%',
    placeholder: '%',
    type: FieldTypes.TEXT,
    component: Input,
    className: `col-span-2 sm:col-span-1 ${COMPANY_IAC_PURCHASE_AT_FIELDS_SHARED_CLASSNAME}`,
    validation: { required: REQUIRED_MESSAGE },
  },
  {
    name: `sharesToPurchase.${InvestmentProposalOverviewFieldNames.NO_OF_SHARES_PURCHASE_AT_COMPLETION}`,
    label: 'No. of Shares',
    placeholder: 'No. of Shares',
    type: FieldTypes.NUMBER,
    component: NumberInput,
    className: `col-span-2 sm:col-span-1 ${COMPANY_IAC_PURCHASE_AT_FIELDS_SHARED_CLASSNAME}`,
    validation: { required: REQUIRED_MESSAGE },
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(INTEGER_PATTERN, '');
      setValue(`sharesToPurchase.${InvestmentProposalOverviewFieldNames.NO_OF_SHARES_PURCHASE_AT_COMPLETION}`, value);
      setValue(
        `sharesToPurchase.${InvestmentProposalOverviewFieldNames.PERCENT}`,
        String(((Number(value) / totalShares) * 100).toFixed(2)),
      );
    },
  },
  {
    name: `sharesToPurchase.${InvestmentProposalOverviewFieldNames.SHARE_CLASS}`,
    label: 'Notes',
    placeholder: 'Notes',
    type: FieldTypes.TEXT,
    component: Input,
    className: `col-span-2 ${COMPANY_IAC_PURCHASE_AT_FIELDS_SHARED_CLASSNAME}`,
    validation: {
      maxLength: {
        value: 100,
        message: 'The input length should be less than 100 characters.',
      },
    },
  },
];

export const COMPANY_IAC_CLOSING_DATES_COMMON_CLASSNAME = {
  NUMBER: 'w-1/6 mx-1',
  DURATION: 'w-2/6 mx-1',
  CLOSING_DATE: 'w-2/6 mx-',
  ACTIONS: 'w-1/6',
};

export const COMPANY_IAC_CLOSING_DATES_TITLES = [
  { label: 'Number', className: COMPANY_IAC_CLOSING_DATES_COMMON_CLASSNAME.NUMBER },
  { label: 'Duration', className: COMPANY_IAC_CLOSING_DATES_COMMON_CLASSNAME.DURATION },
  { label: 'Closing Date', className: COMPANY_IAC_CLOSING_DATES_COMMON_CLASSNAME.CLOSING_DATE },
  { label: '', className: COMPANY_IAC_CLOSING_DATES_COMMON_CLASSNAME.ACTIONS },
];

export const COMPANY_IAC_CLOSING_DATES_FIELDS = (
  index: number,
  setValue: UseFormSetValue<CompanyIacOverviewFormState>,
): FormItem[] => {
  const fieldNamePrefix: `closingDates.${number}` = `closingDates.${index}`;

  return [
    {
      name: `${fieldNamePrefix}.${InvestmentProposalOverviewFieldNames.CLOSING_DATE_DURATION}`,
      placeholder: 'Duration',
      type: FieldTypes.TEXT,
      component: Input,
      className: `${COMPANY_IAC_CLOSING_DATES_COMMON_CLASSNAME.DURATION} mb-0`,
      validation: {
        required: REQUIRED_MESSAGE,
        pattern: {
          value: NUMBERS_PATTERN,
          message: ENTER_INTEGER_NUMBER_MESSAGE,
        },
      },
      onChange: (e) => {
        const calculatedDate = addDays(new Date(), Number(e.target.value));

        setValue(`${fieldNamePrefix}.${InvestmentProposalOverviewFieldNames.CLOSING_DATE_DURATION}`, e.target.value);
        setValue(
          `${fieldNamePrefix}.${InvestmentProposalOverviewFieldNames.CLOSING_DATE}`,
          format(getClosestWorkOrCurrentDate(calculatedDate), DAY_FORMAT_WITH_DASH),
        );
      },
    },
    {
      name: `${fieldNamePrefix}.${InvestmentProposalOverviewFieldNames.CLOSING_DATE}`,
      placeholder: 'Closing Date',
      type: FieldTypes.DATE,
      component: DatePickerInput,
      className: `${COMPANY_IAC_CLOSING_DATES_COMMON_CLASSNAME.CLOSING_DATE} mb-0`,
      min: new Date(),
      validation: {
        validate: (closingDateValue: string) => {
          return validateClosingDate(closingDateValue, false);
        },
      },
      onChange: (e) => {
        const calculatedDate = getClosestWorkOrCurrentDate(new Date(e.target.value));

        const duration = String(differenceInDays(calculatedDate, new Date()));

        setValue(
          `${fieldNamePrefix}.${InvestmentProposalOverviewFieldNames.CLOSING_DATE}`,
          format(calculatedDate, DAY_FORMAT_WITH_DASH),
        );

        setValue(`${fieldNamePrefix}.${InvestmentProposalOverviewFieldNames.CLOSING_DATE_DURATION}`, duration);
      },
    },
  ];
};

export const COMPANY_IAC_SHARE_PRICE_FIELDS_COMMON_CLASSNAME = {
  CLASS_OF_SHARES: 'w-1/3 mx-1 disabled-light-grey',
  DENOMINATION: 'w-1/3 mx-1',
  SHARE_PRICE: 'w-1/3 mx-1',
};

export const COMPANY_IAC_SHARE_PRICE_TITLES = [
  { label: 'Class of Shares', className: COMPANY_IAC_SHARE_PRICE_FIELDS_COMMON_CLASSNAME.CLASS_OF_SHARES },
  { label: 'Denomination', className: COMPANY_IAC_SHARE_PRICE_FIELDS_COMMON_CLASSNAME.DENOMINATION },
  { label: 'planD offer Price per Share', className: COMPANY_IAC_SHARE_PRICE_FIELDS_COMMON_CLASSNAME.SHARE_PRICE },
];

export const COMPANY_IAC_SHARE_PRICE_FIELDS = (index: number): FormItem[] => [
  {
    name: `companySharePrices.${index}.${InvestmentProposalOverviewFieldNames.CLASS_OF_SHARES}`,
    placeholder: 'Class of Shares',
    type: FieldTypes.TEXT,
    component: Input,
    className: `${COMPANY_IAC_SHARE_PRICE_FIELDS_COMMON_CLASSNAME.CLASS_OF_SHARES} mb-0`,
    disabled: true,
  },
  {
    name: `companySharePrices.${index}.${InvestmentProposalOverviewFieldNames.DENOMINATION}`,
    placeholder: 'Denomination',
    type: FieldTypes.NUMBER,
    component: NumberInput,
    className: `${COMPANY_IAC_SHARE_PRICE_FIELDS_COMMON_CLASSNAME.DENOMINATION} mb-0 disabled-light-grey-prefix`,
    validation: {
      required: REQUIRED_MESSAGE,
      pattern: {
        value: DECIMAL_NUMBER_PATTERN,
        message: ENTER_DECIMAL_NUMBER_MESSAGE,
      },
    },
    symbol: '£',
    fractionLength: 50,
  },
  {
    name: `companySharePrices.${index}.${InvestmentProposalOverviewFieldNames.SHARE_PRICE}`,
    placeholder: 'Share Price',
    type: FieldTypes.NUMBER,
    component: NumberInput,
    className: `${COMPANY_IAC_SHARE_PRICE_FIELDS_COMMON_CLASSNAME.SHARE_PRICE} mb-0 disabled-light-grey-prefix`,
    validation: {
      required: REQUIRED_MESSAGE,
      pattern: {
        value: DECIMAL_NUMBER_PATTERN,
        message: ENTER_DECIMAL_NUMBER_MESSAGE,
      },
    },
    symbol: '£',
    fractionLength: 50,
  },
];

export const INVESTMENT_PROPOSAL_OVERVIEW_FORM_FIELDS_SECOND_PART: FormItem[] = [
  {
    name: InvestmentProposalOverviewFieldNames.INCORPORATION_DATE,
    label: 'Incorporation Date',
    placeholder: 'Incorporation Date',
    type: FieldTypes.DATE,
    required: true,
    component: DatePickerInput,
    validation: { required: REQUIRED_MESSAGE },
  },
  {
    name: InvestmentProposalOverviewFieldNames.COMPANY_NUMBER,
    label: 'Company Number',
    placeholder: 'Company Number',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: { required: REQUIRED_MESSAGE },
  },
  {
    name: InvestmentProposalOverviewFieldNames.COMPANY_STAGE,
    label: 'Company Stage of development*',
    placeholder: 'Select from dropdown',
    type: FieldTypes.SELECT,
    required: true,
    component: SelectFormInput,
    options: [
      { label: 'Declining revenues - loss making', value: 'Declining revenues - loss making' },
      { label: 'Declining revenues - profitable', value: 'Declining revenues - profitable' },
      { label: 'Flat revenues - loss making', value: 'Flat revenues - loss making' },
      { label: 'Flat revenues - profitable', value: 'Flat revenues - profitable' },
      { label: 'Growing revenues - loss making', value: 'Growing revenues - loss making' },
      { label: 'Growing revenues - profitable', value: 'Growing revenues - profitable' },
    ],
    validation: { required: REQUIRED_MESSAGE },
  },
  {
    name: InvestmentProposalOverviewFieldNames.COMPANY_APPROVAL_REQUIRED_FOR_SECONDARY_SALE,
    label: 'Company approval required for secondary sale?*',
    placeholder: 'Select from dropdown',
    type: FieldTypes.SELECT,
    required: true,
    component: SelectFormInput,
    disabled: true,
    options: COMPANY_APPROVAL_FIELD_OPTIONS,
    validation: { required: REQUIRED_MESSAGE },
  },
  {
    name: InvestmentProposalOverviewFieldNames.NUMBER_OF_SHAREHOLDERS,
    label: 'Number of shareholders',
    placeholder: 'Number of shareholders',
    type: FieldTypes.TEXT,
    component: Input,
  },
];

export const INVESTMENT_PROPOSAL_SUMMARY_FORM_FIELDS: FormItem[] = [
  {
    name: InvestmentProposalSummaryFieldNames.COMPANY_OVERVIEW,
    label: 'Company overview/ business case*',
    placeholder: 'Company overview/ business case',
    type: FieldTypes.TEXT,
    required: true,
    component: TextArea,
    validation: {
      required: REQUIRED_MESSAGE,
      maxLength: TEXT_AREA_MAX_LENGTH_ERROR,
    },
  },
  {
    name: InvestmentProposalSummaryFieldNames.BACKGROUND_INVESTMENT_PROPOSAL,
    label: 'Background to investment proposal*',
    placeholder: 'Background to investment proposal',
    type: FieldTypes.TEXT,
    required: true,
    component: TextArea,
    validation: {
      required: REQUIRED_MESSAGE,
      maxLength: TEXT_AREA_MAX_LENGTH_ERROR,
    },
  },
  {
    name: InvestmentProposalSummaryFieldNames.FINANCIAL_SHARE_CAPITAL,
    label: 'Financial and share capital summary*',
    placeholder: 'Financial and share capital summary',
    type: FieldTypes.TEXT,
    required: true,
    component: TextArea,
    validation: {
      required: REQUIRED_MESSAGE,
      maxLength: TEXT_AREA_MAX_LENGTH_ERROR,
    },
  },
  {
    name: InvestmentProposalSummaryFieldNames.VALUATION_METRICS,
    label: 'Valuation justification / Metrics*',
    placeholder: 'Valuation justification / Metrics',
    type: FieldTypes.TEXT,
    required: true,
    component: TextArea,
    validation: {
      required: REQUIRED_MESSAGE,
      maxLength: TEXT_AREA_MAX_LENGTH_ERROR,
    },
  },
];

export enum BusinessReviewFieldNames {
  MARKET_OPPORTUNITY = 'marketOpportunity',
  PARTNERSHIP_OPPORTUNITY = 'partnershipOpportunity',
  IP_POSITION = 'IPPosition',
  CASH_RUNAWAY_IN_NUMBER_OF_MONTH = 'cashRunwayInNoOfMonth',
  MANAGEMENT_TEAM = 'managementTeam',
  MILESTONES = 'milestones',
  NEXT_INVESTMENT_PLANS = 'nextInvestmentPlans',
  COMPETITION = 'competition',
}

export enum BusinessReviewRadioFieldNames {
  EXTERNALS_USED_FOR_DUE_DILIGENCE = 'externalsUsedForDiligence',
  EXTERNALS_USED_FOR_DUE_DILIGENCE_NOTES = 'externalsUsedForDiligenceNotes',
  DD_RED_FLAGS = 'DDRedFlags',
  DD_RED_FLAGS_NOTES = 'DDRedFlagsNotes',
  REGULATORY_CHECKS = 'regulatoryChecks',
  REGULATORY_CHECKS_NOTES = 'regulatoryChecksNotes',
  OTHER_MATTERS = 'otherMatters',
  OTHER_MATTERS_NOTES = 'otherMattersNotes',
  CONFLICTS_OF_INTEREST = 'conflictsOfInterest',
  CONFLICTS_OF_INTEREST_NOTES = 'conflictsOfInterestNotes',
}

export const BUSINESS_REVIEW_FORM_FIELDS: FormItem[] = [
  {
    name: BusinessReviewFieldNames.MARKET_OPPORTUNITY,
    label: 'Market opportunity & Market size',
    placeholder: 'Market opportunity & Market size',
    type: FieldTypes.TEXT,
    component: TextArea,
    validation: { maxLength: TEXT_AREA_MAX_LENGTH_ERROR },
  },
  {
    name: BusinessReviewFieldNames.COMPETITION,
    label: 'Competitor',
    placeholder: 'Competitor',
    type: FieldTypes.TEXT,
    component: TextArea,
    validation: { maxLength: TEXT_AREA_MAX_LENGTH_ERROR },
  },
  {
    name: BusinessReviewFieldNames.PARTNERSHIP_OPPORTUNITY,
    label: 'Partnership Opportunities',
    placeholder: 'Partnership Opportunities',
    type: FieldTypes.TEXT,
    component: TextArea,
    validation: { maxLength: TEXT_AREA_MAX_LENGTH_ERROR },
  },
  {
    name: BusinessReviewFieldNames.IP_POSITION,
    label: 'IP position',
    placeholder: 'IP position',
    type: FieldTypes.TEXT,
    component: TextArea,
    validation: { maxLength: TEXT_AREA_MAX_LENGTH_ERROR },
  },
  {
    name: BusinessReviewFieldNames.MANAGEMENT_TEAM,
    label: 'Management team (strengths and weaknesses, roles filled/ to be filled, risks and developments)',
    placeholder: 'Management team (strengths and weaknesses, roles filled/ to be filled, risks and developments)',
    type: FieldTypes.TEXT,
    component: TextArea,
    validation: { maxLength: TEXT_AREA_MAX_LENGTH_ERROR },
  },
  {
    name: BusinessReviewFieldNames.CASH_RUNAWAY_IN_NUMBER_OF_MONTH,
    label: 'Cash runway in number of months at time of IAC*',
    placeholder: 'Cash runway in number of months at time of IAC',
    type: FieldTypes.TEXT,
    component: TextArea,
    validation: { required: REQUIRED_MESSAGE, maxLength: TEXT_AREA_MAX_LENGTH_ERROR },
  },
  {
    name: BusinessReviewFieldNames.MILESTONES,
    label: 'Milestones',
    placeholder: 'Milestones',
    type: FieldTypes.TEXT,
    component: TextArea,
    validation: { maxLength: TEXT_AREA_MAX_LENGTH_ERROR },
  },
  {
    name: BusinessReviewFieldNames.NEXT_INVESTMENT_PLANS,
    label: 'Next investment round/financing plans',
    placeholder: 'Next investment round/financing plans',
    type: FieldTypes.TEXT,
    component: TextArea,
    validation: { maxLength: TEXT_AREA_MAX_LENGTH_ERROR },
  },
];

export const BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS_VALUES = {
  YES: 'true',
  NO: 'false',
  N_A: 'n/a',
};

export const BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS = [
  { id: 'yes', label: 'Yes', value: BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS_VALUES.YES },
  { id: 'no', label: 'No', value: BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS_VALUES.NO },
  { id: 'n/a', label: 'N/A', value: BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS_VALUES.N_A },
];

export const BUSINESS_REVIEW_RADIO_BUTTONS_WITH_NOTES_FIELDS: {
  radioInput: CompanyIacRadioInputSetField;
  textAreaInput: CompanyIacTextAreaNotesField;
}[] = [
  {
    radioInput: {
      name: BusinessReviewRadioFieldNames.EXTERNALS_USED_FOR_DUE_DILIGENCE,
      radioLegend: 'External parties used for technical / financial / legal diligence (if any)',
      radioOptions: BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS,
      fieldSetClassName: 'mb-4',
    },
    textAreaInput: {
      name: BusinessReviewRadioFieldNames.EXTERNALS_USED_FOR_DUE_DILIGENCE_NOTES,
      placeholder: 'Notes',
      validation: { required: REQUIRED_MESSAGE, maxLength: TEXT_AREA_MAX_LENGTH_ERROR },
    },
  },
  {
    radioInput: {
      name: BusinessReviewRadioFieldNames.DD_RED_FLAGS,
      radioLegend: 'Due diligence red flags to note*',
      radioOptions: BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS,
      fieldSetClassName: 'mb-4',
      validation: { required: REQUIRED_MESSAGE },
    },
    textAreaInput: {
      name: BusinessReviewRadioFieldNames.DD_RED_FLAGS_NOTES,
      placeholder: 'Notes',
      validation: { required: REQUIRED_MESSAGE, maxLength: TEXT_AREA_MAX_LENGTH_ERROR },
    },
  },
  {
    radioInput: {
      name: BusinessReviewRadioFieldNames.REGULATORY_CHECKS,
      radioLegend: 'Have regulatory checks been undertaken? If so, any matters of note?',
      radioOptions: BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS,
      fieldSetClassName: 'mb-4',
    },
    textAreaInput: {
      name: BusinessReviewRadioFieldNames.REGULATORY_CHECKS_NOTES,
      placeholder: 'Notes',
      validation: { required: REQUIRED_MESSAGE, maxLength: TEXT_AREA_MAX_LENGTH_ERROR },
    },
  },
  {
    radioInput: {
      name: BusinessReviewRadioFieldNames.OTHER_MATTERS,
      radioLegend: 'Other matters of note?',
      radioOptions: BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS,
      fieldSetClassName: 'mb-4',
    },
    textAreaInput: {
      name: BusinessReviewRadioFieldNames.OTHER_MATTERS_NOTES,
      placeholder: 'Notes',
      validation: { required: REQUIRED_MESSAGE, maxLength: TEXT_AREA_MAX_LENGTH_ERROR },
    },
  },
  {
    radioInput: {
      name: BusinessReviewRadioFieldNames.CONFLICTS_OF_INTEREST,
      radioLegend: BusinessReviewRadioConflictsOfInterestsLegend,
      radioOptions: BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS,
      fieldSetClassName: 'mb-4',
      validation: { required: REQUIRED_MESSAGE },
    },
    textAreaInput: {
      name: BusinessReviewRadioFieldNames.CONFLICTS_OF_INTEREST_NOTES,
      placeholder: 'Notes',
      validation: { required: REQUIRED_MESSAGE, maxLength: TEXT_AREA_MAX_LENGTH_ERROR },
    },
  },
];

export const CUSTOM_FIELDS_FORM_FIELDS: FormItem[] = [
  {
    name: CustomFieldsFieldNames.LABEL,
    label: 'Input Label Name',
    placeholder: 'Label Name',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: {
      required: REQUIRED_MESSAGE,
      maxLength: { value: 100, message: 'Must not be greater than 100 characters.' },
    },
    className: 'w-full sm:w-4/12 mb-0',
  },
  {
    name: CustomFieldsFieldNames.VALUE,
    label: 'Input Value',
    placeholder: 'Input Value',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: {
      required: REQUIRED_MESSAGE,
      maxLength: { value: 1500, message: 'Must not be greater than 1500 characters.' },
    },
    className: 'w-full sm:w-7/12 mb-0',
  },
];

export enum CHOOSE_FUND_MODAL_FORM_FIELDS {
  FUND = 'fund',
}

export const COMPANY_IAC_CHOOSE_FUND_MODAL_TITLE = 'Choose a fund for %COMPANY_NAME% IAC Investment Proposal';

export const COMPANY_IAC_INVITING_FUND_MODAL_TITLE = 'Invite Fund Manager Users';

export const COMPANY_IAC_INVITING_FUND_ADD_NEW_TITLE = 'Add new FM User';

export const COMPANY_IAC_INVITING_FUND_ADD_NEW_FIELDS: FormItem[] = [
  {
    name: InvitingFundAddNewFieldNames.FIRST_NAME,
    label: 'First Name',
    placeholder: 'First Name',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    validation: {
      required: 'Required information missing please input First Name to continue',
    },
  },
  {
    name: InvitingFundAddNewFieldNames.LAST_NAME,
    label: 'Last Name',
    placeholder: 'Last Name',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    validation: {
      required: 'Required information missing please input Last Name to continue',
    },
  },
  {
    name: InvitingFundAddNewFieldNames.EMAIL,
    label: 'Email Address',
    placeholder: 'Email Address',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    validation: {
      required: 'Required information missing please input Email to continue',
      pattern: { message: 'Invalid Email Address', value: EMAIL_PATTERN },
    },
  },
  {
    name: InvitingFundAddNewFieldNames.ADD_ANOTHER,
    type: FieldTypes.CHECKBOX,
    component: Checkbox,
    label: 'Add Another User',
    className: 'flex items-center [&_input]:top-0',
  },
];

export const COMPANY_IAC_WHOLE_FORM_ERROR_NOTIFICATION = 'Please submit the required fields';

export const COMPANY_IAC_DECLINE_MODAL_TITLE = 'Decline this IAC Investment Proposal';

export const COMPANY_IAC_DECLINE_MODAL_DESCRIPTION =
  'Please provide the reason for declining this IAC Investment Proposal and what needs to be amended if that is the case.';

export const COMPANY_IAC_DECLINE_MODAL_FIELDS: FormItem[] = [
  {
    name: CompanyIacDeclineFieldNames.REASON,
    label: 'Reason for Decline',
    placeholder: 'Enter',
    type: FieldTypes.TEXT,
    component: Input,
    className: 'mb-0',
    validation: {
      required: 'Required information missing please input Reason to continue',
      maxLength: { message: 'The reason length should be less than 50 characters.', value: 50 },
    },
  },
];

export const COMPANY_IAC_FM_USER_CONFIRM_MODAL_TITLE = 'Confirm IAC Investment Proposal';

export const COMPANY_IAC_FM_USER_CONFIRM_MODAL_DESCRIPTION = 'Are you sure you want to confirm Investment proposal for';

export const COMPANY_IAC_STOP_MODAL_TITLE = 'Stop Share Exchange Request';

export enum CompanyIacStopReasons {
  COMPANY_APPROVAL_NOT_GRANTED = 'company_approval_not_granted',
  DEAL_LOST_ON_PRICING = 'deal_lost_on_pricing',
  LEGAL_RISK_ISSUES = 'legal_risk_issues',
  COMPETITOR_BID = 'competitor_bid',
  M_A_PROCESSING_COMMENCED = 'm_a_processing_commenced',
  LACK_OF_CASH_RUNWAY = 'lack_of_cash_runway',
  KYC_AML_FAILURE = 'kyc_aml_failure',
  OTHER = 'other',
}

const CompanyIacOptions: Option[] = [
  { label: 'Company Approval Not Granted', value: CompanyIacStopReasons.COMPANY_APPROVAL_NOT_GRANTED },
  { label: 'Deal Lost on Pricing', value: CompanyIacStopReasons.DEAL_LOST_ON_PRICING },
  { label: 'Legal/Risk Issues', value: CompanyIacStopReasons.LEGAL_RISK_ISSUES },
  { label: 'Competitor Bid', value: CompanyIacStopReasons.COMPETITOR_BID },
  { label: 'M&A Processing Commenced', value: CompanyIacStopReasons.M_A_PROCESSING_COMMENCED },
  { label: 'Lack of Cash Runway', value: CompanyIacStopReasons.LACK_OF_CASH_RUNWAY },
  { label: 'KYC/AML Failure', value: CompanyIacStopReasons.KYC_AML_FAILURE },
  { label: 'Other', value: CompanyIacStopReasons.OTHER },
];

export const COMPANY_IAC_STOP_MODAL_CORE_FIELDS: FormItem[] = [
  {
    name: CompanyIacStopFieldNames.REASON_SELECT,
    label: 'Please enter the decline reason of share exchange at this stage',
    type: FieldTypes.SELECT,
    component: SelectFormInput,
    options: CompanyIacOptions,
    className: 'mb-4',
    customCompanySelectStyles: {
      menuList: (style: CSSObjectWithLabel) => ({ ...style, maxHeight: '120px' }),
    },
    validation: {
      required: 'Required information missing please input Reason to continue',
    },
  },
];

export const COMPANY_IAC_STOP_MODAL_OPTIONAL_FIELDS: FormItem[] = [
  {
    name: CompanyIacStopFieldNames.REASON_TEXT,
    label: 'Please add your Reason',
    placeholder: 'Reason',
    type: FieldTypes.TEXT,
    component: Input,
    className: 'mb-0',
    validation: {
      required: 'Required information missing please input Reason to continue',
      maxLength: { message: 'The Reason length should be less than 50 characters.', value: 50 },
    },
  },
];

export const companyIacStatusesColoredLabel: {
  [key in COMPANY_IAC_STATUSES]: { children: string; variant: ColoredLabelVariants };
} = {
  [COMPANY_IAC_STATUSES.STARTED]: { children: 'Started', variant: ColoredLabelVariants.PENDING },
  [COMPANY_IAC_STATUSES.STOPPED]: { children: 'Stopped', variant: ColoredLabelVariants.ERROR },
  [COMPANY_IAC_STATUSES.DECLINED_BY_FMUSERS]: { children: 'Declined by FM User', variant: ColoredLabelVariants.ERROR },
  [COMPANY_IAC_STATUSES.APPROVED_BY_FMUSERS]: {
    children: 'Approved by FM User',
    variant: ColoredLabelVariants.SUCCESS,
  },
  [COMPANY_IAC_STATUSES.REVIEWING_BY_FMUSERS]: {
    children: 'Reviewing by FM User',
    variant: ColoredLabelVariants.PENDING,
  },
  [COMPANY_IAC_STATUSES.CONFIRMED_BY_ADMIN]: { children: 'Confirmed by Admin', variant: ColoredLabelVariants.SUCCESS },
  [COMPANY_IAC_STATUSES.ARCHIVED]: { children: 'Archived', variant: ColoredLabelVariants.PENDING },
};
export const COMPANY_IAC_DEAL_PARAMETERS_FORM_FIELDS: FormItem[] = [
  {
    name: CompanyIacStartDealFieldNames.DAXIA_SHARE_PRICE,
    label: 'Daxia share price',
    placeholder: 'Daxia share price',
    type: FieldTypes.TEXT,
    component: Input,
    symbol: '£',
    disabled: true,
    className: COMMERCIAL_PARAMETERS_INPUT_CLASSNAME,
  },
  {
    name: CompanyIacStartDealFieldNames.FEE_RATE,
    label: 'Fee',
    placeholder: 'Fee',
    type: FieldTypes.TEXT,
    component: Input,
    validation: {
      required: REQUIRED_MESSAGE,
      pattern: {
        value: POSITIVE_NUMBER_FLOAT_PATTERN,
        message: ENTER_NUMBER_MESSAGE,
      },
    },
    symbol: '%',
    className: COMMERCIAL_PARAMETERS_INPUT_CLASSNAME,
  },
  {
    name: CompanyIacStartDealFieldNames.FEE_MINIMUM,
    label: 'Minimum Fee',
    placeholder: 'Minimum Fee',
    type: FieldTypes.TEXT,
    component: Input,
    validation: {
      required: REQUIRED_MESSAGE,
      pattern: {
        value: POSITIVE_NUMBER_FLOAT_PATTERN,
        message: ENTER_NUMBER_MESSAGE,
      },
    },
    symbol: '£',
    className: COMMERCIAL_PARAMETERS_INPUT_CLASSNAME,
  },
];
