import React, { FocusEventHandler, PropsWithChildren, ReactElement } from 'react';

import cn from 'classnames';
import { debounce } from 'lodash';
import { Control, Controller, Path } from 'react-hook-form';
import { StylesConfig, SelectComponentsConfig, GroupBase, OptionsOrGroups } from 'react-select';
import Select from 'react-select/async';

import { REQUIRED_MESSAGE } from 'constants/global';
import { GET_INVESTORS_FILTER } from 'constants/users';
import { useAppDispatch } from 'modules/store';
import { getUsers } from 'modules/users/action';
import { ErrorMessage, FormLabel } from 'shared-components/index';
import { formatUsersToOptions } from 'utils';

interface Props<T> {
  control: Control<T>;
  name: string;
  label: string;
  defaultOptions?: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined;
  className?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  disabled?: boolean;
  selectStyles?: StylesConfig;
  components?: SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>;
  onClearCallback?: VoidFunction;
}

const UserSelectField = <T,>({
  control,
  className,
  name,
  label,
  onBlur,
  placeholder,
  disabled,
  selectStyles,
  components,
  defaultOptions,
  onClearCallback,
}: PropsWithChildren<Props<T>>): ReactElement => {
  const dispatch = useAppDispatch();

  const onSearchUsers = (searchString: string, callback: CallableFunction) => {
    if (searchString?.length <= 4) return callback([]);

    dispatch(getUsers({ query: searchString, filter: GET_INVESTORS_FILTER }))
      .unwrap()
      .then(({ data, meta }) => {
        const formattedOptions = formatUsersToOptions(data);

        const results =
          meta.total > 20
            ? [
                {
                  value: '',
                  label: `20 result from ${meta.total}. Please refine your search query`,
                  isDisabled: true,
                },
                ...formattedOptions,
              ]
            : formattedOptions;

        callback(results);
      });
    return null;
  };

  return (
    <Controller
      name={name as Path<T>}
      control={control}
      rules={{ required: REQUIRED_MESSAGE }}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => (
        <div className={className}>
          {label && <FormLabel>{label}</FormLabel>}
          <Select
            {...field}
            onBlur={onBlur}
            onChange={(selectedOption, triggeredAction) => {
              if (triggeredAction.action === 'clear' && onClearCallback) {
                onClearCallback();
              }

              onChange(selectedOption);
            }}
            isDisabled={disabled}
            noOptionsMessage={() => 'No Users'}
            className={cn('select-input', { 'select-input-error': error?.message })}
            defaultOptions={defaultOptions}
            loadOptions={debounce(onSearchUsers, 2000)}
            placeholder={placeholder || 'User'}
            styles={selectStyles}
            components={components}
            isClearable
          />
          {error && (
            <ErrorMessage className='absolute text-xs pl-[5px] pt-[5px] leading-[14px]' error={error?.message} />
          )}
        </div>
      )}
    />
  );
};

export default UserSelectField;
