import React, { ChangeEvent, PropsWithChildren, ReactElement, useEffect } from 'react';

import cn from 'classnames';
import {
  FieldErrors,
  UseFormRegister,
  Path,
  UseFormWatch,
  PathValue,
  UseFormSetValue,
  UseFormUnregister,
} from 'react-hook-form';

import { BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS_VALUES } from 'constants/company-iac';
import { CompanyIacRadioInputSetField, CompanyIacTextAreaNotesField } from 'interfaces';
import { RadioInputSet, TextArea } from 'shared-components';

interface Props<T> {
  isDisabled?: boolean;
  register: UseFormRegister<T>;
  unregister: UseFormUnregister<T>;
  setValue: UseFormSetValue<T>;
  errors: FieldErrors<T>;
  watch: UseFormWatch<T>;
  radioInputProps: CompanyIacRadioInputSetField;
  textAreaProps: CompanyIacTextAreaNotesField;
  onChangeNotes?: (event: ChangeEvent<HTMLTextAreaElement>) => Promise<void>;
  onChangeRadioInput?: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
}

const CompanyIacRadioInputWithNotes = <T,>({
  isDisabled,
  register,
  unregister,
  onChangeRadioInput,
  onChangeNotes,
  setValue,
  errors,
  watch,
  radioInputProps: { validation: radioInputValidation, ...radioInputProps },
  textAreaProps: { validation: textAreaValidation, ...textAreaProps },
}: PropsWithChildren<Props<T>>): ReactElement => {
  const watchTextAreaValue = watch(textAreaProps.name as Path<T>);
  const watchRadioInputValue = watch(radioInputProps.name as Path<T>);
  const radioInputError = errors[radioInputProps.name as Path<T>]?.message;
  const textAreaError = errors[textAreaProps.name as Path<T>]?.message;

  const validateInputWithNotes = (value: PathValue<T, Path<T>>) => {
    if (!value && radioInputValidation?.required) {
      return radioInputValidation.required as string;
    }

    return true;
  };

  useEffect(() => {
    if (watchTextAreaValue === undefined) {
      return;
    }

    if (watchRadioInputValue && watchRadioInputValue !== BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS_VALUES.YES) {
      unregister(textAreaProps.name as Path<T>);
      setValue(textAreaProps.name as Path<T>, null as PathValue<T, Path<T>>);
    }
  }, [setValue, unregister, watchRadioInputValue, watchTextAreaValue, textAreaProps.name]);

  return (
    <div className='mb-6'>
      <RadioInputSet
        disabled={isDisabled}
        error={radioInputError as string}
        {...register(radioInputProps.name as Path<T>, {
          onChange: onChangeRadioInput,
          validate: validateInputWithNotes,
        })}
        {...radioInputProps}
      />

      {watchRadioInputValue && watchRadioInputValue === BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS_VALUES.YES && (
        <TextArea
          disabled={isDisabled}
          error={textAreaError as string}
          {...textAreaProps}
          {...register(textAreaProps.name as Path<T>, {
            ...textAreaValidation,
            onChange: onChangeNotes,
          })}
          className={cn('mb-5', { 'disabled-light-grey': isDisabled })}
        />
      )}
    </div>
  );
};

export default CompanyIacRadioInputWithNotes;
