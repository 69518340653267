import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components/index';

export interface Props {
  onSubmit: VoidFunction;
  onBack?: VoidFunction;
  hideBackButton?: boolean;
  submitButtonText?: string;
  backButtonText?: string;
  isLoading?: boolean;
  className?: string;
  submitButtonVariant?: ButtonVariants;
  skipButton?: boolean;
  skipButtonText?: boolean;
  skipButtonLink?: string;
  skipButtonVariant?: ButtonVariants;
  backButtonVariant?: ButtonVariants;
  disabledSubmitButton?: boolean;
  onSkip?: VoidFunction;
}

const ButtonsControl: FC<Props> = ({
  className,
  hideBackButton = false,
  onBack,
  onSubmit,
  submitButtonText,
  backButtonText,
  isLoading,
  submitButtonVariant,
  skipButton,
  skipButtonText,
  skipButtonLink,
  skipButtonVariant,
  disabledSubmitButton,
  backButtonVariant,
  onSkip,
}) => {
  const navigate = useNavigate();

  const handleSkip = () => {
    if (!skipButtonLink) return;

    if (onSkip) onSkip();

    navigate(skipButtonLink);
  };

  return (
    <div className={twMerge('flex justify-between gap-4 xs:gap-0 xs:mt-6', className)}>
      {!hideBackButton && (
        <Button
          variant={backButtonVariant || ButtonVariants.SECONDARY}
          className='w-full xs:w-[134px] xs:mr-4'
          onClick={onBack}
        >
          {backButtonText || 'Back'}
        </Button>
      )}

      <Button
        onClick={onSubmit}
        variant={submitButtonVariant}
        isLoading={isLoading}
        disabled={disabledSubmitButton}
        className='max-w-full xs:max-w-300'
      >
        {submitButtonText || 'Next'}
      </Button>

      {skipButton && skipButtonLink && (
        <Button
          onClick={handleSkip}
          variant={skipButtonVariant || ButtonVariants.SECONDARY}
          className='max-w-full xs:max-w-[290px] xs:ml-4'
        >
          {skipButtonText || 'Skip'}
        </Button>
      )}
    </div>
  );
};

export default ButtonsControl;
