import React, { FC } from 'react';

interface Props {
  text: string;
}

const NoInformationText: FC<Props> = ({ text }) => {
  return <p>No information is available as no {text}</p>;
};

export default NoInformationText;
