import { RegisterOptions } from 'react-hook-form';

import { FormItem } from 'interfaces';
import { PrivacyPolicyLabel } from 'page-components';
import { Input, Checkbox } from 'shared-components';
import PhoneField from 'shared-components/PhoneField';

import { EMAIL_PATTERN, PASSWORD_PATTERN } from './global';
import { FieldTypes } from './shared';

export enum SignUpFieldNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  WEBSITE = 'website',
  PHONE = 'phone',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'password_confirmation',
}

export enum SIGN_UP_FLOWS {
  CREATE_SHARE_EXCHANGE = 'cse',
}

export const SIGN_UP_PAGE_TITLE = 'Register';

export const SIGN_UP_PAGE_ADDITIONAL_TEXT = 'Already a member?';

export const SIGN_UP_PAGE_ADDITIONAL_LINK = 'Sign In now';

export const FINISH_REGISTRATION_PAGE_TITLE = 'Finish Registration';

export const getSignUpFields = (
  currentPassword: string,
  excessFields: Array<SignUpFieldNames | string> | undefined = [],
  disabledEmailInput = false,
  onBlurEmail?: VoidFunction,
): FormItem[] =>
  [
    {
      name: SignUpFieldNames.FIRST_NAME,
      label: 'First Name',
      type: FieldTypes.TEXT,
      required: true,
      component: Input,
      tabIndex: 1,
      validation: { required: 'Required information missing please input First name to continue' },
      className: 'row-start-1 row-end-2 col-start-1 col-end-2 mb-6',
    },
    {
      name: SignUpFieldNames.LAST_NAME,
      label: 'Last Name',
      type: FieldTypes.TEXT,
      required: true,
      component: Input,
      tabIndex: 2,
      validation: { required: 'Required information missing please input Last name to continue' },
      className: 'col-span-3 row-span-1 col-start-2 col-end-3 mb-6',
    },
    {
      name: SignUpFieldNames.EMAIL,
      label: 'Email Address',
      type: FieldTypes.TEXT,
      required: true,
      disabled: disabledEmailInput,
      component: Input,
      tabIndex: 3,
      validation: {
        required: 'Required information missing please input Email address to continue',
        pattern: { message: 'Invalid email address', value: EMAIL_PATTERN },
      },
      className: 'row-start-2 row-end-3 col-start-1 col-end-3 mb-6',
      onBlur: onBlurEmail,
    },
    {
      name: SignUpFieldNames.CONFIRM_PASSWORD,
      label: 'Confirm Password',
      type: FieldTypes.PASSWORD,
      component: Input,
      required: true,
      tabIndex: 5,
      validation: {
        required: 'Required information missing please input Confirmation Password to continue',
        validate: (confirmPassword: string) => currentPassword === confirmPassword || 'Passwords do not match',
      },
      className: 'row-start-5 row-end-6 col-start-1 col-end-3 mb-6',
    },
    {
      name: SignUpFieldNames.PHONE,
      type: FieldTypes.PHONE,
      component: PhoneField,
      tabIndex: 6,
      label: 'Phone Number',
      description: 'to keep you updated on your portfolio and the progress of any share exchange opportunities',
      className: 'row-start-7 row-end-8 2xl:row-start-6 2xl:row-end-7 col-start-1 col-end-3 mb-6',
      required: true,
      validation: {
        required: 'Required information missing please input Phone number to continue',
      },
    },
    {
      name: 'policy',
      label: PrivacyPolicyLabel,
      type: FieldTypes.CHECKBOX,
      component: Checkbox,
      validation: {
        required: 'You should agree to the Policy and Terms & conditions',
      },
      className: 'row-start-[8] row-end-9 2xl:row-start-7 2xl:row-end-8 col-start-1 col-end-3 mb-6',
    },
  ].filter(({ name }) => !excessFields.includes(name));

export const SIGN_UP_PASSWORD_VALIDATION: RegisterOptions = {
  required: 'Required information missing please input Password to continue',
  pattern: {
    message: 'Password should match all requirements',
    value: PASSWORD_PATTERN,
  },
};

export const SIGN_UP_PAGE_FORM_INITIAL = {
  [SignUpFieldNames.PHONE]: '+44',
  [SignUpFieldNames.WEBSITE]: 'https://',
};

export const SIGN_UP_INVITED_USER_EXCESS_FIELDS = [SignUpFieldNames.EMAIL];
