import React, { useEffect, useMemo } from 'react';

import qs from 'query-string';
import { SubmitHandler } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { LocalStorageKeys } from 'constants/global';
import { ROUTES } from 'constants/routes';
import {
  SIGN_UP_FLOWS,
  SIGN_UP_PAGE_ADDITIONAL_LINK,
  SIGN_UP_PAGE_ADDITIONAL_TEXT,
  SIGN_UP_PAGE_TITLE,
} from 'constants/sign-up';
import { UserTypes } from 'constants/user';
import { SignUpInput } from 'interfaces';
import { asyncSignUp, resetAuthInvites } from 'modules/auth/action';
import { selectIsActionLoading } from 'modules/auth/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AuthLayout, HeaderInfo, SignUpForm } from 'page-components';

const SignUp = () => {
  const dispatch = useAppDispatch();
  const isActionLoading = useAppSelector(selectIsActionLoading);
  const { search } = useLocation();

  const initialSignUpForm = useMemo(() => {
    const { email, flow } = qs.parse(search);

    if (email && flow && flow === SIGN_UP_FLOWS.CREATE_SHARE_EXCHANGE) {
      return { email: email as string };
    }

    return undefined;
  }, [search]);

  const disabledEmailInput = useMemo(() => !!initialSignUpForm, [initialSignUpForm]);

  const onSubmit: SubmitHandler<SignUpInput> = (data) => {
    const { user_type: urlParamsUserType, flow: urlParamsFlow, company_id: urlParamsCompanyId } = qs.parse(search);
    const { company_id } = qs.parse(search);

    const localStorageUserType = localStorage.getItem(LocalStorageKeys.USER_TYPE);

    if (!urlParamsUserType && !localStorageUserType) {
      return;
    }

    dispatch(
      asyncSignUp({
        ...data,
        companyId: company_id ? Number(company_id) : undefined,
        flowParams: { flow: urlParamsFlow as string | null, companyId: urlParamsCompanyId as string | null },
        userType: (localStorageUserType || urlParamsUserType) as UserTypes,
      }),
    );
  };

  useEffect(() => {
    dispatch(resetAuthInvites());
  }, [dispatch]);

  return (
    <AuthLayout>
      <HeaderInfo
        title={SIGN_UP_PAGE_TITLE}
        linkText={SIGN_UP_PAGE_ADDITIONAL_LINK}
        linkPath={`${ROUTES.signIn}${search}`}
        className='[&_h2]:pt-0'
      >
        {SIGN_UP_PAGE_ADDITIONAL_TEXT}
      </HeaderInfo>

      <SignUpForm
        isLoading={isActionLoading}
        onSubmit={onSubmit}
        formValues={initialSignUpForm}
        disabledEmailInput={disabledEmailInput}
      />
    </AuthLayout>
  );
};

export default SignUp;
