import React, { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { TypographyStyles, TypographyVariants } from 'constants/shared/typography';

export interface Props {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'label';
  variant?: TypographyVariants;
  className?: string;
  children: ReactNode | string;
}

const Typography = ({ tag = 'p', variant = TypographyVariants.BODY_NORMAL, className, children }: Props) => {
  const TypographyTag = tag;

  return <TypographyTag className={twMerge(TypographyStyles[variant], className)}>{children}</TypographyTag>;
};

export default Typography;
