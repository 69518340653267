import React, { FC } from 'react';

import cn from 'classnames';

import {
  COMPANY_RUNWAY_FORM_FIELDS,
  DUE_DILIGENCE_COMPANY_RUNWAY_REQUIRED_FIELDS,
  DueDiligenceSections,
  MONTHS_CASH_RUNWAY_BLOCK_TITLE,
  MONTHS_CASH_RUNWAY_FORM_FIELDS,
} from 'constants/due-diligence';
import { UseMonthsCashRunwayReturnValues } from 'hooks/useDueDiligenceMonthsCashRunway';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';
import { FormContent } from 'shared-components';
import { highlightDDFields } from 'utils/due-diligence';

import DueDiligenceBlock from '../due-diligence-block/DueDiligenceBlock';
import DueDiligenceSubBlock from '../DueDiligenceSubBlock';

const MonthsCashRunwayBlock: FC<
  UseMonthsCashRunwayReturnValues & {
    getUnfieldValue: getUnfieldValueFunc;
  }
> = ({ isEditing, handleSaveField, isLoading, handleDiscardChanges, control, register, errors, getUnfieldValue }) => {
  return (
    <DueDiligenceBlock
      blockNumber='4'
      descriptionClassName='text-grey-500'
      sectionType={DueDiligenceSections.MONTHS_CASH_RUNWAY_BLOCK}
      title={MONTHS_CASH_RUNWAY_BLOCK_TITLE}
      onClickDiscardChanges={handleDiscardChanges}
      isLoading={isLoading}
    >
      <form>
        <DueDiligenceSubBlock
          title='4.a. Cashflow Forecast'
          description='Please attach a copy of your latest cashflow forecast to the documents section. If no forecast is available,
          please detail an overview of your expected forecast below.'
        >
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[1fr_1fr_1fr] grid-rows-2 gap-x-6 mt-6'>
            <FormContent
              fields={highlightDDFields(
                COMPANY_RUNWAY_FORM_FIELDS,
                getUnfieldValue,
                DUE_DILIGENCE_COMPANY_RUNWAY_REQUIRED_FIELDS,
              )}
              control={control}
              register={register}
              errors={errors}
              onBlur={handleSaveField}
              isDisabled={!isEditing || isLoading}
            />
          </div>
        </DueDiligenceSubBlock>

        <DueDiligenceSubBlock
          title='4.b. Cash Runway'
          description='Please detail the number of months cash runway that the company currently has.
        If less than 12 months runway, please detail what steps are being taken to extend this'
        >
          <FormContent
            fields={highlightDDFields(MONTHS_CASH_RUNWAY_FORM_FIELDS, getUnfieldValue)}
            control={control}
            register={register}
            errors={errors}
            onBlur={handleSaveField}
            isDisabled={!isEditing || isLoading}
            className={cn({ 'disabled-light-grey': !isEditing || isLoading })}
          />
        </DueDiligenceSubBlock>
      </form>
    </DueDiligenceBlock>
  );
};

export default MonthsCashRunwayBlock;
