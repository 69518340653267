import React, { FC, SetStateAction, Dispatch, useCallback } from 'react';

import { ReactComponent as EditSvg } from 'assets/svg/pencil.svg';
import { ReactComponent as DeleteSvg } from 'assets/svg/trash-can.svg';
import { DAXIA_PATTERN, INVESTMENTS_DAXIA_PRICE_PER_SHARE_TOOLTIP_CONTENT } from 'constants/investments';
import {
  CompanyActiveShareExchange,
  CompanyWithUserShareExchangeStatus,
  HandleClickRequestShareExchangeType,
  Investment,
  InvestmentStatuses,
  investmentStatusesColoredLabel,
  InvestmentWithCompany,
} from 'interfaces';
import { ButtonsActions, ColoredLabel } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import TableDataWithTooltip from 'shared-components/table/TableDataWithTooltip';
import { formatNumberInputValue } from 'utils/shared';

import InvestmentsTableFormRow from '../investments-table/InvestmentsTableFormRow';
import MyInvestmentExchangeButton from './MyInvestmentsExchangeButton';
import MyInvestmentsNameTableDataContent from './MyInvestmentsNameTableDataContent';

type Props = {
  isEditing: boolean;
  isFirstElement: boolean;
  companyName?: string;
  companyId: number;
  companyNumber: string;
  setEditId: Dispatch<SetStateAction<string | null>>;
  disabledActions: boolean;
  companyActiveShareExchange: CompanyActiveShareExchange | null;
  setDeletingInvestment: (investment: InvestmentWithCompany | null) => void;
  handleClickRequestShareExchange: HandleClickRequestShareExchangeType;
  investment: InvestmentWithCompany;
  onSuccessEditInvestment: VoidFunction;
  declineReason: string;
  isActiveExchange: boolean;
  shareExchangeRequestId: number;
  serDeclined: boolean;
  draftDealId?: number;
} & CompanyWithUserShareExchangeStatus;

const MyCompaniesWithInvestmentsTableItem: FC<Props> = ({
  isEditing,
  isFirstElement,
  companyName,
  companyNumber,
  companyId,
  setEditId,
  disabledActions,
  companyActiveShareExchange,
  setDeletingInvestment,
  handleClickRequestShareExchange,
  investment,
  onSuccessEditInvestment,
  declineReason,
  isActiveExchange,
  shareExchangeRequestId,
  serDeclined,
  userCompanySEStatus,
  draftDealId,
}) => {
  const {
    status,
    investmentName,
    originalInvestmentPrice,
    totalShares,
    companyNumber: investmentCompanyNumber,
    isBlended,
    currentInvestmentValue,
    ...investmentData
  } = investment;

  const isDaxiaInvestment = investmentName?.includes(DAXIA_PATTERN);

  const getInvestmentButtonsActions = () => [
    {
      id: 'edit-investment',
      icon: EditSvg,
      action: () => {
        setEditId(investment.id);
      },
      disabled: disabledActions,
    },
    {
      id: 'delete-investment',
      icon: DeleteSvg,
      action: () => setDeletingInvestment({ ...investment, companyName }),
      disabled: disabledActions,
    },
  ];

  const investmentToEdit = { ...investmentData, companyName, companyNumber } as Investment;

  const onClickRequest = useCallback(() => {
    handleClickRequestShareExchange({
      companyId,
      userCompanySEStatus,
      shareExchangeRequestId,
      serDeclined,
      draftDealId,
    });
  }, [handleClickRequestShareExchange, companyId, userCompanySEStatus, shareExchangeRequestId, serDeclined]);

  return (
    <>
      <TableRow>
        <TableData className='pl-2 min-w-150'>
          {isFirstElement && !isDaxiaInvestment && (
            <MyInvestmentExchangeButton
              id={investment.id}
              companyId={companyId}
              onClickRequest={onClickRequest}
              userCompanySEStatus={userCompanySEStatus}
              companyActiveShareExchange={companyActiveShareExchange}
              declineReason={declineReason}
              serDeclined={serDeclined}
              isActiveExchange={isActiveExchange}
            />
          )}
        </TableData>
        <TableData>
          <MyInvestmentsNameTableDataContent
            isFirstElement={isFirstElement}
            investmentName={investmentName}
            companyName={companyName || ''}
            companyId={companyId}
            isDaxia={isDaxiaInvestment}
          />
        </TableData>
        <TableData>{isDaxiaInvestment ? investmentCompanyNumber : companyNumber}</TableData>
        <TableData>{investment.classOfShares}</TableData>
        <TableDataWithTooltip
          anchorId={`${investment.id}-pricePerShare`}
          tooltipContent={INVESTMENTS_DAXIA_PRICE_PER_SHARE_TOOLTIP_CONTENT}
          hidden={!isDaxiaInvestment || !isBlended}
        >
          £{investment.pricePerShare}
        </TableDataWithTooltip>
        <TableData>
          {status !== InvestmentStatuses.EXCHANGED ? `£${formatNumberInputValue(currentInvestmentValue, 4)}` : ''}
        </TableData>
        <TableData>{investment.investmentDate}</TableData>
        <TableData>{formatNumberInputValue(totalShares, 4)}</TableData>
        <TableData>{originalInvestmentPrice && `£${formatNumberInputValue(originalInvestmentPrice)}`}</TableData>
        <TableData>{status && <ColoredLabel {...investmentStatusesColoredLabel[status]} />}</TableData>
        <TableData>
          {!isDaxiaInvestment && (
            <ButtonsActions anchorPrefix={investment.id.toString()} actions={getInvestmentButtonsActions()} />
          )}
        </TableData>
      </TableRow>

      <InvestmentsTableFormRow
        colSpan={10}
        isShown={isEditing}
        investment={investmentToEdit}
        onCancel={() => setEditId(null)}
        onSuccessEdit={onSuccessEditInvestment}
        isEditMode
      />
    </>
  );
};

export default MyCompaniesWithInvestmentsTableItem;
