import React from 'react';

import { ConstraintVariants } from 'constants/shared';
import { TabItem } from 'interfaces';
import { DashboardLayout } from 'page-components';
import { BusinessSettingsTab } from 'page-components/business-settings';
import { Constraint, Tabs, TitleWithDescription } from 'shared-components';

const BUSINESS_SETTINGS_TABS: TabItem[] = [
  {
    id: 'business-settings',
    name: 'Business Settings',
    component: BusinessSettingsTab,
  },
];

const BusinessSettings = () => {
  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <TitleWithDescription className='mb-6' title='Business Settings' />
        <Tabs tabs={BUSINESS_SETTINGS_TABS} />
      </Constraint>
    </DashboardLayout>
  );
};

export default BusinessSettings;
