import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { useLocation, useParams } from 'react-router-dom';

import {
  DEAL_PARAMETERS_PREVIEW_AND_SEND_TOOLTIP,
  PREVIEW_AND_SEND_TOOLTIP_BUTTON_ID,
} from 'constants/deal-parameters';
import { dealParametersStatusesLabel } from 'constants/deals';
import { ConstraintVariants } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import { TypographyStyles } from 'constants/shared/typography';
import useModal from 'hooks/use-modal/useModal';
import { DealPreviewModalData } from 'interfaces';
import { getDealParametersById } from 'modules/deals/action';
import { selectDealParametersClosingDatesInfo, selectDealParametersMainInfo } from 'modules/deals/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DashboardLayout } from 'page-components';
import DealClosureOverviewEmailPreviewModal from 'page-components/deals/deal-closure-overview/DealClosureOverviewEmailPreviewModal';
import DealClosureOverviewModal from 'page-components/deals/deal-closure-overview/DealClosureOverviewModal';
import { DealParametersTab, ShareExchangesTab } from 'page-components/deals/deal-parameters';
import { ButtonWithTooltip, ColoredLabel, Constraint, DynamicPageTitle, LinkButton, Tabs } from 'shared-components';

import { getCompanyProfileLink } from '../utils';

const DEAL_PARAMETERS_TABS = [
  {
    id: 'deal-parameters',
    name: 'Deal Parameters',
    component: DealParametersTab,
  },
  {
    id: 'share-exchanges',
    name: 'Share Exchanges',
    component: ShareExchangesTab,
  },
];

const DealParameters = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const {
    onOpen: handleOpenClosureOverviewModal,
    onClose: handleCloseClosureOverviewModal,
    isOpen: isOpenClosureOverviewModal,
  } = useModal();

  const {
    onOpen: handleOpenClosureOverviewEmailPreviewModal,
    onClose: handleCloseClosureOverviewEmailPreviewModal,
    isOpen: isOpenClosureOverviewEmailPreviewModal,
  } = useModal();

  const {
    id: dealParametersId,
    companyId,
    companyName,
    companyNumber,
    status,
  } = useAppSelector(selectDealParametersMainInfo);
  const { closingDates, availableClosingDates } = useAppSelector(selectDealParametersClosingDatesInfo);

  const isShownSendDealClosureButton = location?.search?.includes('tab=deal-parameters');

  const [previewData, setPreviewData] = useState<DealPreviewModalData>({
    templates: [],
    founders: [],
  });
  const [selectedClosingDates, setSelectedClosingDates] = useState<string[]>([]);

  const goToPreviewStep = (data: DealPreviewModalData) => {
    setPreviewData(data);
    handleOpenClosureOverviewEmailPreviewModal();
  };

  const deleteSelectedClosingDates = () => {
    setSelectedClosingDates([]);
  };

  const handleBackToClosureOverviewModal = () => {
    handleCloseClosureOverviewEmailPreviewModal();
    handleOpenClosureOverviewModal();
  };

  useEffect(() => {
    if (id) dispatch(getDealParametersById(id));
  }, [dispatch, id]);

  return (
    <DashboardLayout>
      <DealClosureOverviewModal
        isOpen={isOpenClosureOverviewModal}
        onClose={handleCloseClosureOverviewModal}
        id={dealParametersId}
        availableClosingDates={availableClosingDates}
        closingDates={closingDates}
        selectedClosingDates={selectedClosingDates}
        setSelectedClosingDates={setSelectedClosingDates}
        goToNextStep={goToPreviewStep}
      />

      <DealClosureOverviewEmailPreviewModal
        id={Number(id)}
        isOpen={isOpenClosureOverviewEmailPreviewModal}
        onClose={handleCloseClosureOverviewEmailPreviewModal}
        handleBack={handleBackToClosureOverviewModal}
        previewData={previewData}
        selectedClosingDates={selectedClosingDates}
        deleteDates={deleteSelectedClosingDates}
      />

      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <div className='flex justify-between items-center mb-6 flex-col 2lg:flex-row'>
          <DynamicPageTitle
            isLoading={!companyName || String(dealParametersId) !== id}
            title={
              <>
                <LinkButton
                  className='mr-1 px-0 leading-50'
                  to={getCompanyProfileLink(companyId, true)}
                  variant={ButtonVariants.UNDERLINED_LINK}
                >
                  {companyName}
                </LinkButton>
                ({companyNumber}){status && <ColoredLabel className='ml-4' {...dealParametersStatusesLabel[status]} />}
              </>
            }
            dynamicTitle='Deal'
            className={cn(
              '[&>span]:font-bold [&>span]:mr-6 mb-6 flex items-center flex-col md:flex-row gap-2 md:gap-0',
              TypographyStyles.semi_large,
            )}
          />

          {isShownSendDealClosureButton && (
            <ButtonWithTooltip
              wrapperClassName='w-fit'
              tooltipClassName='!mt-[-10px]'
              tooltipContent={DEAL_PARAMETERS_PREVIEW_AND_SEND_TOOLTIP}
              anchorId={PREVIEW_AND_SEND_TOOLTIP_BUTTON_ID}
              disabled={!availableClosingDates?.length}
              onClick={handleOpenClosureOverviewModal}
            >
              Preview and Send
            </ButtonWithTooltip>
          )}
        </div>
        <Tabs tabs={DEAL_PARAMETERS_TABS} locationState={location?.state} componentProps={{ companyId }} />
      </Constraint>
    </DashboardLayout>
  );
};

export default DealParameters;
