import React, { FC } from 'react';

import { format, parseISO } from 'date-fns';
import { NavigateFunction } from 'react-router-dom';

import { ReactComponent as TrashCanSvg } from 'assets/svg/trash-can.svg';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { DEAL_STATUSES, dealStatusesLabel } from 'constants/deals';
import { ButtonVariants } from 'constants/shared/button';
import { Deal, DealHandleClickRequestShareExchangeType } from 'interfaces';
import { Button, ButtonsActions, ColoredLabel } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import { getDealDetailsLink, separateAndCapitalizeString } from 'utils';
import { checkIsDealNotCompleted } from 'utils/deals';

type Props = {
  deal: Deal;
  navigate: NavigateFunction;
  handleClickRequestShareExchange: DealHandleClickRequestShareExchangeType;
  handleOpenDeleteDealModal: (data: { id: number; companyId: number }) => void;
};

const DealsTableRow: FC<Props> = ({ deal, navigate, handleClickRequestShareExchange, handleOpenDeleteDealModal }) => {
  const handleNavigateToDetail = () => navigate(getDealDetailsLink(deal.id));

  const actions = [
    {
      id: 'delete',
      icon: TrashCanSvg,
      disabled: !checkIsDealNotCompleted(deal.stage, deal.status),
      action: () =>
        handleOpenDeleteDealModal({
          id: deal.id,
          companyId: deal.companyId,
        }),
    },
  ];

  return (
    <TableRow>
      <TableData>
        {deal.status === DEAL_STATUSES.PENDING ? (
          <Button
            className='w-[120px]'
            onClick={() => handleClickRequestShareExchange({ companyId: deal.companyId, draftDealId: deal.id })}
          >
            Exchange
          </Button>
        ) : (
          <Button variant={ButtonVariants.SECONDARY} className='w-[120px]' onClick={handleNavigateToDetail}>
            View
          </Button>
        )}
      </TableData>
      <TableData>{deal.companyName}</TableData>
      <TableData>{format(parseISO(deal.created_at), DAY_FORMAT_WITH_SLASHES)}</TableData>
      <TableData>{separateAndCapitalizeString(deal.stage)}</TableData>
      <TableData>
        <ColoredLabel {...dealStatusesLabel[deal.status]} />
      </TableData>
      <TableData>
        <ButtonsActions anchorPrefix='deals' actions={actions} />
      </TableData>
    </TableRow>
  );
};

export default DealsTableRow;
