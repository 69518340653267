import { FormItem } from 'interfaces';
import { Checkbox, Input } from 'shared-components';

import { EMAIL_PATTERN } from './global';
import { FieldTypes } from './shared';

export enum FounderInviteFieldNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL_ADDRESS = 'email',
}

export const INVESTORS_INVITE_PAGE_TITLE = 'Invite other investors to planD';

export const INVESTORS_INVITE_PAGE_DESCRIPTION =
  'If you know of any other investors who might like the opportunity to exit please invite them to join the planD platform here';

export const FOUNDER_INVITE_PAGE_TITLE = 'Invite the Founder/Director';

export const FOUNDER_INVITE_ADDITIONAL_TEXT =
  'Please provide the details of the Founder(s)/Director(s) if you are happy for us to contact them in order to carry out the ' +
  'necessary due diligence to qualify this deal.';

export const INVITES_PAGE_FIELDS: FormItem[] = [
  {
    name: FounderInviteFieldNames.FIRST_NAME,
    label: 'First Name',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    validation: {
      required: 'Required information missing please input First Name to continue',
    },
    className: 'col-span-full sm:col-span-2',
  },
  {
    name: FounderInviteFieldNames.LAST_NAME,
    label: 'Last Name',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    validation: {
      required: 'Required information missing please input Last Name to continue',
    },
    className: 'col-span-full sm:col-span-2',
  },
  {
    name: FounderInviteFieldNames.EMAIL_ADDRESS,
    label: 'Email Address',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    validation: {
      required: 'Required information missing please input Email to continue',
      pattern: { message: 'Invalid Email Address', value: EMAIL_PATTERN },
    },
    className: 'col-span-full',
  },
];

export const FOUNDER_INVITE_PAGE_FIELDS: FormItem[] = [
  ...INVITES_PAGE_FIELDS,
  {
    name: 'policy',
    label: 'I confirm that i am happy for planD to reach out to the founder on my behalf',
    type: FieldTypes.CHECKBOX,
    component: Checkbox,
    className: 'col-span-full mb-4',
    validation: {
      required: 'Required information missing',
    },
  },
];
