import React, { FC, FunctionComponent, ReactNode } from 'react';

import cn from 'classnames';
import { PlacesType, Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import useModal from 'hooks/use-modal/useModal';
import { MissedTooltipPlaces } from 'interfaces';

interface Props {
  icon: ReactNode;
  tooltipContent: string | ReactNode | FunctionComponent;
  anchorId: string;
  wrapperClassName?: string;
  tooltipClassName?: string;
  place?: PlacesType | MissedTooltipPlaces;
  offset?: number;
}

const IconWithTooltip: FC<Props> = ({
  icon,
  wrapperClassName,
  tooltipClassName,
  tooltipContent,
  anchorId,
  place,
  offset,
}) => {
  const { isOpen, onOpen, onClose } = useModal();

  return (
    <>
      <div id={anchorId} onMouseEnter={onOpen} onMouseLeave={onClose} className={cn('relative', wrapperClassName)}>
        {icon}
      </div>

      <Tooltip
        place={place as any}
        anchorId={anchorId}
        isOpen={isOpen}
        offset={offset || -10}
        clickable
        noArrow
        className={twMerge('text-sm text-grey-500 z-50 overflow-hidden bg-white', tooltipClassName)}
      >
        {tooltipContent}
      </Tooltip>
    </>
  );
};

export default IconWithTooltip;
