import React from 'react';

const DealDetailsReadyToCompleteTooltipContent = () => {
  return (
    <p>
      Thank you for completing the planD process, there is nothing further for you to action.
      <br />
      Upon deal closure, we will finalise the transaction and will be able to issue your investment confirmation letter
    </p>
  );
};

export default DealDetailsReadyToCompleteTooltipContent;
