import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import {
  Company,
  CompanyData,
  CompanyResponse,
  GetListPayload,
  CompaniesResponse,
  CompaniesMetrics,
  CompanyIacDeal,
  CreateDealParametersPayload,
  CompanyInvestor,
  AddNewCompanyInvestorFormValues,
  CompanyInvestorRequestsPayload,
  ValidatedCompanyInvestor,
  InvitePreviewTemplate,
  SendInviteInvestorEmailPayload,
  ShortCompanyData,
  CompanyDataForInvestments,
} from 'interfaces';
import { stringifyFilterParams } from 'utils';

export class CompaniesApis {
  constructor(private url: string) {}

  getCompany(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CompanyResponse>(`${this.url}/${id}`, config);
  }

  getCompanyName(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: { companyName: string } }>(`${this.url}/${id}/company-name`, config);
  }

  getMyCompanies(params: GetListPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<CompaniesResponse>(`${this.url}?${stringifyFilterParams(params)}`, config);
  }

  getCompanyProfile(companyNumber: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CompanyData>(`${this.url}/${companyNumber}`, config);
  }

  getCompanies(params: GetListPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<CompaniesResponse>(`${this.url}?${stringifyFilterParams(params)}`, config);
  }

  addCompany(data: CompanyData, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: Company }>(`${this.url}/add-company`, data, config);
  }

  deleteCompany(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }

  getAdminCompaniesMetrics(config?: AxiosRequestConfig) {
    return httpApiClient.get<CompaniesMetrics>(`${this.url}/company`, config);
  }

  getDealParameters(companyId: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: CompanyIacDeal }>(`${this.url}/${companyId}/deal-parameters`, config);
  }

  createDealParameters({ id, ...payload }: CreateDealParametersPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post(`${this.url}/${id}/deal-parameters`, payload, config);
  }

  setCompanyWhiteLis({ id, isWhitelisted }: { id: number; isWhitelisted: boolean }, config?: AxiosRequestConfig) {
    return httpApiClient.patch<CompanyData>(`${this.url}/${id}/whitelist`, { isWhitelisted }, config);
  }

  getInvitedCompanyInvestors(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: CompanyInvestor[] }>(`${this.url}/${id}/shareholders`, config);
  }

  createCompanyInvestorForInvite(
    { id, investor }: { id: string; investor: AddNewCompanyInvestorFormValues },
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.post<{ data: CompanyInvestor }>(`${this.url}/${id}/shareholders`, investor, config);
  }

  deleteInvitedCompanyInvestor({ id, investorId }: CompanyInvestorRequestsPayload, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}/shareholders/${investorId}`, config);
  }

  validateInvitedCompanyInvestor({ id, investorId }: CompanyInvestorRequestsPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<ValidatedCompanyInvestor>(
      `${this.url}/${id}/shareholders/${investorId}/validate`,
      config,
    );
  }

  uploadCompanyInvestors({ id, data }: { id: string; data: FormData }, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: Array<CompanyInvestor> }>(
      `${this.url}/${id}/shareholders/batch-upload`,
      data,
      config,
    );
  }

  getCompaniesInviteEmailTemplate(companyId: string, config?: AxiosRequestConfig) {
    return httpApiClient.post<InvitePreviewTemplate>(`${this.url}/${companyId}/shareholders/invite-template`, config);
  }

  sendInviteInvestorEmail({ companyId, id, content }: SendInviteInvestorEmailPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<InvitePreviewTemplate>(
      `${this.url}/${companyId}/shareholders/${id}/invite`,
      { content },
      config,
    );
  }

  getShortCompanyInfo(companyId: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: ShortCompanyData }>(`${this.url}/${companyId}/short`, config);
  }

  getCompanyDataForInvestments(companyNumber: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CompanyDataForInvestments>(`${this.url}/${companyNumber}/data-for-investments`, config);
  }
}

export const companiesApis = new CompaniesApis('/companies');
export const founderCompaniesApis = new CompaniesApis('/founders/companies');
export const adminCompaniesApis = new CompaniesApis('/admin/companies');
export const investorsCompaniesApis = new CompaniesApis('/investors/companies');
export const foundersApis = new CompaniesApis('/founders');
export const externalCompaniesApis = new CompaniesApis('/external/company-house/companies');
export const adminCompaniesMetricsApis = new CompaniesApis('/admin/metrics');
export const fmUserApis = new CompaniesApis('/fmusers/companies');
export const adminApis = new CompaniesApis('/admin');
