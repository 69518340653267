import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';
import { formatFloatNumbers } from 'utils/global/global';
import { groupAndConvertInvestments } from 'utils/investments';

const selectState = (x: RootState) => x.investmentsReducer;

export const selectAllInvestments = createSelector(selectState, (state) =>
  groupAndConvertInvestments(state.allInvestments),
);

export const selectCompaniesWithInvestments = createSelector(selectState, (state) =>
  state.companiesWithInvestments.map(({ investments, ...company }) => {
    const investmentsWithFormattedNumbers = investments.map(({ amountInvested, pricePerShare, ...investment }) => ({
      amountInvested: formatFloatNumbers(amountInvested),
      pricePerShare: formatFloatNumbers(pricePerShare),
      ...investment,
    }));

    return { ...company, investments: investmentsWithFormattedNumbers };
  }),
);

export const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

export const selectIsActionLoading = createSelector(selectState, (state) => state.isActionLoading);

export const selectAllInvestmentsPaginationData = createSelector(selectState, ({ totalPages, total }) => ({
  totalPages,
  total,
}));

export const selectCompaniesGraph = createSelector(selectState, ({ companiesGraph }) => companiesGraph);

export const selectIndustriesGraph = createSelector(selectState, ({ industriesGraph }) => industriesGraph);

export const selectLinkedInInvestments = createSelector(selectState, (state) => state.linkedInInvestments);

export const selectReviewInvestments = createSelector(selectState, (state) =>
  state.reviewInvestments.map(({ amountInvested, pricePerShare, ...investment }) => ({
    amountInvested: formatFloatNumbers(amountInvested),
    pricePerShare: formatFloatNumbers(pricePerShare),
    ...investment,
  })),
);

export const selectQualificationQuestions = createSelector(selectState, (state) => state.qualificationQuestions);
