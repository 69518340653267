import React, { FC, ReactNode, useMemo } from 'react';

import { DOCUMENTS_TABLE_COLUMNS, INVESTOR_DOCUMENTS_TABLE_COLUMNS } from 'constants/documents';
import useModal from 'hooks/use-modal/useModal';
import { UpdateSortingFunctionType } from 'interfaces';
import { selectIsLoadingTableDocuments, selectTableDocuments } from 'modules/documents/selectors';
import { useAppSelector } from 'modules/store';
import DocumentsViewer from 'shared-components/documents-viewer/DocumentsViewer';
import Table, { TableSkeleton } from 'shared-components/table';
import { getPreviewDocumentsData } from 'utils/documents';

import { checkIsInvestor } from '../../utils';
import LocalDocumentsTableRow from './LocalDocumentsTableRow';
import NoDocumentsAdded from './NoDocumentsAdded';

type Props = {
  itemsPerPage: number;
  children?: ReactNode;
  updateSorting: UpdateSortingFunctionType;
};

const LocalDocumentsTable: FC<Props> = ({ itemsPerPage, children, updateSorting }) => {
  const isInvestor = useMemo(() => checkIsInvestor(), []);

  const fileDocuments = useAppSelector(selectTableDocuments);
  const loading = useAppSelector(selectIsLoadingTableDocuments);
  const {
    isOpen: isPreviewModalOpen,
    onOpen: handleOpenPreviewModal,
    onClose: handleClosePreviewModal,
    modalProps: previewModalProps,
  } = useModal();

  const [previewDocuments, isDocumentsInvalid] = getPreviewDocumentsData(previewModalProps?.filePath);

  const columns = !isInvestor ? DOCUMENTS_TABLE_COLUMNS : INVESTOR_DOCUMENTS_TABLE_COLUMNS;

  return (
    <>
      <DocumentsViewer
        isOpen={isPreviewModalOpen}
        onClose={handleClosePreviewModal}
        documents={previewDocuments}
        shownError={isDocumentsInvalid}
      />
      <div className='flex flex-col'>
        <Table onUpdateSorting={updateSorting} columns={columns}>
          {loading ? (
            <TableSkeleton rowsNumber={itemsPerPage} columnsNumber={columns.length} />
          ) : fileDocuments?.length ? (
            fileDocuments.map((fileDocument) => (
              <LocalDocumentsTableRow
                key={fileDocument.id}
                fileDocument={fileDocument}
                handleOpenPreviewDocumentsModal={handleOpenPreviewModal}
              />
            ))
          ) : (
            <NoDocumentsAdded />
          )}
        </Table>
        {children}
      </div>
    </>
  );
};

export default LocalDocumentsTable;
