import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import {
  CurrentUser,
  InvitedUser,
  UpdateCurrentUserData,
  UserIntegration,
  UserWithTokenPayload,
  MyInvitesPayload,
  GenerateDueDiligenceInviteFounderLinkPayload,
} from 'interfaces';

export class CurrentUserApi {
  constructor(private url: string) {}

  userLogOut(config?: AxiosRequestConfig) {
    return httpApiClient.post<unknown>(`${this.url}/logout`, config);
  }

  getUserProfile(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: CurrentUser }>(`${this.url}/profile`, config);
  }

  getUserInfoByHash(hash: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<UserWithTokenPayload>(`${this.url}/users/info/${hash}`, config);
  }

  updateUser(data: UpdateCurrentUserData, config?: AxiosRequestConfig) {
    return httpApiClient.patch(`${this.url}/update`, data, config);
  }

  deleteUserInformation(config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/delete-data`, config);
  }

  updateLinkedInIntegration(data: UserIntegration, config?: AxiosRequestConfig) {
    return httpApiClient.patch(`${this.url}/update-integrations`, data, config);
  }

  inviteUser(data: Omit<MyInvitesPayload, 'userType'>, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: InvitedUser }>(`${this.url}/user-invites/send`, data, config);
  }

  getInviteLinkByCompanyId(companyId: number, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: { inviteLink: string } }>(
      `${this.url}/user-invites/links`,
      { companyId },
      config,
    );
  }

  getInvites(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: InvitedUser[] }>(`${this.url}/user-invites`, config);
  }

  deletePendingInvite(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/user-invites/${id}`, config);
  }

  generateDueDiligenceInviteFounderLink(
    data: GenerateDueDiligenceInviteFounderLinkPayload,
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.post<{ data: { inviteLink: string } }>(
      `${this.url}/user-invites/founders/dd-links`,
      data,
      config,
    );
  }

  checkIsAlreadyRegisteredByEmail(email: string, config?: AxiosRequestConfig) {
    return httpApiClient.get(`${this.url}/users/exists/${email}`, config);
  }
}

export const currentUserApi = new CurrentUserApi('');
export const currentUserProfileApi = new CurrentUserApi('/profile');
export const currentUserLinkedinApi = new CurrentUserApi('/external/linkedin');
