import React, { FC, useEffect, useMemo, useState } from 'react';

import { ADMIN_COMPANIES_TABLE_COLUMNS } from 'constants/companies';
import useFilterParameters from 'hooks/use-filter-parameters/useFilterParameters';
import useModal from 'hooks/use-modal/useModal';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { getAdminCompanies } from 'modules/companies/action';
import { selectCompaniesData, selectCompanyDataIsCalled } from 'modules/companies/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Pagination, SearchForm } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';
import { checkIsAdmin } from 'utils';

import { AddCompanyModal } from '../index';
import AdminCompaniesTableRow from './AdminCompaniesTableRow';
import AdminDeleteCompanyModal from './AdminDeleteCompanyModal';

const COMPANIES_PER_PAGE = 7;

type Props = {
  onClickCompanyIac: (companyId: number, companyName: string) => void;
};

const CompaniesTable: FC<Props> = ({ onClickCompanyIac }) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(null);

  const { isOpen, onClose, onOpen } = useModal();

  const dispatch = useAppDispatch();

  const { page, setPage, query, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();
  const { filter } = useFilterParameters();

  const { list: companies, isLoading, ...companiesPaginationData } = useAppSelector(selectCompaniesData);
  const isCompanyDeleted = useAppSelector(selectCompanyDataIsCalled);

  const isAdmin = useMemo(() => {
    return checkIsAdmin();
  }, []);

  const handleDeleteAction = (companyId: number) => {
    setSelectedCompanyId(companyId);
  };

  useEffect(() => {
    dispatch(
      getAdminCompanies({
        page,
        per_page: COMPANIES_PER_PAGE,
        query,
        order: sorting.order,
        sort: sorting.column,
        filter,
        isAdmin,
      }),
    );
  }, [dispatch, page, query, sorting.order, sorting.column, filter, isAdmin, isCompanyDeleted]);

  return (
    <div className='flex flex-col'>
      <div className='flex justify-between flex-col sm:flex-row gap-x-2'>
        <SearchForm
          className='mb-4 xs:mb-12 items-center flex-col xs:flex-row gap-4 xs:gap-0'
          handleFilter={handleFilter}
          placeholder='Search by Company Name or Company Number'
        />
        <AddCompanyModal page={page} perPage={COMPANIES_PER_PAGE} setPage={setPage} />
      </div>
      <Table onUpdateSorting={updateSorting} columns={ADMIN_COMPANIES_TABLE_COLUMNS}>
        {isLoading ? (
          <TableSkeleton rowsNumber={COMPANIES_PER_PAGE} columnsNumber={4} />
        ) : (
          companies &&
          companies.map((company) => (
            <AdminCompaniesTableRow
              key={company.id}
              onClickCompanyIac={onClickCompanyIac}
              onDeleteCompanyClick={handleDeleteAction}
              company={company}
              onOpen={onOpen}
            />
          ))
        )}
      </Table>

      <Pagination currentPage={page} updatePage={setPage} {...companiesPaginationData} />
      <AdminDeleteCompanyModal isOpen={isOpen} onClose={onClose} companyId={selectedCompanyId} />
    </div>
  );
};

export default CompaniesTable;
