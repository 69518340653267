import React, { ChangeEvent, FC, useState } from 'react';

import cn from 'classnames';
import { FieldErrors, UseFormRegister, Path } from 'react-hook-form';

import { ReactComponent as TrashCan } from 'assets/svg/trash-can.svg';
import { InvestmentProposalBlockNames } from 'constants/company-iac';
import useModal from 'hooks/use-modal/useModal';
import { CompanyIacCustomFieldFormValues } from 'interfaces';
import { selectCustomFields } from 'modules/company-iac/selectors';
import { useAppSelector } from 'modules/store';
import { TextArea } from 'shared-components';

import DeleteIacFieldModal from '../company-iac-delete-field/DeleteIacFieldModal';

type Props = {
  blockName: InvestmentProposalBlockNames;
  register: UseFormRegister<CompanyIacCustomFieldFormValues>;
  deletedFields: number[];
  onDeleteCustomField: (name: number) => void;
  disabled?: boolean;
  callback?: VoidFunction;
  errors: FieldErrors<CompanyIacCustomFieldFormValues>;
  onBlur?: (event: ChangeEvent<HTMLTextAreaElement>) => Promise<void>;
};

type DeleteIacField = {
  label: string | undefined;
  id: number;
};

const INPUT_VALIDATION = {
  value: 1500,
  message: 'The input length should be less than 1500 characters.',
};

const CompanyIacCustomFieldsForm: FC<Props> = ({
  register,
  blockName,
  onDeleteCustomField,
  deletedFields,
  disabled,
  errors,
  onBlur,
}) => {
  const customFields = useAppSelector(selectCustomFields(blockName));
  const { onOpen, onClose, ...modalState } = useModal();
  const [deleteIacField, setDeleteIacField] = useState<DeleteIacField | null>();

  const onDeleteFieldModalOpen = (label = '', id: number) => {
    onOpen();
    setDeleteIacField({ label: `${label}`, id });
  };

  const removeField = () => {
    deleteIacField?.id && onDeleteCustomField(deleteIacField.id);
    setDeleteIacField(null);
    onClose();
  };

  return (
    <form>
      {customFields
        .filter(({ id }) => !deletedFields.includes(id))
        .map((field) => (
          <div key={field.id} className='flex gap-4'>
            <TextArea
              className='w-full'
              {...register(field.name, {
                maxLength: INPUT_VALIDATION,
              })}
              onBlur={onBlur}
              label={field.label}
              disabled={disabled}
              error={errors[field.name as Path<CompanyIacCustomFieldFormValues>]?.message as string | undefined}
            />
            <button
              className={cn({ 'cursor-not-allowed': disabled })}
              type='button'
              disabled={disabled}
              onClick={() => onDeleteFieldModalOpen(field.label, field.id)}
            >
              <TrashCan className='mb-4 grey-500-svg-path' />
            </button>
          </div>
        ))}
      <DeleteIacFieldModal onSubmit={removeField} onClose={onClose} {...modalState} {...deleteIacField} />
    </form>
  );
};

export default CompanyIacCustomFieldsForm;
