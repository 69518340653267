import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AuthState, CompaniesFullInfo, SignUpInput, InvitedUser, ResetPasswordData } from 'interfaces';

import {
  asyncSignUp,
  searchCompanies,
  addCompany,
  inviteInvestor,
  inviteFounder,
  asyncSignIn,
  asyncForgotPassword,
  asyncResetPassword,
  asyncGetInvitedUser,
  connectLinkedIn,
  submitSignUpData,
  resetAuthData,
  asyncFinishRegistration,
  asyncInvitedUserSignUp,
  resetAuthInvites,
} from './action';
import { initialState } from './slice';

export function signUpReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(asyncSignUp.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(asyncSignUp.fulfilled, (state) => {
    state.isActionLoading = false;
  });

  builder.addCase(asyncSignUp.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function asyncSignInReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(asyncSignIn.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(asyncSignIn.fulfilled, (state) => {
    state.isActionLoading = false;
  });

  builder.addCase(asyncSignIn.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function addCompanyReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(addCompany.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(addCompany.fulfilled, (state, { payload: cleanUpCompanyData }: PayloadAction<boolean>) => ({
    ...state,
    ...(cleanUpCompanyData && { companyData: null }),
    isActionLoading: false,
  }));

  builder.addCase(addCompany.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function searchCompaniesReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(searchCompanies.fulfilled, (state, action: PayloadAction<CompaniesFullInfo>) => {
    const { items, total_results } = action.payload;
    state.companies = { items, total_results };
  });
}

export function inviteInvestorReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(inviteInvestor.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(inviteInvestor.fulfilled, (state, action: PayloadAction<InvitedUser>) => {
    state.investorsList.push(action.payload);
    state.isActionLoading = false;
  });

  builder.addCase(inviteInvestor.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function inviteFounderReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(inviteFounder.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(inviteFounder.fulfilled, (state, action: PayloadAction<InvitedUser>) => {
    state.foundersList.push(action.payload);
    state.isActionLoading = false;
  });

  builder.addCase(inviteFounder.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function forgotPasswordReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(asyncForgotPassword.pending, (state) => {
    state.isActionLoading = true;
  });
  builder.addCase(asyncForgotPassword.fulfilled, (state) => {
    state.isActionLoading = false;
  });
  builder.addCase(asyncForgotPassword.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function resetPasswordReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(asyncResetPassword.pending, (state) => {
    state.isActionLoading = true;
  });
  builder.addCase(asyncResetPassword.fulfilled, (state, action: PayloadAction<ResetPasswordData>) => {
    state.resetPasswordUserType = action.payload.userType;
    state.isActionLoading = false;
  });
  builder.addCase(asyncResetPassword.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function getAsyncInvitedUserReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(asyncGetInvitedUser.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(asyncGetInvitedUser.fulfilled, (state, action: PayloadAction<InvitedUser | null>) => {
    state.isActionLoading = false;
    state.invitedUser = action.payload;
  });

  builder.addCase(asyncGetInvitedUser.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function connectLinkedInReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(connectLinkedIn.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(connectLinkedIn.fulfilled, (state) => {
    state.isActionLoading = false;
  });

  builder.addCase(connectLinkedIn.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function submitSignUpDataReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(submitSignUpData, (state, action: PayloadAction<SignUpInput>) => {
    state.signUpData = { ...action.payload };
  });
}

export function finishRegistrationReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(asyncFinishRegistration.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(asyncFinishRegistration.fulfilled, (state) => {
    state.isActionLoading = false;
  });

  builder.addCase(asyncFinishRegistration.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function resetAuthDataReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(resetAuthData, () => initialState);
}

export function invitedUserSignUpReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(asyncInvitedUserSignUp.pending, (state) => {
    state.isActionLoading = true;
  });
  builder.addCase(asyncInvitedUserSignUp.fulfilled, (state) => {
    state.isActionLoading = false;
  });
  builder.addCase(asyncInvitedUserSignUp.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function resetAuthInvitesReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(resetAuthInvites, (state) => {
    state.investorsList = [];
    state.foundersList = [];
  });
}
