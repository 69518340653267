import { FormItem } from 'interfaces';
import { Input } from 'shared-components';

import { PASSWORD_PATTERN } from './global';
import { FieldTypes } from './shared';

export const RESET_PASSWORD_PAGE_TITLE = 'Set your password';

export const RESET_PASSWORD_PAGE_ADDITIONAL_TEXT = 'Set a secure password for your account';

export const SET_PASSWORD_SUBMIT_BUTTON = 'Next';

export enum SetPasswordFieldNames {
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'password_confirmation',
}

export const getSetPasswordFields = (currentPassword: string): FormItem[] => [
  {
    name: SetPasswordFieldNames.PASSWORD,
    label: 'Set Password',
    placeholder: 'Password',
    type: FieldTypes.PASSWORD,
    component: Input,
    validation: {
      required: 'Required information missing please input Password to continue',
      pattern: {
        message: 'Password should match all requirements',
        value: PASSWORD_PATTERN,
      },
    },
    className: 'col-span-2 sm:col-span-1',
  },
  {
    name: SetPasswordFieldNames.CONFIRM_PASSWORD,
    label: 'Confirm Password',
    placeholder: 'Confirm Password',
    type: FieldTypes.PASSWORD,
    component: Input,
    validation: {
      required: 'Required information missing please input Confirmation Password to continue',
      validate: (confirmPassword: string) =>
        currentPassword === confirmPassword || 'Password should be equal to Confirm Password',
    },
    className: 'col-span-2 sm:col-span-1',
  },
];

export const SET_PASSWORD_AUTH_LAYOUT_CHILDREN_CLASSNAMES = 'relative h-[calc(100vh-74px)] max-w-630';
