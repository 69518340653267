import { AxiosRequestConfig } from 'axios';
import qs from 'query-string';

import { httpApiClient } from 'configs/http-client';
import {
  AddInvestmentPayload,
  ChartItem,
  Investment,
  MetaPage,
  MetaPayload,
  ReviewInvestment,
  QualificationQuestionResponce,
  SubmitShareExchangeAnswersPayload,
  EditInvestmentRequestPayload,
  CompanyWithInvestments,
  CreateShareExchangeRequestPayload,
} from 'interfaces';

import { stringifyFilterParams } from '../utils';

export class InvestmentsApi {
  constructor(private url: string) {}

  getInvestments({ page = 1, per_page = 5, query, ...rest }: MetaPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Investment[]; meta: MetaPage }>(
      `${this.url}/investments?${qs.stringify({ page, per_page, q: query, ...rest })}`,
      config,
    );
  }

  getCompaniesWithInvestments({ page = 1, per_page = 5, query, ...rest }: MetaPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: CompanyWithInvestments[]; meta: MetaPage }>(
      `${this.url}/companies?${stringifyFilterParams({ page, per_page, query, ...rest })}`,
      config,
    );
  }

  addInvestment(data: AddInvestmentPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: Investment }>(`${this.url}/investments`, data, config);
  }

  editInvestment(data: EditInvestmentRequestPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<{ data: Investment }>(`${this.url}/investments/${data.id}`, data, config);
  }

  deleteInvestment(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/investments/${id}`, config);
  }

  getCompaniesGraph(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: ChartItem[] }>(`${this.url}/investments/company-graph`, config);
  }

  getIndustriesGraph(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: ChartItem[] }>(`${this.url}/investments/industry-graph`, config);
  }

  uploadInvestments(data: FormData, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: Array<Omit<ReviewInvestment, 'id'>> }>(
      `${this.url}/upload-batch-investments`,
      data,
      config,
    );
  }

  addReviewedInvestments(data: ReviewInvestment[], config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: Investment[]; leftToBeProcessed: number }>(
      `${this.url}/add-batch-investments`,
      { data },
      config,
    );
  }

  getLinkedInInvestments({ page = 1, per_page = 5, query, ...rest }: MetaPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Investment[]; meta: MetaPage }>(
      `${this.url}/other-investments?${qs.stringify({ page, per_page, q: query, ...rest })}`,
      config,
    );
  }

  requestShareExchange(companyId: number, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: CreateShareExchangeRequestPayload }>(
      `${this.url}/companies/${companyId}/share-exchange-requests/`,
      {},
      config,
    );
  }

  resubmitShareExchange(companyId: number, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ data: CreateShareExchangeRequestPayload }>(
      `${this.url}/share-exchange-requests/${companyId}/re-submit`,
      {},
      config,
    );
  }

  cancelShareExchange(shareExchangeId: string, config?: AxiosRequestConfig) {
    return httpApiClient.post(`${this.url}/share-exchange-requests/${shareExchangeId}/cancel`, {}, config);
  }

  submitShareExchangeAnswers(data: SubmitShareExchangeAnswersPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post(`${this.url}/share-exchange-request-answers`, data, config);
  }

  getQualificationQuestions(shareExchangeId: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<QualificationQuestionResponce>(
      `/share-exchange-request-questions?${qs.stringify({ page: 1, per_page: 100, sharexreq: shareExchangeId })}`,
      config,
    );
  }
}

export const investmentsApi = new InvestmentsApi('/investors');
