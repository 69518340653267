import React, { useEffect, useMemo } from 'react';

import { SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import {
  SIGN_UP_INVITED_USER_EXCESS_FIELDS,
  SIGN_UP_PAGE_ADDITIONAL_LINK,
  SIGN_UP_PAGE_ADDITIONAL_TEXT,
  SIGN_UP_PAGE_TITLE,
} from 'constants/sign-up';
import { SignUpInput } from 'interfaces';
import { asyncGetInvitedUser, asyncInvitedUserSignUp } from 'modules/auth/action';
import { selectInvitedUserSignUp, selectIsActionLoading } from 'modules/auth/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AuthLayout, HeaderInfo, SignUpForm } from 'page-components';

const SignUpInvitedUser = () => {
  const { hash } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isActionLoading = useAppSelector(selectIsActionLoading);
  const invitedUserData = useAppSelector(selectInvitedUserSignUp);

  const signUpFormValues = useMemo(() => {
    if (!invitedUserData) {
      return {};
    }

    return { firstName: invitedUserData.firstName, lastName: invitedUserData.lastName };
  }, [invitedUserData]);

  const onSubmitForm: SubmitHandler<SignUpInput> = (data: SignUpInput) => {
    if (!hash || !invitedUserData) {
      return;
    }
    dispatch(
      asyncInvitedUserSignUp({
        ...data,
        hash,
        userType: invitedUserData.type,
        companyId: invitedUserData.companyId,
        inviteType: invitedUserData.inviteType,
      }),
    );
  };

  useEffect(() => {
    if (!hash) {
      navigate(ROUTES.login);
      return;
    }

    dispatch(asyncGetInvitedUser(hash));
  }, [navigate, hash, dispatch]);

  return (
    <AuthLayout>
      <HeaderInfo title={SIGN_UP_PAGE_TITLE} linkText={SIGN_UP_PAGE_ADDITIONAL_LINK} linkPath={ROUTES.signIn}>
        {SIGN_UP_PAGE_ADDITIONAL_TEXT}
      </HeaderInfo>

      <SignUpForm
        isLoading={isActionLoading}
        onSubmit={onSubmitForm}
        excessFields={SIGN_UP_INVITED_USER_EXCESS_FIELDS}
        formValues={signUpFormValues}
      />
    </AuthLayout>
  );
};

export default SignUpInvitedUser;
