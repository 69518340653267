import React, { FC } from 'react';

import { ROUTES } from 'constants/routes';
import { Investor } from 'interfaces';
import { LinkButton } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import { navigateWithFilter } from 'utils';

type Props = {
  investor: Investor;
};

const InvestorsTableRow: FC<Props> = ({ investor }) => (
  <TableRow>
    <TableData>{investor.firstName}</TableData>
    <TableData>{investor.lastName}</TableData>
    <TableData>{investor.email}</TableData>
    <TableData>{investor.phone}</TableData>
    <TableData>
      <LinkButton
        to={navigateWithFilter(true, ROUTES.companies, 'investor', String(investor.id))}
        className='max-w-150'
      >
        Companies
      </LinkButton>
    </TableData>
    <TableData>
      <LinkButton to={navigateWithFilter(true, ROUTES.deals, 'investor_id', String(investor.id))} className='max-w-150'>
        Deals
      </LinkButton>
    </TableData>
  </TableRow>
);

export default InvestorsTableRow;
