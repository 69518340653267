import React, { useState, useEffect, FC } from 'react';

import cn from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';

import { FieldTypes } from 'constants/shared';
import {
  getSignUpFields,
  SIGN_UP_PAGE_FORM_INITIAL,
  SIGN_UP_PAGE_TITLE,
  SIGN_UP_PASSWORD_VALIDATION,
  SignUpFieldNames,
} from 'constants/sign-up';
import { SignUpInput } from 'interfaces';
import { checkIsUserAlreadyRegistered } from 'modules/current-user/action';
import { useAppDispatch } from 'modules/store';
import { Button, FormContent, Input, PasswordStrengthWidget } from 'shared-components';

import AlreadyRegisteredInfoSection from './AlreadyRegisteredInfoSection';

type Props = {
  isLoading: boolean;
  onSubmit: SubmitHandler<SignUpInput>;
  excessFields?: Array<SignUpFieldNames | string>;
  formValues?: Partial<SignUpInput>;
  submitButtonText?: string;
  disabledEmailInput?: boolean;
};

const SignUpForm: FC<Props> = ({
  onSubmit,
  excessFields = [],
  isLoading,
  formValues,
  submitButtonText,
  disabledEmailInput,
}) => {
  const dispatch = useAppDispatch();

  const [isShownPasswordWidget, setIsShownPasswordWidget] = useState(false);
  const [isAlreadyRegisteredEmail, setIsAlreadyRegisteredEmail] = useState(false);

  const toggleIsShownPasswordWidget = () => setIsShownPasswordWidget((prev) => !prev);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    trigger,
    getValues,
  } = useForm<SignUpInput>({
    defaultValues: SIGN_UP_PAGE_FORM_INITIAL,
    reValidateMode: 'onChange',
  });

  const watchPassword = watch(SignUpFieldNames.PASSWORD);

  const handleBlurEmail = async () => {
    const isEmailValid = await trigger(SignUpFieldNames.EMAIL);

    if (!isEmailValid) return;

    dispatch(checkIsUserAlreadyRegistered(getValues(SignUpFieldNames.EMAIL)))
      .unwrap()
      .then(() => setIsAlreadyRegisteredEmail(true))
      .catch(() => setIsAlreadyRegisteredEmail(false));
  };

  useEffect(() => {
    if (formValues) {
      reset({ ...formValues });
    }
  }, [formValues, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='grid grid-cols-2 gap-x-7 relative'>
        <FormContent<SignUpInput>
          fields={getSignUpFields(watchPassword, excessFields, disabledEmailInput, handleBlurEmail)}
          register={register}
          errors={errors}
          control={control}
        />

        <AlreadyRegisteredInfoSection
          isShown={isAlreadyRegisteredEmail}
          additionalQueryParams={`email=${getValues(SignUpFieldNames.EMAIL)}`}
        />

        <Input
          {...register(SignUpFieldNames.PASSWORD, SIGN_UP_PASSWORD_VALIDATION)}
          error={errors[SignUpFieldNames.PASSWORD]?.message}
          onFocus={toggleIsShownPasswordWidget}
          onBlur={toggleIsShownPasswordWidget}
          type={FieldTypes.PASSWORD}
          tabIndex={4}
          label='Password'
          className='row-start-4 row-end-5 col-start-1 col-end-3'
          required
        />
        <PasswordStrengthWidget
          password={watchPassword}
          className={cn(
            'col-start-1 col-end-3 row-start-6 row-end-7 2xl:row-start-3 2xl:absolute 2xl:-right-[105%] top-0',
            { block: isShownPasswordWidget },
            { hidden: !isShownPasswordWidget },
          )}
        />
      </div>
      <Button className='my-2.5' isLoading={isLoading} disabled={isAlreadyRegisteredEmail}>
        {submitButtonText || SIGN_UP_PAGE_TITLE}
      </Button>
    </form>
  );
};

export default SignUpForm;
