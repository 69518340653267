import React, { FC, useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import {
  DECLINE_DUE_DILIGENCE_FORM_FIELDS,
  DECLINE_DUE_DILIGENCE_MODAL_TITLE,
  DECLINE_DUE_DILIGENCE_REASONS,
  DECLINE_DUE_DILIGENCE_SUBMIT_BUTTON_TEXT,
  DECLINE_DUE_DILIGENCE_OPTIONAL_FORM_FIELDS,
} from 'constants/due-diligence';
import { DueDiligenceDeclineFieldNames, DueDiligenceDeclineFormState } from 'interfaces';
import { selectBusinessName } from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import { AlertModal, FormContent } from 'shared-components';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  isLoading: boolean;
  onDeclineDueDiligence: (reason: string) => void;
};

const DeclineDueDiligenceModal: FC<Props> = ({ isOpen, onClose, isLoading, onDeclineDueDiligence }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    reset,
    control,
  } = useForm<DueDiligenceDeclineFormState>();

  const [isVisibleOptionalInputs, setIsVisibleOptionalInputs] = useState(false);

  const businessName = useAppSelector(selectBusinessName);

  const watchReasonSelect = watch(DueDiligenceDeclineFieldNames.REASON_SELECT);

  const onSubmit: SubmitHandler<DueDiligenceDeclineFormState> = ({ reasonText, reasonSelect }) => {
    if (!reasonSelect) {
      return;
    }

    const reason = reasonSelect.value === DECLINE_DUE_DILIGENCE_REASONS.OTHER ? reasonText : reasonSelect.label;

    onDeclineDueDiligence(reason);
  };

  const onCloseModal = () => {
    reset({ [DueDiligenceDeclineFieldNames.REASON_SELECT]: null });
    setIsVisibleOptionalInputs(false);
    onClose();
  };

  useEffect(() => {
    if (watchReasonSelect && watchReasonSelect.value === DECLINE_DUE_DILIGENCE_REASONS.OTHER) {
      setIsVisibleOptionalInputs(true);
      return;
    }

    if (isVisibleOptionalInputs) {
      setIsVisibleOptionalInputs(false);
    }
  }, [watchReasonSelect, isVisibleOptionalInputs]);

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onCloseModal}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      title={`${DECLINE_DUE_DILIGENCE_MODAL_TITLE} ${businessName || ''}?`}
      submitButtonText={DECLINE_DUE_DILIGENCE_SUBMIT_BUTTON_TEXT}
      modalClassName='lg:w-8/12 xl:w-6/12'
      contentClassName='lg:px-28 lg:py-12'
      wrapperModalClassName='lg:py-0 lg:px-0'
    >
      <form className='w-full mt-6'>
        <FormContent fields={DECLINE_DUE_DILIGENCE_FORM_FIELDS} register={register} errors={errors} control={control} />

        {isVisibleOptionalInputs && (
          <FormContent fields={DECLINE_DUE_DILIGENCE_OPTIONAL_FORM_FIELDS} register={register} errors={errors} />
        )}
      </form>
    </AlertModal>
  );
};

export default DeclineDueDiligenceModal;
