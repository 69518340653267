import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import {
  MetaPage,
  GetListPayload,
  Deal,
  CommonMetrics,
  GetDealsOptionsPayload,
  DealsParametersResponse,
  FullDealParameters,
  UpdateDealParametersPayload,
  DealPredefinedData,
  StringOrNull,
  DealSendClosureOverviewPayload,
  DealProgressItem,
  DealPreviewClosureOverviewPayload,
} from 'interfaces';
import { stringifyFilterParams } from 'utils';

import { SHARE_EXCHANGE_DOCUMENT_KEYS } from '../constants/deals';

export class DealsApis {
  constructor(private url: string) {}

  getDeals(params: GetListPayload & Pick<GetDealsOptionsPayload, 'filter'>, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Deal[]; meta: MetaPage }>(`${this.url}?${stringifyFilterParams(params)}`, config);
  }

  getDealDetails(id: number | string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Deal }>(`${this.url}/${id}`, config);
  }

  deleteDeal(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }

  restartDeal(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.patch(`${this.url}/${id}/reactivate`, config);
  }

  getDealsTotalInStagesMetrics(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ totals: Record<string, number> }>(`${this.url}/total-in-stages`, config);
  }

  getDealsTotalStartedMetrics(rangeFilter: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CommonMetrics>(`${this.url}/total-started?filter=${rangeFilter}`, config);
  }

  getDealsTotalFinishedMetrics(rangeFilter: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CommonMetrics>(`${this.url}/total-finished?filter=${rangeFilter}`, config);
  }

  getDealsParameters(params: GetListPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<DealsParametersResponse>(`${this.url}?${stringifyFilterParams(params)}`, config);
  }

  getDealParametersById(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: FullDealParameters; closingDatesAvailableForOverview: string[] }>(
      `${this.url}/${id}`,
      config,
    );
  }

  updateDealParametersById({ id, ...payload }: UpdateDealParametersPayload, config?: AxiosRequestConfig) {
    return httpApiClient.patch(`${this.url}/${id}`, payload, config);
  }

  finishDealParametersById(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.patch(`${this.url}/${id}/complete`, config);
  }

  reactivateDealParametersById(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.patch(`${this.url}/${id}/reactivate`, config);
  }

  stopDealById({ id, stopReason }: { id: string | number; stopReason?: string }, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ data: Deal }>(`${this.url}/${id}/stop`, { ...(stopReason && { stopReason }) }, config);
  }

  completeDealById(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.patch(`${this.url}/${id}/complete`, config);
  }

  getCompanyDealsPredefinedData(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: DealPredefinedData }>(`${this.url}/${id}/deals`, config);
  }

  getLastDealPredefinedData(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: DealPredefinedData }>(`${this.url}/last-draft`, config);
  }

  createCompanyShareExchanges(
    { id, data, investmentIds, draftDealId }: { id: number; data: any; investmentIds?: number[]; draftDealId?: number },
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.post(`${this.url}/${id}/deals`, { exchanges: data, investmentIds, draftDealId }, config);
  }

  sendEmailNotificationByDealId(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.patch(`${this.url}/${id}/send-expires-soon-notification`, config);
  }

  previewDealClosureOverview(
    { id, selectedClosingDates }: DealPreviewClosureOverviewPayload,
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.post(
      `${this.url}/${id}/preview-closure-overview`,
      { closingDates: selectedClosingDates },
      config,
    );
  }

  sendDealClosureOverview({ id, closingDates, emails }: DealSendClosureOverviewPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post(`${this.url}/${id}/send-closure-overview`, { closingDates, emails }, config);
  }

  getDealStages(config?: AxiosRequestConfig) {
    return httpApiClient.get<{
      data: Record<string, { stage: string; doc: SHARE_EXCHANGE_DOCUMENT_KEYS; label: string }>;
    }>(`${this.url}/stages`, config);
  }

  getDealsProgressList(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: DealProgressItem[] }>(`${this.url}/deal-progress-widgets`, config);
  }

  getFounderDealsProgress(id?: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: DealProgressItem[] }>(`${this.url}/${id}/se-progress-widget`, config);
  }

  getDealIdByPlainlyId(plainlyId: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Deal }>(`${this.url}/${plainlyId}/show`, config);
  }
}

export const adminDealsApi = new DealsApis('/admin/deals');
export const adminDealsMetrics = new DealsApis('/admin/metrics/deals');
export const dealsApis = new DealsApis('/investors/deals');
export const founderDealsApis = new DealsApis('/founders/deals');
export const adminDealParametersApis = new DealsApis('/admin/deal-parameters');
export const adminFounderApis = new DealsApis('/admin/founders');
export const investorCompaniesApis = new DealsApis('/investors/companies');
export const foundersCompaniesApis = new DealsApis('founders/companies');
export const apis = new DealsApis('');
