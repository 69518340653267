import React, { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  text: string;
  children?: ReactNode;
  isShown?: boolean;
  loading?: boolean;
  className?: string;
}

const Banner: FC<Props> = ({ text, children, isShown = true, loading, className }) => {
  if (loading) return <div className='skeleton-loader h-10 w-full rounded-md' />;

  if (!isShown) return null;

  return (
    <div className='flex justify-center items-center p-1.5 bg-red-500 rounded-md'>
      <p className={twMerge('text-grey-100 font-medium mr-10', className)}>{text}</p>
      {children}
    </div>
  );
};

export default Banner;
