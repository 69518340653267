import React, { useEffect } from 'react';

import { MY_INVESTMENTS_SNIPPET_DESCRIPTION, MY_INVESTMENTS_SNIPPET_TITLE } from 'constants/my-investments';
import { getCompaniesGraph } from 'modules/investments/action';
import { selectCompaniesGraph } from 'modules/investments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { SnippetLayout } from 'shared-components';
import { ChartInfoList, PieChart, PieChartActiveShapeV1 } from 'shared-components/chart';
import { generateArrayOfColors, getRemainderCountOfListByLimit } from 'utils';

const MyInvestmentsMetricsSnippet = () => {
  const dispatch = useAppDispatch();
  const { isLoading, list: companiesList } = useAppSelector(selectCompaniesGraph);

  const randomColors = generateArrayOfColors(companiesList.length);

  const restCount = getRemainderCountOfListByLimit(companiesList, 10);

  useEffect(() => {
    dispatch(getCompaniesGraph());
  }, [dispatch]);

  if (!isLoading && !companiesList?.length) return null;

  return (
    <SnippetLayout
      className='w-full xl:w-1/2'
      title={MY_INVESTMENTS_SNIPPET_TITLE}
      description={MY_INVESTMENTS_SNIPPET_DESCRIPTION}
    >
      <div className='flex flex-col items-center w-full'>
        <PieChart
          isLoading={isLoading}
          data={companiesList.slice(0, 10)}
          colors={randomColors}
          activeShape={<PieChartActiveShapeV1 />}
        />
        <ChartInfoList
          className='mt-12'
          colors={randomColors}
          infoList={companiesList.slice(0, 10)}
          restCount={restCount}
        />
      </div>
    </SnippetLayout>
  );
};

export default MyInvestmentsMetricsSnippet;
