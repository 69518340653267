import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import { ROUTES } from 'constants/routes';
interface Props {
  className?: string;
}
const Logo: FC<Props> = ({ className }) => {
  return (
    <Link to={ROUTES.dashboard}>
      <LogoSvg className={twMerge('mx-auto', className)} />
    </Link>
  );
};
export default Logo;
