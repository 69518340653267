import React, { FC } from 'react';

import { Tooltip } from 'react-tooltip';

import { ReactComponent as TrashCanIcon } from 'assets/svg/trash-can.svg';
import { COMPANY_INVESTORS_ALREADY_INVESTOR_TEXT } from 'constants/company-investors';
import useModal from 'hooks/use-modal/useModal';
import { ButtonAction, CompanyInvestor } from 'interfaces';
import { ButtonsActions, Checkbox } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';

interface Props extends CompanyInvestor {
  checked: boolean;
  handleSelectInvestor: (investor: CompanyInvestor) => void;
  handleDeleteInvestor: (id: number) => void;
  disabled?: boolean;
}

const CompanyInvestorsTableItem: FC<Props> = ({
  checked,
  handleSelectInvestor,
  handleDeleteInvestor,
  disabled,
  ...investor
}) => {
  const { isOpen, onOpen, onClose } = useModal();

  const { id, fullName, email, invited } = investor;

  const anchorId = `investor-${id}`;

  const actions: ButtonAction[] = [{ id: 'delete', icon: TrashCanIcon, action: () => handleDeleteInvestor(id) }];

  return (
    <TableRow disabled={disabled}>
      <TableData className='pl-1' id={anchorId} onMouseEnter={onOpen} onMouseLeave={onClose}>
        <Checkbox
          checked={checked}
          inputClassName='top-0'
          onChange={() => handleSelectInvestor(investor)}
          disabled={invited}
        />
        {invited && (
          <Tooltip
            anchorId={anchorId}
            isOpen={isOpen}
            positionStrategy='fixed'
            className='text-sm text-grey-500 z-50'
            clickable
            noArrow
          >
            {COMPANY_INVESTORS_ALREADY_INVESTOR_TEXT}
          </Tooltip>
        )}
      </TableData>
      <TableData>{fullName}</TableData>
      <TableData className='underline'>{email}</TableData>
      <TableData>
        <Checkbox checked={invited || false} inputClassName='top-0' />
      </TableData>
      <TableData className='pr-0'>
        <ButtonsActions className='[&_button>div]:justify-end' anchorPrefix='company-investor-item' actions={actions} />
      </TableData>
    </TableRow>
  );
};

export default CompanyInvestorsTableItem;
