import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { COMPANY_WITH_SHARE_EXCHANGE_STATUSES } from 'constants/my-investments';
import { CompanyActiveShareExchange, CompanyWithUserShareExchangeStatus } from 'interfaces';
import { ButtonWithTooltip } from 'shared-components';
import { getShareExchangeLink } from 'utils';

import MyInvestmentsDeclineReasonTooltipContent from './MyInvestmentsDeclineReasonTooltipContent';

type Props = {
  id: string;
  onClickRequest: VoidFunction;
  companyActiveShareExchange: CompanyActiveShareExchange | null;
  companyId: number;
  declineReason: string;
  isActiveExchange: boolean;
  serDeclined: boolean;
} & CompanyWithUserShareExchangeStatus;

const BUTTON_STYLES = 'w-120';

const MyInvestmentExchangeButton: FC<Props> = ({
  id,
  companyId,
  onClickRequest,
  companyActiveShareExchange,
  userCompanySEStatus,
  declineReason,
  isActiveExchange,
  serDeclined,
}) => {
  const navigate = useNavigate();

  const anchorId = `my-investments-${companyId}-${id}`;
  const disabledExchangeButtonId = `disabled-exchange-button-${companyId}-${id}`;
  const disabledRequestButtonId = `disabled-request-button-${companyId}-${id}`;
  const availableExchangeButtonId = `available-exchange-button-${companyId}-${id}`;

  const onClickViewExchange = () => {
    if (!companyActiveShareExchange?.id) {
      return;
    }

    navigate(getShareExchangeLink(companyActiveShareExchange.id));
  };

  if (serDeclined)
    return (
      <ButtonWithTooltip
        tooltipContent={<MyInvestmentsDeclineReasonTooltipContent reason={declineReason} />}
        anchorId={anchorId}
        className={BUTTON_STYLES}
        onClick={onClickRequest}
        offset={20}
      >
        Re-submit
      </ButtonWithTooltip>
    );

  if (
    userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SE ||
    userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.SE_REACHED_LIMIT
  )
    return (
      <ButtonWithTooltip
        disabled
        tooltipContent='Opportunity is closed'
        anchorId={disabledExchangeButtonId}
        className={BUTTON_STYLES}
        offset={20}
      >
        Exchange
      </ButtonWithTooltip>
    );

  if (userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SE)
    return (
      <ButtonWithTooltip
        className={BUTTON_STYLES}
        onClick={onClickRequest}
        tooltipContent={isActiveExchange ? 'Start one more share exchange' : 'You can now start share exchange'}
        anchorId={availableExchangeButtonId}
        offset={20}
      >
        Exchange
      </ButtonWithTooltip>
    );

  if (userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SER)
    return (
      <ButtonWithTooltip
        onClick={onClickViewExchange}
        tooltipContent='Your request in progress'
        anchorId={disabledRequestButtonId}
        className={BUTTON_STYLES}
        offset={20}
      >
        View
      </ButtonWithTooltip>
    );

  if (userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SER)
    return (
      <ButtonWithTooltip
        tooltipContent='Click here to initiate a share exchange request'
        anchorId={anchorId}
        className={BUTTON_STYLES}
        onClick={onClickRequest}
        offset={20}
      >
        Request
      </ButtonWithTooltip>
    );

  return null;
};

export default MyInvestmentExchangeButton;
