import React, { FC, SVGProps } from 'react';

import cn from 'classnames';

import { ReactComponent as ArrowRightSvg } from 'assets/svg/arrow-right.svg';
import { UserTypes } from 'constants/user';
import Typography from 'shared-components/Typography';

type Props = {
  title: string;
  description: string;
  userType: UserTypes;
  className?: string;
  onClick: (userType: UserTypes) => void;
  icon: FC<SVGProps<SVGSVGElement>>;
};

const WhoAreYouChip: FC<Props> = ({ className = '', title, description, onClick, userType, icon: Icon }) => {
  const handleClick = () => onClick(userType);

  return (
    <div
      onClick={handleClick}
      className={cn(
        'rounded-2xl p-6 flex justify-between cursor-pointer outline outline-1 outline-grey-200 hover:outline-[3px] hover:outline-green-600 duration-100',
        className,
      )}
    >
      <div className='flex items-center'>
        <div className='p-5 rounded-2xl bg-blue-800'>
          <Icon className='w-10 h-10 [&>path]:fill-green-600' />
        </div>
        <div className='px-4 xs:px-6'>
          <div className='mb-3 text-2xl text-grey-800 font-inter-tight font-bold'>{title}</div>
          <Typography className='text-grey-500'>{description}</Typography>
        </div>
        <div>
          <ArrowRightSvg className='[&>path]:fill-blue-600' />
        </div>
      </div>
    </div>
  );
};

export default WhoAreYouChip;
