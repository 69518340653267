import { ROUTES } from 'constants/routes';

import { navigateByRole } from '../global/global';

export const getCompanyProfileLink = (id?: string | number, isAdmin?: boolean) =>
  navigateByRole(!!isAdmin, ROUTES.companyProfile.replace(':id', String(id)));

export const getDealDetailsLink = (id: number | string, isAdmin = false) =>
  navigateByRole(isAdmin, `${ROUTES.deals}/${id}`);

export const getLinkWithOriginPrefix = (href: string): string => `${window.location.origin}/${href}`;

export const getDueDiligenceLink = (id: number | string, isAdmin = false) =>
  `${isAdmin ? '/admin' : ''}${ROUTES.dueDiligence.replace(':id', id?.toString())}`;

export const getInvestmentsStartShareExchange = (companyId: string, isAdmin = false) =>
  `${isAdmin ? '/admin' : ''}${ROUTES.myInvestments}?tab=investments&companyId=${companyId}&startShareExchange=true`;

export const getInvestmentsStartShareExchangeRequest = (companyId: string, isAdmin = false) =>
  `${isAdmin ? '/admin' : ''}${
    ROUTES.myInvestments
  }?tab=investments&companyId=${companyId}&startShareExchangeRequest=true`;

export const getShareExchangeLink = (id?: string | number, isAdmin = false) =>
  id ? `${isAdmin ? '/admin' : ''}${ROUTES.shareExchangeReview.replace(':id', id.toString())}` : '#';

export const getAdminCompanyIacLink = (id?: number | string) =>
  `/admin${ROUTES.companiesIac.replace(':id', String(id))}`;

export const getDealParametersLink = (id: number | string, isAdmin: boolean) =>
  navigateByRole(isAdmin, ROUTES.dealParameters.replace(':id', String(id)));

export const getLinkWithTabId = (path: string, tabId: string) => `${path}?tab=${tabId}`;

export const getCompanyInvestorsLink = (id: number) => `${ROUTES.companyInvestors.replace(':id', String(id))}`;

export const getSignificantControlCompanyLink = (id: number | null, isAdmin = false) =>
  id ? `${isAdmin ? '/admin' : ''}${ROUTES.significantControlCompany.replace(':id', id.toString())}` : '#';

export const getArchivedDueDiligenceLink = (id: number, isAdmin = false) =>
  navigateByRole(isAdmin, ROUTES.archivedDueDiligence.replace(':id', String(id)));

export const getArchivedCompanyIacLink = (id: number, isAdmin = false) =>
  navigateByRole(isAdmin, ROUTES.archivedCompanyIac.replace(':id', String(id)));

export const getDueDiligenceLinkByDDId = (companyId: string, dueDiligenceId: string, isAdmin = false) =>
  navigateByRole(
    isAdmin,
    ROUTES.companyDueDiligence.replace(':id', companyId).replace(':dueDiligenceId', dueDiligenceId),
  );

export const getCompanyIacLinkByIacId = (companyId: string, iacId: string, isAdmin = false) =>
  navigateByRole(isAdmin, ROUTES.companyIac.replace(':id', companyId).replace(':iacId', iacId));

export const getFounderProfileLink = (id?: string | number, isAdmin?: boolean) =>
  navigateByRole(!!isAdmin, ROUTES.founder.replace(':id', String(id)));
