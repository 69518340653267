import React, { FC, ReactNode } from 'react';

import cn from 'classnames';

import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import { ButtonVariants } from 'constants/shared/button';
import { LinkButton } from 'shared-components';

interface Props {
  children?: ReactNode;
  title: string;
  linkText?: string;
  linkPath?: string;
  className?: string;
  backPath?: string;
}

const HeaderInfo: FC<Props> = ({ children, title, linkText, linkPath, className, backPath }) => {
  return (
    <div className={cn('mb-10 flex flex-col relative', className)}>
      {backPath && (
        <LinkButton
          to={backPath}
          variant={ButtonVariants.SECONDARY}
          className='grey-500-svg-path mb-10 xl:mb-0 xl:absolute top-20 -left-1/3'
        >
          <ArrowLeft className='mr-1.5' /> <span>Back</span>
        </LinkButton>
      )}
      <h2 className='pt-15 2xl:pt-40 sm:pt-18 mb-4 text-40 font-inter-tight font-medium leading-10 text-grey-800 leading-50'>
        {title}
      </h2>
      <div className='text-grey-500 leading-5 flex items-center'>
        {children}{' '}
        {linkText && linkPath && (
          <LinkButton className='w-fit ml-1' variant={ButtonVariants.UNDERLINED_LINK} to={linkPath}>
            {linkText}
          </LinkButton>
        )}
      </div>
    </div>
  );
};

export default HeaderInfo;
