import React, { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { LinkButton } from 'shared-components';
import { getCompanyProfileLink } from 'utils';

import { ButtonVariants } from '../constants/shared/button';

interface Props {
  children: ReactNode;
  id?: string;
  className?: string;
  isLoading?: boolean;
}

const WithCompanyProfileLinkContainer = ({ children, id, className, isLoading }: Props) => {
  if (isLoading) return <div className={twMerge('skeleton-loader w-96 h-[46px]', className)} />;

  if (!id) return null;

  return (
    <div className={className}>
      {children}
      <LinkButton className='w-full md:w-fit' to={getCompanyProfileLink(id, true)} variant={ButtonVariants.SECONDARY}>
        Company Profile
      </LinkButton>
    </div>
  );
};

export default WithCompanyProfileLinkContainer;
