import React from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { CONTACT_DETAILS_FIELDS, EMAIL_FIELDS, PERSONAL_DETAILS_FIELDS, PROFILE_FORM_TITLES } from 'constants/settings';
import { ProfileSettingsInput } from 'interfaces';
import { updateCurrentUser } from 'modules/current-user/action';
import { selectUser } from 'modules/current-user/selectors';
import { useAppSelector, useAppDispatch } from 'modules/store';
import { FormContent } from 'shared-components';

import InputsWrapper from './InputsWrapper';
import ProfileSettingsHeader from './ProfileSettingsHeader';

const ProfileSettings = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ProfileSettingsInput>({
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phone: user?.phone || '',
      amountInvested: '1', // TODO: Remove this
    },
  });

  const onSubmit: SubmitHandler<ProfileSettingsInput> = (data) => {
    dispatch(updateCurrentUser(data));
  };

  const onCancel = () => reset();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ProfileSettingsHeader onCancel={onCancel} />

      <InputsWrapper title={PROFILE_FORM_TITLES.personalDetails}>
        <div className='w-full grid grid-cols-2 gap-4'>
          <FormContent<ProfileSettingsInput> fields={PERSONAL_DETAILS_FIELDS} register={register} errors={errors} />
        </div>
      </InputsWrapper>

      <InputsWrapper title={PROFILE_FORM_TITLES.emailAddress}>
        <div className='grid grid-cols-1'>
          <FormContent<ProfileSettingsInput> fields={EMAIL_FIELDS} register={register} errors={errors} />
        </div>
      </InputsWrapper>

      <InputsWrapper title={PROFILE_FORM_TITLES.contactDetails}>
        <div className='grid grid-cols-2'>
          <FormContent<ProfileSettingsInput> fields={CONTACT_DETAILS_FIELDS} register={register} errors={errors} />
        </div>
      </InputsWrapper>
    </form>
  );
};

export default ProfileSettings;
