import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import {
  MetaPage,
  GetListPayload,
  Investor,
  CommonMetrics,
  InvestorPieChartMetrics,
  WelcomeInvestorShareExchangeList,
} from 'interfaces';
import { stringifyFilterParams } from 'utils';

export class InvestorsApis {
  constructor(private url: string) {}

  getInvestors(params: GetListPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Investor[]; meta: MetaPage }>(
      `${this.url}?${stringifyFilterParams(params)}`,
      config,
    );
  }

  getMetrics(rangeFilter: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CommonMetrics>(`${this.url}/investor?filter=${rangeFilter}`, config);
  }

  getPieChart(config?: AxiosRequestConfig) {
    return httpApiClient.get<InvestorPieChartMetrics>(`${this.url}/investor-pie-chart`, config);
  }

  getWelcomeInvestorShareExchangeData(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: WelcomeInvestorShareExchangeList }>(`${this.url}/dashboard-buttons`, config);
  }
}

export const adminInvestorsApi = new InvestorsApis('/admin/investors');
export const adminInvestorsMetrics = new InvestorsApis('/admin/metrics');
export const investorApi = new InvestorsApis('/investors');
