import { createSelector } from '@reduxjs/toolkit';

import { DealParametersStatuses } from 'constants/deals';
import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.companiesReducer;

export const selectCurrentCompanyData = createSelector(selectState, ({ currentCompany: { isLoading, data } }) => ({
  isLoading,
  data,
}));

export const selectCurrentCompanyMainInfo = createSelector(selectState, ({ currentCompany: { isLoading, data } }) => {
  const {
    companyName,
    industry,
    dateIncorporated,
    size,
    natureOfBusiness,
    companyNumber,
    isWhitelisted,
    id,
    canViewDueDiligence,
    canStartDueDiligence,
    canViewInvestmentProposal,
    qqUnderRejected,
  } = data || {};
  return {
    isLoading,
    companyName,
    industry,
    dateIncorporated,
    size,
    natureOfBusiness,
    companyNumber,
    isWhitelisted: !!isWhitelisted,
    id,
    canViewDueDiligence,
    canStartDueDiligence,
    canViewInvestmentProposal,
    qqUnderRejected,
  };
});

export const selectCurrentCompanyId = createSelector(selectState, ({ currentCompany: { data } }) => data?.id);

export const selectCompanyShareExchangeInfo = createSelector(selectState, ({ currentCompany: { isLoading, data } }) => {
  const {
    amountShareExchangesCompleted,
    amountShareExchangesInProgress,
    amountShareExchangesStopped,
    noOfSharesExchanged,
    dealParameters,
  } = data?.extra || {};

  const { noOfShares = null, noOfSharesRemaining = null } = data || {};

  return {
    amountShareExchangesCompleted,
    amountShareExchangesInProgress,
    amountShareExchangesStopped,
    noOfShares,
    noOfSharesRemaining,
    noOfSharesExchanged,
    dealParameters,
    isLoading,
  };
});

export const selectIsCompanyCanBeWhitelisted = createSelector(
  selectState,
  ({ currentCompany: { data } }) => data?.extra?.canCompanyBeWhitelisted,
);

export const selectCompanyDealParameters = createSelector(
  selectState,
  ({ currentCompany: { data } }) => data?.extra?.dealParameters,
);

export const selectCanStartDeal = createSelector(
  selectState,
  ({ currentCompany: { data } }) => data?.extra?.canStartDealParameter,
);

export const selectCompanyOfficers = createSelector(selectState, ({ currentCompany }) => currentCompany.officers);

export const selectActiveCompanyHolders = createSelector(selectState, ({ currentCompany }) =>
  currentCompany.holders?.filter(({ ceasedAt }) => !ceasedAt),
);

export const selectIsCurrentCompanyLoading = createSelector(
  selectState,
  ({ currentCompany }) => currentCompany.isLoading,
);

export const selectCompaniesData = createSelector(selectState, (state) => state.companies);

export const selectCompaniesList = createSelector(selectState, (state) => state.companies.list);

export const selectCompaniesPaginationData = createSelector(selectState, ({ companies: { total, totalPages } }) => ({
  total,
  totalPages,
}));

export const selectMyCompaniesOptions = createSelector(selectState, (state) => state.myCompaniesOptions);

export const selectCompanyData = createSelector(selectState, ({ companyData: { data } }) => data);

export const selectCompanyDataIsLoading = createSelector(selectState, (state) => state.companyData.isLoading);

export const selectCompanyDataIsCalled = createSelector(selectState, (state) => state.companyData.called);

export const selectCompaniesMetrics = createSelector(selectState, (state) => state.companiesMetrics);

export const selectInvitedCompanyInvestors = createSelector(selectState, (state) => state.invitedCompanyInvestors);

export const selectHasCompanyLiveDeal = createSelector(selectState, (state) => state.currentCompany?.data?.hasLiveDeal);

export const selectCompanyDealParametersHasActiveDeal = createSelector(selectState, (state) =>
  state?.currentCompany?.data?.extra?.dealParameters?.some(
    (dealParameter) => dealParameter.status === DealParametersStatuses.LIVE,
  ),
);

export const selectArchivedCompanyDueDiligence = createSelector(
  selectState,
  ({ archived: { isLoading, archivedDueDiligence } }) => ({ isLoading, list: archivedDueDiligence }),
);

export const selectArchivedCompanyIac = createSelector(
  selectState,
  ({ archived: { isLoading, archivedCompanyIac } }) => ({ isLoading, list: archivedCompanyIac }),
);
