import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
  progress: number;
  isLoading?: boolean;
}

const ProgressBar: FC<Props> = ({ className, progress, isLoading }) => {
  if (isLoading) return <div className='skeleton-loader w-full h-5 rounded-full' />;

  return (
    <div className={twMerge('w-full h-5 bg-grey-300 rounded-full', className)}>
      <div
        style={{ width: progress + '%' }}
        className='h-full text-center text-xs text-white bg-lightGreen-700 rounded-full'
      />
    </div>
  );
};

export default ProgressBar;
