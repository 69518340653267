import React, { FC, ReactNode } from 'react';

import { DUE_DILIGENCE_CHECK_LOCK_MESSAGE, DUE_DILIGENCE_CHECK_LOCK_STATUSES } from 'constants/due-diligence';

interface Props {
  title: string | undefined;
  subtitle: string;
  children?: ReactNode;
  subTitleNode?: ReactNode;
  isLocked?: boolean;
  checkLockStatus?: string;
}

const DetailsPageTitle: FC<Props> = ({ title, subtitle, children, isLocked, checkLockStatus, subTitleNode }) => {
  if (!title || !subtitle)
    return (
      <div>
        <div className='skeleton-loader h-12 w-80' />
        <div className='skeleton-loader h-14 w-96 mt-4' />
      </div>
    );

  return (
    <div>
      <div className='flex'>
        <h2 className='text-grey-500 mr-4'>{subtitle}</h2>
        {children}
      </div>
      <h3 className='text-32 text-blue-800 font-bold'>{title}</h3>
      {subTitleNode}
      {isLocked && checkLockStatus === DUE_DILIGENCE_CHECK_LOCK_STATUSES.PENDING && (
        <div className='skeleton-loader h-12 w-96 mt-2' />
      )}

      {isLocked && checkLockStatus === DUE_DILIGENCE_CHECK_LOCK_STATUSES.LOADED && (
        <div className='flex items-center mt-2 text-[14px] text-red-600 font-bold'>
          {DUE_DILIGENCE_CHECK_LOCK_MESSAGE}
        </div>
      )}
    </div>
  );
};

export default DetailsPageTitle;
