import React, { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  rowNumber: number;
  children: ReactNode;
  className?: string;
}

const DealParametersSharesFormRow: FC<Props> = ({ rowNumber, children, className }) => {
  return (
    <div className={twMerge('flex flex-col sm:flex-row sm:space-x-5 bg-grey-100 py-3 px-5 mb-10', className)}>
      <div>{rowNumber}.</div>
      {children}
    </div>
  );
};

export default DealParametersSharesFormRow;
