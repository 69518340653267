import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import {
  CompanyData,
  CurrentUserPayload,
  SignUpPayload,
  SignUpPayloadWithHash,
  CompaniesFullInfo,
  SignInResponse,
  ForgotPasswordInput,
  SetPasswordPayload,
  UserWithTokenPayload,
  TermsAndConditionsData,
  ConnectLinkedInInput,
  InvitedUser,
  ResetPasswordData,
  SignInPayload,
} from 'interfaces';

export class AuthApi {
  constructor(private url: string) {}

  signUp(data: SignUpPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<UserWithTokenPayload>(`${this.url}/register`, data, config);
  }

  signIn({ companyId, ...data }: SignInPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<SignInResponse>(
      `${this.url}/login${companyId ? `?companyId=${companyId}` : ''}`,
      data,
      config,
    );
  }

  searchCompanies(data: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CompaniesFullInfo>(`${this.url}?q=${data}`, config);
  }

  addCompany(data: CompanyData, config?: AxiosRequestConfig) {
    return httpApiClient.post<CurrentUserPayload>(`${this.url}/add-company`, data, config);
  }

  forgotPassword(data: ForgotPasswordInput, config?: AxiosRequestConfig) {
    return httpApiClient.post<unknown>(`${this.url}/password/email`, data, config);
  }

  resetPassword(data: SetPasswordPayload, config?: AxiosRequestConfig) {
    return httpApiClient.patch<ResetPasswordData>(`${this.url}/password/reset`, data, config);
  }

  signUpInvitedInvestor(data: SignUpPayloadWithHash, config?: AxiosRequestConfig) {
    return httpApiClient.post<UserWithTokenPayload>(`${this.url}/user-invites/register-investor`, data, config);
  }

  signUpInvitedFounder(data: SignUpPayloadWithHash, config?: AxiosRequestConfig) {
    return httpApiClient.post<UserWithTokenPayload>(`${this.url}/user-invites/register-founder`, data, config);
  }

  signUpInvitedFmUser(data: SignUpPayloadWithHash, config?: AxiosRequestConfig) {
    return httpApiClient.post<UserWithTokenPayload>(`${this.url}/user-invites/register-fmuser`, data, config);
  }

  getInvitedUser(hash: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: InvitedUser }>(`${this.url}/user-invites/hash/${hash}`, config);
  }

  getTermsConditions(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: TermsAndConditionsData }>(`${this.url}/pages/terms-and-conditions`, config);
  }

  connectLinkedIn(data: ConnectLinkedInInput, config?: AxiosRequestConfig) {
    return httpApiClient.patch<unknown>(`${this.url}/profiles`, data, config);
  }
}

export const authApi = new AuthApi('');
export const authInvestorsApi = new AuthApi('/investors');
export const authFoundersApi = new AuthApi('/founders');
export const authExternalCompaniesApi = new AuthApi('/external/company-house/companies');
export const authLinkedinApi = new AuthApi('/external/linkedin');
