import React from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { MY_INVESTMENTS_PAGE_TABS_IDS, MY_INVESTMENTS_PAGE_TITLE } from 'constants/investments';
import { ConstraintVariants } from 'constants/shared';
import { TabItem } from 'interfaces';
import { DashboardLayout, MyInvestmentsMetricsSnippet } from 'page-components';
import MyInvestmentsContainer from 'page-components/investments/my-investments/MyInvestmentsContainer';
import MyInvestmentsOverviewContainer from 'page-components/my-investments/MyInvestmentsOverviewContainer';
import { Constraint, Tabs, TitleWithDescription } from 'shared-components';

export const MY_INVESTMENTS_PAGE_TABS: TabItem[] = [
  { id: MY_INVESTMENTS_PAGE_TABS_IDS.INVESTMENTS, name: 'Investments', component: MyInvestmentsContainer },
  { id: MY_INVESTMENTS_PAGE_TABS_IDS.OVERVIEW, name: 'Overview', component: MyInvestmentsMetricsSnippet },
];

const MyInvestments = () => {
  const { search } = useLocation();

  const { tab } = qs.parse(search);

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED} className='min-h-min'>
        <TitleWithDescription className='mb-7.5' title={MY_INVESTMENTS_PAGE_TITLE} />
        <Tabs tabs={MY_INVESTMENTS_PAGE_TABS} />
      </Constraint>

      {tab === MY_INVESTMENTS_PAGE_TABS_IDS.OVERVIEW && <MyInvestmentsOverviewContainer />}
    </DashboardLayout>
  );
};

export default MyInvestments;
